import React from 'react';
import { Typography } from '@rmwc/typography';
// @ts-ignore
import DropdownTreeSelect from 'react-dropdown-tree-select';
import styles from './LoadPicker.module.scss';
import { Select } from '@rmwc/select';
import { utilityObjRev } from 'utilities';

const getSelectedLoads = (mappedData: any[]) => {
  const loadIds = [];
  const innerFn = (data: any[]) => {
    data.forEach((x) => {
      if (x.type === 'load') {
        if (x.checked) {
          loadIds.push(x.value);
        }
      } else {
        innerFn(x.children);
      }
    });
  };
  innerFn(mappedData);
  return loadIds;
};

const getSelectedLoadsUtilities = (mappedData: any[]) => {
  const utilities = [];
  const innerFn = (data: any[]) => {
    data.forEach((x) => {
      if (x.type === 'load') {
        if (x.checked && utilities.indexOf(x.utilityType) === -1) {
          utilities.push(x.utilityType);
        }
      } else {
        innerFn(x.children);
      }
    });
  };
  innerFn(mappedData);
  return utilities;
};

/**
 * Method to map nodes into tree structure for plugin
 * @param nodes
 * @param parentLastOfType
 * @param disabled
 * @returns
 */
const innerMapGroups = (nodes: any[], parentLastOfType, disabled?: boolean) => {
  const theNodes = nodes.map((x, i) => {
    const actions = [{ className: 'badge', text: '...' }];
    const lastOfType = nodes[i + 1] ? false : true;
    // const type = x.nodes.length > 0 ? 'group' : 'load';
    var data = {
      label: x.label,
      value: x.id,
      checked: x.checked, // x.nodes.indexOf(groupId) > -1, // x.parentIds.indexOf(groupId) > -1,
      type: x.type,
      actions: actions,
      children: x.type !== 'load' ? innerMapGroups(x.nodes, lastOfType, disabled) : [],
      className: `${x.type} ${lastOfType ? 'lastOfType' : 'hasSiblings'} ${
        parentLastOfType ? 'parentLastOfType' : 'parentHasSiblings'
      } `,
      disabled: disabled,
      utilityType: x.utilityType,
    };

    if (data.children.length === 0) {
      data.actions.splice(0, 1);
    } else {
      if (data.children.length === 1) {
        data.className += ' oneChild';
      } else {
        data.className += ' hasChildren ';
      }
    }

    return data;
  });
  theNodes.sort((a: any, b: any) => {
    return a.checked > b.checked ? -1 : a.checked < b.checked ? 1 : a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
  });
  return theNodes;
};

const processLoads = (id, loads, disabled, selectedNodes, selectedUtility) => {
  let groupedLoadsArr = [];
  const groupedLoads = {};
  let clonedLoads = [].concat(loads);
  if (selectedUtility) {
    clonedLoads = clonedLoads.filter((x) => {
      let result = false;
      if (selectedUtility === 'electricity') {
        result = x.utilityType === utilityObjRev.electricity;
      } else if (selectedUtility === 'gas') {
        result = x.utilityType === utilityObjRev.gas;
      } else if (selectedUtility === 'water') {
        result = x.utilityType === utilityObjRev.water;
      } else {
        result = x.utilityType === utilityObjRev.electricity || x.utilityType === utilityObjRev.gas;
      }
      return result;
    });
  }

  clonedLoads.sort((a: any, b: any) => {
    return a.label.localeCompare(b.label);
  });

  clonedLoads.forEach((x) => {
    const checked = x.usedIn?.indexOf(id) > -1 ? true : false;
    let load = { label: x.label, id: x.loadId, checked: checked, type: 'load', nodes: [], utilityType: x.utilityType };
    let building = {
      label: x.building,
      id: `b-${x.site}-${x.building}-${x.loadId}`,
      checked: checked,
      type: 'building',
      nodes: [load],
      disabled: disabled,
      utilityType: x.utilityType,
    };
    let site = {
      label: x.site,
      id: `s-${x.site}-${x.loadId}`,
      checked: checked,
      type: 'site',
      nodes: [building],
      buildingHash: {},
      disabled: disabled,
      utilityType: x.utilityType,
    };
    site.buildingHash[x.building] = true;
    if (!groupedLoads[x.site]) {
      groupedLoads[x.site] = site;
    } else if (!groupedLoads[x.site].buildingHash[x.building]) {
      groupedLoads[x.site].buildingHash[x.building] = true;
      groupedLoads[x.site].nodes.push(building);
    } else {
      const siteBuilding = groupedLoads[x.site].nodes.find((y) => y.label === x.building);
      siteBuilding.nodes.push(load);
    }
  });

  for (let k in groupedLoads) {
    groupedLoadsArr.push(groupedLoads[k]);
  }
  let mappedData = [];
  if (selectedNodes?.length > 0) {
    mappedData = selectedNodes;
  } else {
    mappedData = innerMapGroups(groupedLoadsArr, false, disabled);
  }
  mappedData.sort((a, b) =>
    a.checked > b.checked ? -1 : a.checked < b.checked ? 1 : a.label > b.label ? 1 : a.label < b.label ? -1 : 0
  );

  return mappedData;
};

export const LoadPicker = ({
  id,
  label,
  selectedNodes,
  setSelectedNodes,
  isUserFlow,
  loads,
  description,
  disabled,
  showUtilityFilter,
}) => {
  const [selectedUtility, setSelectedUtility] = React.useState(null);
  const [treeData, setTreeData] = React.useState([]);
  const [selectedLoadsCount, setSelectedLoadCount] = React.useState(0);

  const checkAllChildren = (areas, checked) => {
    areas.forEach((x) => {
      x.checked = checked;
      checkAllChildren(x.children, checked);
    });
  };

  const updateTreeBadges = (areas) => {
    areas.forEach((x) => {
      if (x.type === 'load') {
        return;
      }
      const count = x.children.filter((y) => y.checked).length;
      const element = document.querySelector(`input[value="${x.value}"`) as HTMLElement;
      if (element && element.parentElement && element.parentElement.nextElementSibling) {
        let text = `${count} Selected`;
        if (count === x.children.length) {
          text = `All ${count} Selected`;
        }
        element.parentElement.nextElementSibling.textContent = text;
      }
      updateTreeBadges(x.children);
    });
  };

  const listenToTreeSearch = () => {
    const ele = document.querySelector('.react-dropdown-tree-select .search') as HTMLInputElement;
    if (ele) {
      ele.onkeyup = () => {
        if (!ele.value) {
          updateTreeBadges(treeData);
        }
      };
    }
  };

  const onChange = (currentNode, selectedNodes) => {
    const area = treeData.find((x) => x.value === currentNode.value);
    if (area) {
      area.checked = currentNode.checked;
      checkAllChildren(area.children, currentNode.checked);
    } else {
      let subarea = null;
      treeData.forEach((a) => {
        const match = a.children.find((x) => x.value === currentNode.value);
        if (match) {
          subarea = match;
          return;
        } else {
          a.children.forEach((b) => {
            const match2 = b.children.find((x) => x.value === currentNode.value);
            if (match2) {
              subarea = match2;
              return;
            }
          });
        }
      });
      if (subarea) {
        subarea.checked = currentNode.checked;
        checkAllChildren(subarea.children, currentNode.checked);
      }
    }
    setSelectedLoadCount(getSelectedLoads(treeData).length);
    setTreeData(treeData);
    setSelectedNodes(treeData);
    updateTreeBadges(treeData);
  };

  const setupTree = (utility, forceChange) => {
    const mappedData = processLoads(id, loads, disabled, forceChange ? [] : selectedNodes, utility);
    setTreeData(mappedData);
    setSelectedNodes(mappedData);
    setSelectedLoadCount(getSelectedLoads(mappedData).length);
    setTimeout(() => {
      listenToTreeSearch();
    }, 0);
    setTimeout(() => {
      setSelectedUtility(utility || 'none');
      updateTreeBadges(mappedData);
    }, 100);
    return mappedData;
  };

  React.useEffect(() => {
    const mappedData = setupTree(null, false);
    if (showUtilityFilter) {
      const utilities = getSelectedLoadsUtilities(mappedData);
      let chosenUtility = null;
      if (utilities.indexOf(utilityObjRev.electricity) > -1) {
        if (utilities.indexOf(utilityObjRev.gas) > -1) {
          chosenUtility = 'dual';
        } else {
          chosenUtility = 'electricity';
        }
      } else if (utilities.indexOf(utilityObjRev.gas) > -1) {
        chosenUtility = 'gas';
      } else if (utilities.indexOf(utilityObjRev.water) > -1) {
        chosenUtility = 'water';
      } else {
        chosenUtility = 'electricity';
      }
      setupTree(chosenUtility, false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`${styles.virtualGroupContent} ${isUserFlow ? styles.userFlow : null} ${
        description ? styles.withDescription : null
      } ${showUtilityFilter ? styles.withUtilityFilter : null} `}>
      {description && (
        <div className={styles.virtualGroupDescription}>
          <Typography use="body1">{description}</Typography>
        </div>
      )}

      {showUtilityFilter && (
        <div className={styles.utilityFilter}>
          <Select
            enhanced
            className={styles.customSelect}
            name="utility"
            options={[
              { value: 'electricity', label: 'Electricity' },
              { value: 'gas', label: 'Gas' },
              { value: 'water', label: 'Water' },
              { value: 'dual', label: 'Electricity and Gas' },
            ]}
            label="Utility"
            required
            outlined
            defaultValue={selectedUtility}
            disabled={disabled}
            onChange={(evt) => {
              // @ts-ignore
              setupTree(evt.detail.value, true);
            }}
          />
        </div>
      )}
      {!isUserFlow && (
        <div className={styles.virtualGroupHeading}>
          <Typography use="headline6">{label ? label : 'Create'}</Typography>
          <span className={styles.countBadge}>{selectedLoadsCount} Total Loads Selected</span>
        </div>
      )}

      <div className={`${styles.virtualGroupTree}`} key={selectedUtility || 'default'}>
        <TreeContainer
          data={treeData}
          showPartiallySelected={true}
          keepTreeOnSearch={true}
          keepChildrenOnSearch={true}
          showDropdown={'always'}
          inlineSearchInput={true}
          onChange={(currentNode, selectedNodes) => {
            onChange(currentNode, selectedNodes);
          }}
          onNodeToggle={() => {
            setTimeout(() => {
              updateTreeBadges(treeData);
            }, 0);
          }}
        />
      </div>
    </div>
  );
};

class TreeContainer extends React.Component<any> {
  componentWillReceiveProps = (nextProps) => {
    return false;
  };

  shouldComponentUpdate = (nextProps) => {
    return false;
  };

  render() {
    const { data, onChange, ...rest } = this.props;
    return <DropdownTreeSelect data={data} onChange={onChange} {...rest} />;
  }
}

export default LoadPicker;
