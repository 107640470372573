import React from 'react';

interface LoaderComponentProps {
  absolute?: true;
}

export const LoaderComponent: React.FC<LoaderComponentProps> = (props) => (
  <div className={`ldsRipple`} style={{ position: props?.absolute ? 'absolute' : 'fixed' }}>
    <div></div>
    <div></div>
  </div>
);

export default LoaderComponent;
