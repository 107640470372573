import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';

import { Icon } from '@rmwc/icon';
import { Radio } from '@rmwc/radio';
import { Controller, useForm } from 'react-hook-form';
import { LoaderComponent } from 'components';
import { ReactComponent as YayImage } from 'assets/yay.svg';

import DrawerContentHeading from './DrawerContentHeading';
import styles from './AddDashboardDrawer.module.scss';
import { NodeDto } from 'utilities';

interface AddDashboardDrawerProps {
  extraParams?: any;
  manageRightDrawer: (open: boolean, type: string, id?: string) => void;
  addDashboard: (data: any) => Promise<boolean>;
  getAccountBuildings: () => Promise<NodeDto[]>;
}

export const AddDashboardDrawer = ({
  extraParams,
  manageRightDrawer,
  addDashboard,
  getAccountBuildings,
}: AddDashboardDrawerProps) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [step, setStep] = React.useState('start');
  const [buildings, setBuildings] = React.useState([] as NodeDto[]);

  const handleNext = () => {
    const hiddenBtn = document.getElementById('hiddenBtn') as HTMLElement;
    if (hiddenBtn) {
      hiddenBtn.click();
    }
  };

  const submitCreate = async (values) => {
    setSubmitting(true);
    const result = await addDashboard(values);
    if (result) {
      sessionStorage.removeItem('dashboards');
      setStep('complete');
    } else {
      toast.error(
        `There was a problem creating this dashboard, please reload the page & try again or contact support.`
      );
    }
    setSubmitting(false);
  };

  React.useEffect(() => {
    if (!loaded) {
      const innerFn = async () => {
        setBuildings(await getAccountBuildings());
        setLoaded(true);
      };
      innerFn();
    }
    // eslint-disable-next-line
  }, [loaded]);

  return (
    <div className={`${styles.addDashboardDrawer}`}>
      <DrawerContentHeading
        title={step === 'start' ? 'Request a new dashboard' : 'Request sent'}
        titleSmall={true}
        titleIcon={<Icon icon={{ icon: 'near_me', basename: 'material-icons-outlined' }} />}
        manageRightDrawer={manageRightDrawer}
      />

      {!loaded && (
        <div className={styles.loadingContainer}>
          <LoaderComponent absolute />
        </div>
      )}

      {loaded && step === 'start' && <AddDashboardForm submitCreate={submitCreate} buildings={buildings} />}

      {loaded && step === 'complete' && (
        <div className={styles.complete}>
          <div className={styles.noDataWrapper}>
            <div className={styles.noDataHeading}>
              <Typography use="headline6">All done!</Typography>
            </div>
            <div className={styles.noDataContent}>
              <Typography use="body2">
                Thank you for your order. The ESP team will setup your dashboard, issue you an invoice and advise you
                once it is ready for you. In the mean time you can begin to edit your colours, backgrounds and logos in
                the view admin area.
              </Typography>
            </div>
            <br />
            <br />
            <YayImage />
          </div>
        </div>
      )}

      <div className={`${styles.drawerFooter}`}>
        <Link
          to="/app/account-settings/authorities"
          onClick={(e) => {
            e.preventDefault();
            sessionStorage.setItem('clearAccountData', 'yes');
            manageRightDrawer(false, 'addDashboard', null);
            setStep('start');
          }}>
          {step === 'start' ? 'Cancel' : 'Close'}
        </Link>

        {step !== 'complete' && (
          <Button
            label={'Request'}
            raised
            type="button"
            icon={submitting ? <CircularProgress theme="secondary" /> : null}
            disabled={submitting}
            onClick={handleNext}
          />
        )}
      </div>
    </div>
  );
};

const AddDashboardForm = ({ submitCreate, buildings }) => {
  const { errors, control, register, handleSubmit, setValue } = useForm({
    mode: 'all',
  });

  const [option, setOption] = React.useState('request');

  const onSubmit = (values) => {
    const payload = {
      dashboardLabel: values.theDashboardName,
      buildingId: values.theBuilding,
      isViewBoxNeeded: option === 'request',
      comment: values.theComments,
    };
    submitCreate(payload);
  };

  return (
    <div className={styles.addDashboardContent}>
      <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
        <div className={styles.createForm}>
          <div className={styles.row}>
            <div className={styles.col}>
              <Typography use="body2">
                Thank you for requesting a new dashboard. Simply select which building this is for, let us know if it is
                for an entire building or part of a building. Do you need a view box? Or do you have your own smart
                display and in the comments section let us know any other detail such as loads or floors that you would
                like to include.
              </Typography>
            </div>
          </div>
          <div className={styles.addDashboardHeading}>
            <Typography use="headline6">Request</Typography>
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <Controller
                name="theBuilding"
                control={control}
                rules={{
                  required: true,
                }}
                as={
                  <Select
                    enhanced
                    className={styles.customSelect}
                    name="theBuilding"
                    options={buildings?.map((x) => ({ value: x.id, label: x.label }))}
                    label="Select a building"
                    required
                    outlined
                  />
                }
              />
              {errors.theBuilding && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a building</Typography>
                </div>
              )}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <TextField
                outlined
                label="Dashboard Name"
                name="theDashboardName"
                required
                inputRef={register({ required: true })}
              />
              {errors?.theDashboardName && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter in a dashboard name</Typography>
                </div>
              )}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.radioContainer}>
              <Radio
                style={{ marginRight: '16px' }}
                value="request"
                checked={option === 'request'}
                onChange={(evt) => setOption(String(evt.currentTarget.value))}>
                Request a view box
              </Radio>
              <Radio
                value="url"
                checked={option === 'url'}
                onChange={(evt) => setOption(String(evt.currentTarget.value))}>
                URL Only
              </Radio>
            </div>
          </div>
        </div>
        <br />
        <div className={styles.row}>
          <div className={styles.col}>
            <TextField
              outlined
              label="Add a comment"
              name="theComments"
              inputRef={register()}
              fullwidth
              rows={2}
              characterCount
              maxLength={500}
              textarea
            />
          </div>
        </div>

        <button id="hiddenBtn" type="submit" style={{ display: 'none' }} />
      </form>
    </div>
  );
};

export default AddDashboardDrawer;
