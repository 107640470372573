import React from 'react';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';

import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import { Icon } from '@rmwc/icon';

import { LoaderComponent } from 'components';

import { ReactComponent as GasIcon } from 'assets/gas.svg';
import { ReactComponent as ElectricityIcon } from 'assets/electricity.svg';
import { ReactComponent as WaterIcon } from 'assets/water.svg';
import { ReactComponent as PinIcon } from 'assets/pin.svg';
import { ReactComponent as YayImage } from 'assets/yay.svg';

import { Notification, priorityTypes, utilityObj, utilityObjRev } from 'utilities';

import DrawerContentHeading from './DrawerContentHeading';

import styles from './ActivitiesDrawer.module.scss';

const mapActivities = (recentActivitiesData: Notification[]) => {
  return (recentActivitiesData || []).map((x) => {
    const title = `${x?.dueDate ? 'Reminder' : 'Alert'} ${x?.label.slice(0, x?.label.indexOf(x?.buildingLabel))} ${
      x.loadLabel
    }`;
    const theDate = x.dueDate || x.createdOn;
    const dtLbl = x.dueDate ? `Due ${moment(`${theDate}Z`).format('MMM DD')}` : moment(`${theDate}Z`).fromNow();
    return {
      id: x.notificationId,
      title: title,
      description: x.label,
      timeAgo: dtLbl,
      buildingLabel: x.buildingLabel,
      hide: false,
      pinned: x.new,
      hasWater: x.loadUtilityType === utilityObjRev.water,
      hasEnergy: x.loadUtilityType !== utilityObjRev.water,
    };
  });
};

export const ActivitiesDrawer = ({
  manageRightDrawer,
  loadingRecentActivities,
  recentActivitiesData,
  selectAreaById,
}) => {
  const history = useHistory();
  const [statefulNotifications, setStatefulNotifications] = React.useState([]);

  React.useEffect(() => {
    const notifications = mapActivities(recentActivitiesData || []);
    setStatefulNotifications(notifications);
    // eslint-disable-next-line
  }, [loadingRecentActivities]);

  return (
    <div id="activitiesDrawer" className={styles.activitiesDrawer}>
      <DrawerContentHeading title="Recent Activities" manageRightDrawer={manageRightDrawer} />

      <div className={styles.notificationList}>
        {loadingRecentActivities && <LoaderComponent absolute />}
        {!loadingRecentActivities && (
          <>
            {statefulNotifications.length === 0 && (
              <div className={styles.noDataWrapper}>
                <YayImage />
                <div className={styles.noDataHeading}>
                  <Typography use="body1">Hooray, you're up to date!</Typography>
                </div>
                <div className={styles.noDataContent}>
                  <Typography use="body1">Alerts about your activity will show up here.</Typography>
                </div>
              </div>
            )}
            {statefulNotifications.map((x) => {
              return (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    manageRightDrawer(true, 'activity', x.id);
                  }}
                  key={x.id}
                  className={`${styles.notification} ${x.pinned ? styles.pinned : ''} ${x.hide ? styles.hide : ''}`}>
                  <label className={styles.notificationContent}>
                    <span className={styles.headingWrapper}>
                      {x.hasEnergy && <ElectricityIcon className={styles.MEDIUM} />}
                      {x.hasWater && <WaterIcon className={styles.MEDIUM} />}
                      <Typography use="body2" className={styles.heading}>
                        {x.title}
                      </Typography>
                    </span>
                    <span className={styles.timeAgo}>
                      <Typography use="body2">{x.timeAgo}</Typography>

                      <Typography use="body2"> - {x.buildingLabel}</Typography>
                    </span>
                  </label>
                </a>
              );
            })}
          </>
        )}
      </div>
      <div className={styles.drawerFooter}>
        <Link
          to="/app/activities"
          onClick={(e) => {
            e.preventDefault();
            manageRightDrawer(false, 'activities', null);
            setTimeout(() => {
              history.push('/app/activities');
              selectAreaById(null);
            });
          }}>
          {statefulNotifications.length === 0 ? 'Go to Activities' : 'View all activities'}
        </Link>
      </div>
    </div>
  );
};

export default ActivitiesDrawer;
