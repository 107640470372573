import React, { Suspense } from 'react';
import { Route, RouteProps, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import LogRocket from 'logrocket';

import { Helmet } from 'react-helmet';
import RWMC from 'RMWC';
import { ToastContainer } from 'react-toastify';

import AppProvider from 'utilities/state';
import { getParameterByName } from 'utilities';
import { GuestGuard, AuthGuard, LoaderComponent, GoogleAnalyticsComponent } from 'components';
import { CoreLayout, AuthenticatedLayout } from 'layouts';
import 'nprogress/nprogress.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-circular-progressbar/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-dropdown-tree-select/dist/styles.css';
// import 'react-sortable-tree/style.css';
import '@nosferatu500/react-sortable-tree/style.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/index.scss';

function initLogging() {
  if (window.location.hostname === 'hub.esphq.com') {
    LogRocket.init('yqmfdf/esp-hub', {
      network: {
        requestSanitizer: (request) => {
          // @ts-ignore no private requests logged
          if (request && request.url) {
            // @ts-ignore
            const url = request.url as string;
            if (url.indexOf('identity') > -1) {
              return null;
            }
          }
          try {
            // @ts-ignore
            if (request && request.headers && request.headers.authorization) {
              // @ts-ignore
              request.headers.authorization = '';
            }
            // @ts-ignore
            if (request && request.headers && request.headers.Authorization) {
              // @ts-ignore
              request.headers.Authorization = '';
            }
          } catch (ex) {
            console.log('requestSanitizer', ex);
          }
          return request;
        },
        responseSanitizer: (response: any) => {
          try {
            if (response) {
              response.body = null;
              if (
                response.headers &&
                response.headers.location &&
                response.headers.location.indexOf('access_token=') > -1
              ) {
                response.headers.location = 'access_token removed';
              }
              if (
                response.headers &&
                response.headers.Location &&
                response.headers.Location.indexOf('access_token=') > -1
              ) {
                response.headers.Location = 'access_token removed';
              }
            }
          } catch (ex) {
            console.log('responseSanitizer', ex);
          }
          return response;
        },
      },
    });
    LogRocket.getSessionURL(function (sessionURL) {
      // @ts-ignore
      gtag('event', 'LogRocket', {
        event_category: 'LogRocket',
        event_action: sessionURL,
      });
    });
  }
}

function App() {
  initLogging();

  let scaleViewport = false;
  let scale = '0.45';
  if (window.innerWidth < 1024) {
    scaleViewport = true;
    scale = (window.innerWidth / 1024 - 0.01).toFixed(2);
  }
  const viewport = scaleViewport
    ? `width=1024,initial-scale=${scale},minimum-scale=${scale},maximum-scale=${scale}`
    : 'width=device-width, initial-scale=1';

  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: '{{CLIENT_SECRET}}',
  // };
  return (
    <RWMC>
      <Helmet>
        <meta name="viewport" content={viewport} />
        <body className={window.location.href.indexOf('export') > -1 ? 'exporting' : ''} />
      </Helmet>
      <BrowserRouter>
        <AppProvider>
          <Switch>
            <NormalRoute exact path="/callback" component={LoaderComponent} />
            <NormalRoute path="/404" component={lazyLoad('./views/NotFound/NotFound')} />
            <NormalRoute exact path="/" component={lazyLoad('./views/Login/Login')} />
            <NormalRoute path="/login" component={lazyLoad('./views/Login/Login')} />
            <NormalRoute path="/forgot-password" component={lazyLoad('./views/ForgotPassword/ForgotPassword')} />
            {/* <NormalRoute path="/welcome/:code/:email" component={lazyLoad('./views/ResetPassword/ResetPassword')} /> */}
            {/* <NormalRoute path="/register" component={lazyLoad('./views/Register/Register')} /> */}
            <NormalRoute path="/verify/:code/:email" component={lazyLoad('./views/Verify/Verify')} />
            {/* <NormalRoute path="/app/register/organisation/:contactId" component={lazyLoad('./views/Verify/Verify')} /> */}
            {/* <NormalRoute path="/app/register/payment/:accountId" component={lazyLoad('./views/Verify/Verify')} /> */}
            <NormalRoute
              path="/reset-password/:code/:email"
              component={lazyLoad('./views/ResetPassword/ResetPassword')}
            />
            <NormalRoute
              path="/welcome/:code/:email"
              component={lazyLoad('./views/CompleteRegistration/CompleteRegistration')}
            />
            <AuthenticatedRoute path="/app/activities" component={lazyLoad('./views/Activities/Activities')} />
            <AuthenticatedRoute
              path="/app/operations"
              component={lazyLoad('./views/OperationDashboard/OperationDashboard')}
            />
            <AuthenticatedRoute
              path="/app/account-settings"
              component={lazyLoad('./views/AccountSettings/AccountSettings')}
            />
            <AuthenticatedRoute path="/app/carbon" exact component={lazyLoad('./views/Carbon/Carbon')} />

            <AuthenticatedRoute
              path="/app/carbon/inventories/:step/:id"
              component={lazyLoad('./views/Carbon/Assessment/index')}
            />
            <AuthenticatedRoute
              path="/app/carbon/inventories/:step"
              component={lazyLoad('./views/Carbon/Assessment/index')}
            />
            <AuthenticatedRoute
              path="/app/carbon/inventories"
              component={lazyLoad('./views/Carbon/Assessment/index')}
            />
            <AuthenticatedRoute path="/app" component={lazyLoad('./views/Detail/Detail')} />
            <Route
              path="/federated"
              component={() => {
                const sso = getParameterByName('sso');
                console.log(sso);
                if (sso.toString() === '3') {
                  const token = getParameterByName('t');
                  const sessionId = getParameterByName('s');
                  const d = new Date();
                  d.setTime(d.getTime() + 60 * 60 * 1000);
                  let expires = d.toUTCString();
                  localStorage.setItem('token', token);
                  localStorage.setItem('sessionId', sessionId);
                  localStorage.setItem('expires', expires);
                }
                return <Redirect to={`/login?sso=${sso}`} />;
              }}
            />
            <Route
              exact
              path="/logout"
              component={() => {
                sessionStorage.clear();
                localStorage.clear();
                return <Redirect to="/login" />;
              }}
            />
            <Route render={() => <Redirect to="/404" />} />
          </Switch>
        </AppProvider>
        <GoogleAnalyticsComponent />
        <ToastContainer theme="colored" limit={5} />
      </BrowserRouter>
    </RWMC>
  );
}

const lazyLoad = (path: string) => {
  return React.lazy(() => import(`${path}`));
};

const NormalRoute: React.FC<RouteProps> = ({ path, exact, component }) => {
  const Component = component;
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        return (
          <GuestGuard>
            <CoreLayout>
              <Suspense fallback={null}>
                <Component {...props} />
              </Suspense>
            </CoreLayout>
          </GuestGuard>
        );
      }}></Route>
  );
};

const AuthenticatedRoute: React.FC<RouteProps> = ({ path, exact, component }) => {
  const Component = component;
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => (
        <AuthGuard>
          <AuthenticatedLayout>
            <Suspense fallback={null}>
              <Component {...props} />
            </Suspense>
          </AuthenticatedLayout>
        </AuthGuard>
      )}></Route>
  );
};

export default App;
