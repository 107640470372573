import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { SimpleMenu, SimpleMenuSurface } from '@rmwc/menu';
import { Typography } from '@rmwc/typography';
import { Ripple } from '@rmwc/ripple';
import { TopAppBar, TopAppBarFixedAdjust, TopAppBarRow, TopAppBarSection, TopAppBarTitle } from '@rmwc/top-app-bar';
import { List, ListItem, SimpleListItem } from '@rmwc/list';
import { Icon } from '@rmwc/icon';
import { TextField } from '@rmwc/textfield';

import { ReactComponent as HelpIcon } from 'assets/help.svg';
import { ReactComponent as ActivityIcon } from 'assets/activity.svg';
import { ReactComponent as HeatMeterIcon } from 'assets/heat-meter.svg';

import { SearchComponent } from 'components';
import { logout, useAppState, getInitials } from 'utilities';

import styles from './Navbar.module.scss';

export interface NavbarComponentProps {
  open: boolean;
  toggleDrawer: () => void;
  setOpen: (open: boolean) => void;
}

export const NavbarComponent: React.FC<NavbarComponentProps> = (props) => {
  const {
    user,
    selectedAccount,
    recentActivitiesData,
    loadingAccountMetadata,
    selectAreaById,
    selectAccount,
    loadChartAndTableDataOntoAreas,
    manageRightDrawer,
  } = useAppState();

  const reports = JSON.parse(sessionStorage.getItem('permittedReports'));
  const hasNewNotifications = recentActivitiesData?.find((x) => x.new);
  const hasAnalyticsAccess = selectedAccount?.subscriptions?.find((x) => x.type === 1);
  const hasCarbonHubAccess = selectedAccount?.subscriptions?.find((x) => x.type === 2);

  return (
    <div id="navbar" className={styles.navbar}>
      <TopAppBar fixed>
        <TopAppBarRow className={styles.navbarRow}>
          <TopAppBarSection alignStart className={styles.logoWrapper}>
            <TopAppBarTitle className={styles['title']}>
              <Link
                id="logo"
                className={styles.logoLink}
                to={hasAnalyticsAccess ? '/app/dashboard' : '/app/carbon'}
                onClick={(e) => {
                  selectAreaById(null);
                }}>
                <div className={styles.logo} />
              </Link>
            </TopAppBarTitle>
          </TopAppBarSection>

          <TopAppBarSection
            className={`${styles.accountsWrapper} ${
              user?.profile?.accounts?.length > 1 ? styles.multiAccounts : null
            }`}>
            {user?.profile?.accounts?.length > 1 && (
              <AccountPickerComponent
                accounts={user?.profile?.accounts || []}
                selectedAccount={selectedAccount}
                selectAccount={selectAccount}
              />
            )}
          </TopAppBarSection>

          {loadingAccountMetadata ? null : (
            <>
              <TopAppBarSection className={styles.mainMenuWrapper}>
                <MainMenuComponent
                  reports={reports}
                  loadChartAndTableDataOntoAreas={loadChartAndTableDataOntoAreas}
                  selectAreaById={selectAreaById}
                  hasAccounts={user?.profile?.accounts?.length > 0}
                  hasAnalyticsAccess={hasAnalyticsAccess}
                  hasCarbonHubAccess={hasCarbonHubAccess}
                />
              </TopAppBarSection>

              {hasAnalyticsAccess && (
                <TopAppBarSection className={styles.searchWrapper}>
                  <SearchComponent />
                </TopAppBarSection>
              )}

              <TopAppBarSection id="rightMenu" className={styles.profileWrapper}>
                {hasAnalyticsAccess && (
                  <Notifications manageRightDrawer={manageRightDrawer} hasNewNotifications={hasNewNotifications} />
                )}
                <Help />
                <Profile
                  displayName={user?.profile?.particulars?.displayName}
                  firstName={user?.profile?.particulars?.firstName}
                  lastName={user?.profile?.particulars?.lastName}
                  email={user?.profile?.particulars.emailAddress}
                />
              </TopAppBarSection>
            </>
          )}
        </TopAppBarRow>
      </TopAppBar>
      <TopAppBarFixedAdjust />
    </div>
  );
};

export const AccountPickerComponent = ({ accounts, selectedAccount, selectAccount }) => {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');
  let searchField = React.useRef(null as HTMLInputElement);
  const searchStr = search.toString().toLowerCase();

  const handleWindowClick = (e) => {
    try {
      // @ts-ignore
      if (!document.getElementById('accountPicker').contains(e.target)) {
        setOpen(false);
      }
    } catch (e) {}
  };
  React.useEffect(() => {
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  });

  return (
    <SimpleMenuSurface
      rootProps={{ id: 'accountPicker', className: styles.accountPicker }}
      anchorCorner="bottomLeft"
      open={open}
      handle={
        <div className={styles.accountPickerLinkWrapper}>
          <Ripple>
            <a
              href="#"
              title={selectedAccount?.label}
              onClick={(e) => {
                e.preventDefault();
                setOpen(!open);
                if (!open) {
                  setTimeout(() => {
                    if (searchField && searchField.current) {
                      searchField.current.focus();
                    }
                  }, 500);
                }
              }}>
              <Typography use="body2">{selectedAccount?.label}</Typography>
              <Icon
                icon={open ? 'arrow_drop_up' : 'arrow_drop_down'}
                onClick={() => {
                  setOpen(!open);
                }}
              />
            </a>
          </Ripple>
        </div>
      }>
      <TextField
        ref={searchField}
        icon="search"
        placeholder="Search"
        name="search"
        type="text"
        className={styles.search}
        autoComplete={'off'}
        autoCorrect={'off'}
        onChange={(evt) => {
          setSearch(evt.currentTarget.value);
        }}
      />
      <List
        onAction={(evt) => {
          const index = evt.detail.index;
          selectAccount(accounts[index]);
          setOpen(false);
        }}>
        {accounts.map((x, i) => {
          const exists = x.label.toLowerCase().indexOf(searchStr) > -1;
          return (
            <ListItem key={x.label + i} style={{ display: exists ? 'flex' : 'none' }}>
              <Typography use="body2">{x.label}</Typography>
            </ListItem>
          );
        })}
      </List>
    </SimpleMenuSurface>
  );
};

export const Notifications = ({ manageRightDrawer, hasNewNotifications }) => (
  <div className={`${styles.notificationsLinkWrapper} ${hasNewNotifications ? styles.hasNewNotifications : null}`}>
    <Ripple>
      <a
        id="notificationsLink"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          manageRightDrawer(true, 'activities', null);
        }}
        className={styles.notificationsLink}>
        <Icon icon="notifications" />
      </a>
    </Ripple>
    {hasNewNotifications ? <span className={styles.bubble} /> : null}
  </div>
);

export const Profile = ({ displayName, firstName, lastName, email }) => {
  const [open, setOpen] = React.useState(false);
  const initials = getInitials(displayName, firstName, lastName);

  const handleWindowClick = (e) => {
    try {
      // @ts-ignore
      if (!document.getElementById('profileContainer').contains(e.target)) {
        setOpen(false);
      }
    } catch (e) {}
  };
  React.useEffect(() => {
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  });

  return (
    <SimpleMenu
      anchorCorner="bottomLeft"
      open={open}
      rootProps={{ id: 'profileContainer' }}
      handle={
        <div className={styles.profileLinkWrapper}>
          <Ripple>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setOpen(!open);
              }}
              className={styles.profileLink}>
              {initials}
            </a>
          </Ripple>
        </div>
      }>
      <li className={styles.profile}>
        <div className={styles.profileUser}>
          <div className={styles.initials}>{initials}</div>
          <Typography use="body2" className={styles.profileName}>
            {displayName}
          </Typography>
          <Typography use="caption" className={styles.profileEmail}>
            {email}
          </Typography>
        </div>

        <div className={styles.accountSettings}>
          <Link
            to="/app/account-settings"
            className={styles.accountSettingsLink}
            onClick={() => {
              setOpen(!open);
            }}>
            <Icon icon="account_balance" className={styles.accountSettingsIcon} />
            <Typography use="body2" className={styles.label}>
              Account Settings
            </Typography>
          </Link>
        </div>

        <Ripple>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              logout(false, true);
            }}
            className={styles.signOut}>
            <Typography use="body2">Sign Out</Typography>
          </a>
        </Ripple>
      </li>
    </SimpleMenu>
  );
};

export const Help = () => {
  const [open, setOpen] = React.useState(false);

  const handleWindowClick = (e) => {
    try {
      // @ts-ignore
      if (!document.getElementById('helpContainer').contains(e.target)) {
        setOpen(false);
      }
    } catch (e) {}
  };
  React.useEffect(() => {
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  });

  return (
    <SimpleMenu
      anchorCorner="bottomLeft"
      open={open}
      rootProps={{ id: 'helpContainer' }}
      handle={
        <div className={styles.helpLinkWrapper}>
          <Ripple>
            <a
              id="helpLink"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setOpen(!open);
              }}
              className={styles.helpLink}>
              <HelpIcon />
            </a>
          </Ripple>
        </div>
      }>
      <List twoLine className={styles.helpList}>
        <Ripple>
          <a id="helpTutorialLink" href="/ESP Hub - Getting Started Guide - v3.pdf" target="_blank">
            <SimpleListItem graphic="get_app" text="Download tutorial" secondaryText="A quick and useful user guide" />
          </a>
        </Ripple>
        <Ripple>
          <a id="helpEmailLink" href="mailto:help@esphq.com">
            <SimpleListItem graphic="email" text="Email for help" secondaryText="help@esphq.com" />
          </a>
        </Ripple>
      </List>
    </SimpleMenu>
  );
};

export const MainMenuComponent = ({
  reports,
  loadChartAndTableDataOntoAreas,
  selectAreaById,
  hasAccounts,
  hasAnalyticsAccess,
  hasCarbonHubAccess,
}) => {
  const [, setOpen] = React.useState(false);
  const history = useHistory();
  const showOperationsReport = hasAnalyticsAccess && reports && reports.find((x) => x.type === 1);

  const isDashboardActive =
    window.location.pathname === '/app' || window.location.pathname.indexOf('/app/dashboard') > -1;
  const isReportsActive = window.location.pathname.indexOf('/app/operations') > -1;
  const isActivitiesActive = window.location.pathname.indexOf('/app/activities') > -1;
  const isCarbonActive = window.location.pathname.indexOf('/app/carbon') > -1;

  const handleWindowClick = (e) => {
    try {
      // @ts-ignore
      if (!document.getElementById('mainMenu').contains(e.target)) {
        setOpen(false);
      }
    } catch (e) {}
  };
  React.useEffect(() => {
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  });

  return (
    <div id="mainMenu" className={styles.mainMenu}>
      {hasCarbonHubAccess && (
        <Ripple>
          <Link to="/app/carbon" className={`${styles.mainMenuLink} ${isCarbonActive ? styles.active : ''}`}>
            <Icon icon={<HeatMeterIcon />} />
            <Typography use="body2">CARBON</Typography>
          </Link>
        </Ripple>
      )}

      {hasAccounts && hasAnalyticsAccess && (
        <Ripple>
          <Link
            to="/app/dashboard"
            className={`${styles.mainMenuLink} ${isDashboardActive ? styles.active : ''}`}
            onClick={(e) => {
              if (window.location.href.indexOf('?') > -1) {
                e.preventDefault();
                history.push('/app/detail' + window.location.search);
              }
              loadChartAndTableDataOntoAreas();
            }}
            // onClick={(e) => {
            //   const href = window.location.href;
            //   if (href.indexOf('?') > -1) {
            //     e.preventDefault();
            //     window.location.href = '/app/detail' + window.location.search;
            //   }
            // }}
          >
            <Icon icon="multiline_chart" />
            <Typography use="body2">ANALYTICS</Typography>
          </Link>
        </Ripple>
      )}

      {hasAccounts && hasAnalyticsAccess ? (
        <Ripple>
          <Link
            to="/app/account-settings/bills"
            className={`${styles.mainMenuLink} ${
              window.location.pathname.indexOf('/app/account-settings/bill') > -1 ? styles.active : ''
            }`}
            onClick={(e) => {
              selectAreaById(null);
            }}>
            <Icon icon={{ icon: 'description', basename: 'material-icons-outlined' }} />
            <Typography use="body2">BILLS</Typography>
          </Link>
        </Ripple>
      ) : null}

      {hasAccounts && showOperationsReport && (
        <Ripple>
          <Link
            to="/app/operations"
            className={`${styles.mainMenuLink} ${isReportsActive ? styles.active : ''}`}
            onClick={(e) => {
              selectAreaById(null);
            }}>
            <Icon icon={{ icon: 'poll', basename: 'material-icons-outlined' }} />
            <Typography use="body2">REPORTS</Typography>
          </Link>
        </Ripple>
      )}

      {hasAccounts && hasAnalyticsAccess && (
        <Ripple>
          <Link
            to="/app/activities"
            className={`${styles.mainMenuLink} ${isActivitiesActive ? styles.active : ''}`}
            onClick={(e) => {
              if (window.location.href.indexOf('?') > -1) {
                e.preventDefault();
                history.push('/app/activities' + window.location.search);
              } else {
              }
              selectAreaById(null);
            }}>
            <ActivityIcon />
            <Typography use="body2">ACTIVITIES</Typography>
          </Link>
        </Ripple>
      )}
    </div>
  );
};

export default NavbarComponent;
