import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { useAppState } from 'utilities';
import LoaderComponent from 'components/Loader/Loader';

interface GuestGuardProps {
  children?: ReactNode;
}

export const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { authenticating, authenticated } = useAppState();
  if (authenticating) {
    return <LoaderComponent />;
  } else if (authenticated) {
    return <Redirect to="/app" />;
  }
  return <>{children}</>;
};

export default GuestGuard;
