import React from 'react';
import { AreaDto, SearchResult } from './interfaces';

export const handleSearch = (
  search: string,
  areas: AreaDto[],
  results: SearchResult[],
  combinedText: string,
  combinedJsx: JSX.Element
) => {
  const s = search.toLowerCase();
  areas.forEach((area, i) => {
    let highlightText = '';
    try {
      highlightText = area.name.replace(new RegExp(search, 'gi'), (match) => `<b>${match}</b>`);
    } catch (ex) {
      highlightText = '';
    }
    const newText = `${combinedText} ${area.name}`;
    const newJsx = (
      <>
        {combinedJsx}
        <label>
          {combinedJsx ? <sub>&gt;</sub> : null}
          <span dangerouslySetInnerHTML={{ __html: highlightText }} title={area.name} />
        </label>
      </>
    );
    if (newText.toLowerCase().indexOf(s) > -1 && area.type.indexOf('channel') === -1) {
      results.push({
        index: i,
        jsx: newJsx,
        area: area,
      });
    }
    handleSearch(search, area.subareas, results, newText, newJsx);
  });
  return results;
};
