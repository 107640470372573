import React from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './SkeletonDrawer.module.scss';

export const SkeletonDrawer: React.FC = () => {
  return (
    <div className={styles.skeletonDrawer}>
      <div className={styles.back}>
        <Skeleton height={24} width={85} />
      </div>
      {new Array(30).fill(0).map((x, i) => (
        <Skeleton height={40} key={i} />
      ))}
    </div>
  );
};

export default SkeletonDrawer;
