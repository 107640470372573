import React from 'react';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';

import styles from './DrawerContentHeading.module.scss';

export const DrawerContentHeading: React.FC<any> = ({ title, titleSmall, titleIcon, manageRightDrawer, children }) => {
  return (
    <div className={`${styles.drawerContentHeading} ${titleIcon ? styles.withIcon : null}`}>
      {titleIcon ? <div className={styles.titleIconContainer}>{titleIcon}</div> : null}
      <Typography use="headline5" className={titleSmall ? styles.small : ''}>
        {title}
      </Typography>
      <a
        href="#"
        className={styles.closeNavbar}
        onClick={(e) => {
          e.preventDefault();
          manageRightDrawer(false, 'activities', null);
        }}>
        <Icon icon="close" />
      </a>
      {children}
    </div>
  );
};

export default DrawerContentHeading;
