import React from 'react';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas';
import { toast } from 'react-toastify';
import {
  RetailerDto,
  AuthorityLetterDto,
  isPhone,
  AuthorityLetterStatusRev,
  AuthorityLetterStatus,
  AuthorityLoadDto,
  AuthorityLoadSearchDto,
} from 'utilities';
import { Calendar } from 'react-date-range';
import { Link } from 'react-router-dom';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';

import { Icon } from '@rmwc/icon';
import { Controller, useForm } from 'react-hook-form';
import { isEmail, AuthorityDto } from 'utilities';
import { LoaderComponent } from 'components';
import { ReactComponent as YayImage } from 'assets/yay.svg';

import DrawerContentHeading from './DrawerContentHeading';
import LoadPicker from './LoadPicker';
import styles from './AuthorityDrawer.module.scss';

const getSelectedLoads = (mappedData: any[]) => {
  const loadIds = [];
  const innerFn = (data: any[]) => {
    data.forEach((x) => {
      if (x.type === 'load') {
        if (x.checked) {
          loadIds.push(x.value);
        }
      } else {
        innerFn(x.children);
      }
    });
  };
  innerFn(mappedData);
  return loadIds;
};

interface AuthorityDrawerProps {
  authorityId: string;
  extraParams?: any;
  manageRightDrawer: (open: boolean, type: string, id?: string) => void;
  updateAuthority: (authority: AuthorityDto) => Promise<AuthorityDto>;
  createAuthority: (authority: AuthorityDto) => Promise<AuthorityDto>;
  getAuthority: (authorityId: string) => Promise<AuthorityDto>;
  getAuthorityLetters: (authorityId: string) => Promise<AuthorityLetterDto[]>;
  getRetailers: () => Promise<RetailerDto[]>;
  getAuthorityLoads: () => Promise<AuthorityLoadSearchDto[]>;
  createAuthorityLetter: (authorityId: string, authorityLetter: AuthorityLetterDto) => Promise<AuthorityLetterDto>;
  updateAuthorityLetter: (authorityLetter: AuthorityLetterDto) => Promise<AuthorityLetterDto>;
  deleteAuthorityLetter: (authorityLetter: AuthorityLetterDto) => Promise<boolean>;
}

export const AuthorityDrawer = ({
  authorityId,
  extraParams,
  manageRightDrawer,
  updateAuthority,
  createAuthority,
  getAuthority,
  getAuthorityLetters,
  getRetailers,
  getAuthorityLoads,
  createAuthorityLetter,
  updateAuthorityLetter,
  deleteAuthorityLetter,
}: AuthorityDrawerProps) => {
  const isCreate = !authorityId;
  const steps = ['createForm', 'loads', 'createLetter', 'email', 'complete'];

  let defaultStep = steps[0];
  if (extraParams?.selectedOptionText) {
    defaultStep = extraParams?.selectedOptionText;
  }

  const [submitting, setSubmitting] = React.useState(false);
  const [step, setStep] = React.useState(defaultStep);

  const [authority, setAuthority] = React.useState(null as AuthorityDto);
  const [authorityLetter, setAuthorityLetter] = React.useState(null as AuthorityLetterDto);
  const [authorityLetters, setAuthorityLetters] = React.useState(null as AuthorityLetterDto[]);
  const [loadedAuthority, setLoadedAuthority] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [retailers, setRetailers] = React.useState([] as RetailerDto[]);
  const [loads, setLoads] = React.useState([] as AuthorityLoadDto[]);
  const [selectedNodes, setSelectedNodes] = React.useState([]);

  const getBackLabel = () => {
    if (isCreate) {
      return steps.indexOf(step) === 0 ? 'CANCEL' : 'BACK';
    } else {
      return 'CLOSE';
    }
  };

  const getNextLabel = () => {
    if (isCreate) {
      return 'NEXT';
    } else if (step === 'viewLetters') {
      return 'ADD NEW LOA';
    } else if (step === 'email') {
      return 'NEXT';
    } else {
      return 'SAVE';
    }
  };

  const handleBack = () => {
    if (step === 'createForm') {
      sessionStorage.setItem('clearAccountData', 'yes');
      manageRightDrawer(false, 'authority', null);
    } else if (!isCreate) {
      manageRightDrawer(false, 'authority', null);
    } else {
      setStep(steps[steps.indexOf(step) - 1]);
    }
  };

  const handleNext = () => {
    const hiddenBtn = document.getElementById('hiddenBtn') as HTMLElement;
    if (hiddenBtn) {
      hiddenBtn.click();
    } else if (step === 'loads') {
      submitGroupsHierarchy();
    } else if (step === 'viewLetters') {
      setAuthorityLetter(null);
      setStep('createLetter');
    } else {
      setSubmitting(true);
      setTimeout(() => {
        setStep(steps[steps.indexOf(step) + 1]);
        setSubmitting(false);
      }, 1000);
    }
  };

  const submitCreate = async (values) => {
    setSubmitting(true);
    let method = authority ? updateAuthority : createAuthority;
    const data = { ...values };
    delete data.createdOn;
    delete data.updatedOn;
    const result = await method(data);
    if (result) {
      sessionStorage.setItem('clearAccountData', 'yes');
      sessionStorage.removeItem('cachedAuthorities');
      setAuthority(result);
      setTimeout(() => {
        if (isCreate) {
          setStep(steps[steps.indexOf(step) + 1]);
        } else {
          manageRightDrawer(false, 'authority', null);
        }
      }, 0);
    }
    setSubmitting(false);
  };

  const submitGroupsHierarchy = async () => {
    const loadIds = getSelectedLoads(selectedNodes);
    const data = { ...authority, loads: loadIds.map((x) => ({ loadId: x })) };
    setSubmitting(true);
    const result = await updateAuthority(data);
    if (result) {
      sessionStorage.setItem('clearAccountData', 'yes');
      if (isCreate) {
        setStep(steps[steps.indexOf(step) + 1]);
      } else {
        manageRightDrawer(false, 'authority', null);
      }
    }
    setSubmitting(false);
  };

  const submitAuthorityLetter = async (values) => {
    setSubmitting(true);
    if (values?.letterId) {
      await updateAuthorityLetter(values);
      setAuthorityLetter(values);
      setStep('viewLetters');

      toast.success(`Letter of authority "${values.label}" successfully updated.`);
    } else {
      const result = await createAuthorityLetter(authority.authorityId, values);
      setAuthorityLetter(result);
      setStep(steps[steps.indexOf(step) + 1]);
      // mark other letters as expired
      authorityLetters
        ?.filter((x) => x.status === AuthorityLetterStatusRev.Active)
        .forEach((letter) => {
          updateAuthorityLetter({ ...letter, status: AuthorityLetterStatusRev.Expired });
        });
    }
    setSubmitting(false);
    sessionStorage.setItem('clearAccountData', 'yes');
    setLoadedAuthority(false);
  };

  React.useEffect(() => {
    if (!loadedAuthority) {
      const innerFn = async () => {
        const authorityItem = authority?.authorityId ? authority : authorityId ? await getAuthority(authorityId) : null;
        if (!isCreate) {
          setAuthority(authorityItem);
        }
        if (step === 'createForm') {
          const retailers = await getRetailers();
          setRetailers(retailers);
        } else if (step === 'loads') {
          const result = await getAuthorityLoads();
          const authorityLoadIds = authorityItem?.loads.map((x) => x.loadId);
          result.forEach((x) => {
            if (authorityLoadIds.indexOf(x.loadId) > -1) {
              // @ts-ignore
              x.usedIn = [authorityItem.authorityId];
            } else {
              // @ts-ignore
              x.usedIn = [];
            }
          });
          setLoads(result);
        } else if (step === 'viewLetters') {
          const letters = await getAuthorityLetters(authorityId);
          const clonedLetters = [].concat(letters);
          clonedLetters.sort((a, b) => (moment(a.createdOn).isBefore(moment(b.createdOn)) ? 1 : -1));
          setAuthorityLetters(clonedLetters);
        }
        setTimeout(async () => {
          setLoadedAuthority(true);
        }, 0);
      };
      innerFn();
    }
    // eslint-disable-next-line
  }, [loadedAuthority]);

  // for moving from createForm to loads in create flow
  React.useEffect(() => {
    if (step === 'loads') {
      setLoadedAuthority(false);
    }
  }, [step]);

  const title = isCreate
    ? `Create a new authority${authority?.label ? `: ${authority.label}` : ''}`
    : `Update authority${authority?.label ? `: ${authority.label}` : ''}`;

  return (
    <div className={`${styles.authorityDrawer} ${step === 'groups' ? styles.capWidth : ''}`}>
      <DrawerContentHeading
        title={title}
        titleSmall={true}
        titleIcon={<Icon icon={{ icon: 'gavel', basename: 'material-icons-outlined' }} />}
        manageRightDrawer={manageRightDrawer}
      />

      {!loadedAuthority && (
        <div className={styles.loadingContainer}>
          <LoaderComponent absolute />
        </div>
      )}

      {loadedAuthority && step === 'createForm' && (
        <AuthorityCreateForm
          isCreate={isCreate}
          authority={authority}
          submitCreate={submitCreate}
          retailers={retailers}
        />
      )}

      {loadedAuthority && step === 'loads' && (
        <LoadPicker
          id={authority?.authorityId}
          label={`Assign loads to authority`}
          // authority={authority}
          selectedNodes={selectedNodes}
          setSelectedNodes={setSelectedNodes}
          loads={loads}
          isUserFlow={false}
          description={
            isCreate
              ? 'Thank you. Now lets assign the ICP’s or Meter’s that you would like your utility provider to provide ESP data access to.'
              : 'Let’s edit the list of ICP’s or Meter’s that you would like your utility provider to provide ESP data access to.'
          }
          disabled={false}
          showUtilityFilter={true}
        />
      )}

      {loadedAuthority && step === 'createLetter' && (
        <AuthorityCreateLetter
          isCreate={isCreate}
          user={extraParams?.user}
          authorityLetter={authorityLetter}
          submitAuthorityLetter={submitAuthorityLetter}
        />
      )}

      {loadedAuthority && step === 'viewLetters' && (
        <AuthorityLetters
          authorityLetters={authorityLetters}
          deleting={deleting}
          editAuthorityLetter={(letter: AuthorityLetterDto) => {
            setAuthorityLetter(letter);
            setStep('createLetter');
          }}
          deleteAuthorityLetter={async (letter: AuthorityLetterDto) => {
            if (window.confirm(`Are you sure you want to delete the letter of authority "${letter.label}?`)) {
              setDeleting(true);
              await deleteAuthorityLetter(letter);
              setDeleting(false);
              setLoadedAuthority(false);
            }
          }}
        />
      )}

      {loadedAuthority && step === 'email' && (
        <AuthorityShowEmail
          email={authority?.emailAddress}
          retailer={authority.retailer?.label}
          isView={defaultStep === 'email'}
        />
      )}

      {loadedAuthority && step === 'complete' && (
        <div className={styles.complete}>
          <div className={styles.noDataWrapper}>
            <YayImage />
            <div className={styles.noDataHeading}>
              <Typography use="body1">Thank you!</Typography>
            </div>
            <div className={styles.noDataContent}>
              <Typography use="body1">
                {isCreate
                  ? `The authority "${authority.label}" is all setup!`
                  : `The authority "${authority.label}" was updated`}
              </Typography>
            </div>
          </div>
        </div>
      )}

      <div className={`${styles.drawerFooter}`}>
        {step === 'complete' && (
          <Link
            to="/app/account-settings/authorities"
            onClick={(e) => {
              e.preventDefault();
              sessionStorage.setItem('clearAccountData', 'yes');
              setStep('createForm');
              manageRightDrawer(false, 'authority', null);
            }}>
            Close
          </Link>
        )}

        {step !== 'complete' && (
          <Button label={getBackLabel()} outlined type="button" disabled={submitting} onClick={handleBack} />
        )}

        {step !== 'complete' && (
          <Button
            label={getNextLabel()}
            raised
            type="button"
            icon={submitting ? <CircularProgress theme="secondary" /> : null}
            disabled={submitting}
            onClick={handleNext}
          />
        )}
      </div>
    </div>
  );
};

const AuthorityCreateForm = ({ authority, submitCreate, isCreate, retailers }) => {
  const defaultValues = {
    theLabel: authority?.label,
    theRetailer: authority?.retailer?.retailerId,
    theFrequency: authority?.frequency?.toString() || '2',
    // theEnableReminders: authority?.enableReminders ? true : !authority ? true : false,
    theCommenceOn: authority?.commenceOn,
  };

  const { errors, control, register, handleSubmit, setValue } = useForm({
    mode: 'all',
  });

  const [calendarOpen, setCalendarOpen] = React.useState(false);

  const frequencies = [
    { value: '2', label: 'Monthly' },
    { value: '3', label: 'Quarterly' },
    { value: '4', label: 'Half Yearly' },
    { value: '5', label: 'Yearly' },
  ];

  const onSubmit = (values) => {
    const payload = {
      ...authority,
      label: values.theLabel,
      retailer: {
        retailerId: values.theRetailer,
      },
      enableReminders: true, // values.theEnableReminders ? true : false,
      commenceOn: moment.utc(values.theCommenceOn, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00'),
      frequency: Number(values.theFrequency),
    };
    submitCreate(payload);
  };

  return (
    <div className={styles.authorityContent}>
      <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
        <div className={styles.createForm}>
          <div className={styles.row}>
            <div className={styles.col}>
              {isCreate ? (
                <Typography use="body1">
                  This letter of authority authorises your utility provider to release your utility billing data to ESP
                  for the duration of your utility contract. You may cancel/edit this authority at any time.
                </Typography>
              ) : (
                <Typography use="body1">
                  Let’s edit this letter of authority. You may cancel/edit this authority at any time.
                </Typography>
              )}
            </div>
          </div>
          <div className={styles.authorityHeading}>
            <Typography use="headline6">{isCreate ? 'Create Authority' : 'Edit Authority'}</Typography>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <TextField
                outlined
                label="Authority Name"
                name="theLabel"
                required
                inputRef={register({ required: true })}
                autoFocus
                defaultValue={defaultValues?.theLabel}
                floatLabel
              />
              {errors?.theLabel && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter in a authority name</Typography>
                </div>
              )}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <Controller
                name="theRetailer"
                control={control}
                defaultValue={defaultValues?.theRetailer}
                rules={{
                  required: true,
                }}
                as={
                  <Select
                    enhanced
                    className={styles.customSelect}
                    name="theRetailer"
                    options={retailers?.map((x) => ({ value: x.retailerId, label: x.label }))}
                    label="Retailer"
                    required
                    outlined
                    defaultValue={defaultValues?.theRetailer}
                  />
                }
              />
              {errors.theRetailer && (
                <div className={styles.error}>
                  <Typography use="caption">Please select an retailer</Typography>
                </div>
              )}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <Controller
                name="theFrequency"
                control={control}
                defaultValue={defaultValues?.theFrequency}
                rules={{
                  required: true,
                }}
                as={
                  <Select
                    enhanced
                    className={styles.customSelect}
                    name="theFrequency"
                    options={frequencies}
                    label="Frequency"
                    required
                    outlined
                    defaultValue={defaultValues?.theFrequency}
                  />
                }
              />
              {errors.theFrequency && (
                <div className={styles.error}>
                  <Typography use="caption">Please select an frequency</Typography>
                </div>
              )}
            </div>
            <div className={`${styles.col} ${styles.datePickerWrapper} `}>
              <TextField
                outlined
                label="Start From"
                className={styles.dueDate}
                type="text"
                name="theCommenceOn"
                inputRef={register()}
                // min={moment().format('DD-MM-YYYY')}
                required
                defaultValue={
                  defaultValues?.theCommenceOn
                    ? moment(defaultValues?.theCommenceOn).format('DD-MM-YYYY')
                    : moment().format('DD-MM-YYYY')
                }
                onClick={(e) => {
                  e.preventDefault();
                  setCalendarOpen(!calendarOpen);
                  return false;
                }}
              />

              <Calendar
                className={`${styles.calendar}  ${calendarOpen ? styles.calendarOpen : ''} `}
                // minDate={new Date()}
                onChange={(item) => {
                  setValue('theCommenceOn', moment(item).format('DD-MM-YYYY'));
                  setCalendarOpen(false);
                }}
                date={
                  defaultValues?.theCommenceOn
                    ? moment(defaultValues?.theCommenceOn, 'DD-MM-YYYY').toDate()
                    : moment().toDate()
                }
              />

              {errors.theCommenceOn && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid date</Typography>
                </div>
              )}
            </div>
          </div>

          {/* <div className={styles.row}>
            <div className={styles.col}>
              <Checkbox
                label={'Enable Reminders?'}
                defaultChecked={defaultValues?.theEnableReminders}
                {...register('theEnableReminders')}
                onChange={async (evt) => {
                  // @ts-ignore
                  const checked = evt.target.checked;
                  setValue('theEnableReminders', checked);
                }}
              />
            </div>
          </div> */}
        </div>

        <button id="hiddenBtn" type="submit" style={{ display: 'none' }} />
      </form>
    </div>
  );
};

const AuthorityCreateLetter = ({ isCreate, user, authorityLetter, submitAuthorityLetter }) => {
  const defaultValues = {
    theLabel: authorityLetter?.label || '',
    theJobTitle: authorityLetter?.jobTitle || '',
    theEmailAddress: authorityLetter?.emailAddress || user?.profile?.particulars?.emailAddress,
    theMobileNumber: authorityLetter?.mobileNumber || user?.profile?.particulars?.mobile,
    theAuthoriser: authorityLetter?.authoriser || user?.profile?.particulars?.displayName,
    theExpiresOn: authorityLetter?.expiresOn
      ? moment.utc(authorityLetter?.expiresOn).format('DD-MM-YYYY')
      : moment().add(2, 'years').format('DD-MM-YYYY'),
    theLetterDate: authorityLetter?.letterDate
      ? moment.utc(authorityLetter?.letterDate).format('DD-MM-YYYY')
      : moment().format('DD-MM-YYYY'),
    // @ts-ignore
    theSignature: authorityLetter?.signature ? `data:image/png;base64,${authorityLetter?.signature}` : null,
  };

  const { errors, register, handleSubmit, setValue } = useForm({
    mode: authorityLetter?.letterId ? 'all' : 'onChange',
  });

  const [calendarOpen, setCalendarOpen] = React.useState(false);
  const [expiresCalendarOpen, setExpiresCalendarOpen] = React.useState(false);

  const signaturePadRef = React.useRef<any>(null);

  const onSubmit = (values) => {
    if (signaturePadRef.current.isEmpty()) {
      toast.warn(`You must draw a signature before continuing`);
      return;
    }

    const payload = {
      ...authorityLetter,
      label: values.theLabel,
      jobTitle: values.theJobTitle,
      emailAddress: values.theEmailAddress,
      mobileNumber: values.theMobileNumber,
      authoriser: values.theAuthoriser,
      expiresOn: moment.utc(values.theExpiresOn, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00'),
      letterDate: moment.utc(values.theLetterDate, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00'),
      // @ts-ignore
      signature: signaturePadRef.current.toDataURL('image/png').replace('data:image/png;base64,', ''),
    };
    submitAuthorityLetter(payload);
  };

  React.useEffect(() => {
    if (defaultValues?.theSignature) {
      setTimeout(() => {
        // @ts-ignore
        signaturePadRef.current.fromDataURL(defaultValues?.theSignature);
      }, 0);
    }
  }, []);

  return (
    <div className={styles.authorityContent}>
      <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
        <div className={styles.createForm}>
          <div className={styles.row}>
            <div className={styles.col}>
              {isCreate ? (
                <Typography use="body1">
                  Thank you, nearly there. Please provide your details and set the date period that you would like ESP
                  to have access to. Please note the start date can be in the past if you would like ESP to collect
                  historic baseline data.
                </Typography>
              ) : (
                <Typography use="body1">
                  Please update your details and edit the date period that you would like ESP to have access to. Please
                  note the start date can be in the past if you would like ESP to collect historic baseline data.
                </Typography>
              )}
            </div>
          </div>
          <div className={styles.authorityHeading}>
            <Typography use="headline6">Letter of authority</Typography>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <TextField
                outlined
                label="Label"
                name="theLabel"
                required
                inputRef={register({ required: true })}
                autoFocus
                floatLabel
                defaultValue={defaultValues?.theLabel}
              />
              {errors?.theLabel && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter in a label</Typography>
                </div>
              )}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <TextField
                outlined
                label="Job Title"
                name="theJobTitle"
                required
                inputRef={register({ required: true })}
                autoFocus
                floatLabel
                defaultValue={defaultValues?.theJobTitle}
              />
              {errors?.theJobTitle && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter in a job title</Typography>
                </div>
              )}
            </div>
            <div className={styles.col}>
              <TextField
                outlined
                label="Authoriser"
                name="theAuthoriser"
                required
                inputRef={register({ required: true })}
                autoFocus
                floatLabel
                defaultValue={defaultValues?.theAuthoriser}
              />
              {errors?.authoriser && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter in a authoriser</Typography>
                </div>
              )}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <TextField
                outlined
                label="Email"
                name="theEmailAddress"
                required
                autoFocus
                floatLabel
                defaultValue={defaultValues?.theEmailAddress}
                inputRef={register({
                  validate: {
                    isEmail: (value) => isEmail(value),
                  },
                })}
              />
              {errors?.theEmailAddress && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter in a email address</Typography>
                </div>
              )}
            </div>
            <div className={styles.col}>
              <TextField
                outlined
                label="Mobile Number"
                name="theMobileNumber"
                required
                autoFocus
                floatLabel
                defaultValue={defaultValues?.theMobileNumber}
                inputRef={register({
                  validate: {
                    isPhone: (value) => isPhone(value),
                  },
                })}
              />
              {errors?.theMobileNumber && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter in a mobile number</Typography>
                </div>
              )}
            </div>
          </div>

          <div className={styles.row}>
            <div className={`${styles.col} ${styles.datePickerWrapper} `}>
              <TextField
                outlined
                label="Letter Date"
                className={styles.dueDate}
                type="text"
                name="theLetterDate"
                inputRef={register()}
                // min={moment().format('DD-MM-YYYY')}
                required
                defaultValue={defaultValues?.theLetterDate}
                onClick={(e) => {
                  e.preventDefault();
                  setCalendarOpen(!calendarOpen);
                  return false;
                }}
              />

              <Calendar
                className={`${styles.calendar} ${styles.leftCalendar} ${calendarOpen ? styles.calendarOpen : ''} `}
                // minDate={new Date()}
                onChange={(item) => {
                  setValue('theLetterDate', moment(item).format('DD-MM-YYYY'));
                  setCalendarOpen(false);
                }}
                date={moment(defaultValues?.theLetterDate, 'DD-MM-YYYY').toDate()}
              />

              {errors.theLetterDate && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid date</Typography>
                </div>
              )}
            </div>

            <div className={`${styles.col} ${styles.datePickerWrapper} `}>
              <TextField
                outlined
                label="Expires On"
                className={styles.dueDate}
                type="text"
                name="theExpiresOn"
                inputRef={register()}
                required
                defaultValue={defaultValues?.theExpiresOn}
                onClick={(e) => {
                  e.preventDefault();
                  setExpiresCalendarOpen(!expiresCalendarOpen);
                  return false;
                }}
              />

              <Calendar
                className={`${styles.calendar} ${expiresCalendarOpen ? styles.calendarOpen : ''} `}
                minDate={new Date()}
                onChange={(item) => {
                  setValue('theExpiresOn', moment(item).format('DD-MM-YYYY'));
                  setExpiresCalendarOpen(false);
                }}
                date={moment(defaultValues?.theExpiresOn, 'DD-MM-YYYY').toDate()}
              />

              {errors.theExpiresOn && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid date</Typography>
                </div>
              )}
            </div>
          </div>
          <div className={styles.row}>
            <div className={`${styles.col}`}>
              <div className={styles.signatureWrapper}>
                <label>
                  <Typography use="body1">
                    Signature <sup>*</sup>
                  </Typography>
                </label>
                <SignatureCanvas
                  ref={signaturePadRef}
                  clearOnResize={false}
                  canvasProps={{ width: 512, height: 152 }}
                />

                <Button
                  label={'Clear'}
                  icon="clear"
                  outlined
                  type="button"
                  className={styles.clearButton}
                  onClick={() => {
                    // @ts-ignore
                    signaturePadRef.current.clear();
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <button id="hiddenBtn" type="submit" style={{ display: 'none' }} />
      </form>
    </div>
  );
};

const AuthorityShowEmail = ({ retailer, email, isView }) => {
  return (
    <div className={styles.authorityContent}>
      <form data-private autoComplete="off">
        <div className={styles.createForm}>
          <div className={styles.row}>
            <div className={styles.col}>
              {isView ? (
                <Typography use="body1">
                  You may want to advise {retailer} to begin sending your monthly invoices to the secure e-mail address
                  below. Please note this e-mail is unique to your account and {retailer} and will not work for any
                  other utility provider.
                </Typography>
              ) : (
                <Typography use="body1">
                  Awesome, thank you! We will take it from here. You may also wish to advise {retailer} to begin sending
                  your monthly invoices to the secure e-mail address below. Please note this e-mail is unique to your
                  account and {retailer} and will not work for any other utility provider.
                </Typography>
              )}
            </div>
          </div>
          <div className={styles.authorityHeading}>
            <Typography use="headline6">Secure e-mail address</Typography>
          </div>
          <div className={styles.row}>
            <TextField
              outlined
              label="E-mail Address"
              type="text"
              readOnly
              defaultValue={email}
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

const AuthorityLetters = ({ authorityLetters, editAuthorityLetter, deleteAuthorityLetter, deleting }) => {
  return (
    <div className={styles.authorityContent}>
      <form data-private autoComplete="off">
        <div className={styles.createForm}>
          <div className={styles.row}>
            <div className={styles.col}>
              <Typography use="body1">
                Hello. Here is the list of previous authorities, you’ll note the active authority is at the top. To edit
                please click on the letter of authority (LoA) or if you wish to create a new LoA please click the “Add
                new LoA” button below.”
              </Typography>
            </div>
          </div>
          <div className={styles.authorityHeading}>
            <Typography use="headline6">Letters of authority</Typography>
          </div>
          <table className={styles.lettersTableWrapper}>
            <thead>
              <tr className={`${styles.lettersTableRow} ${styles.lettersTableHeader}`}>
                <td className={`${styles.lettersTableCell}`}>Date</td>
                <td className={`${styles.lettersTableCell}`}>Label</td>
              </tr>
            </thead>
            <tbody>
              {authorityLetters?.map((letter, i) => {
                return (
                  <tr className={`${styles.lettersTableRow}`} key={letter.letterId}>
                    <td className={`${styles.lettersTableCell}`}>
                      {moment.utc(letter.letterDate).format('DD/MM/YYYY')}
                    </td>
                    <td className={`${styles.lettersTableCell}`}>
                      <div className={styles.statusContainer}>
                        <label>{letter.label} </label>
                        <span
                          className={`${styles.badge} ${
                            AuthorityLetterStatus[letter.status] === 'Active' ? styles.active : styles.expired
                          }`}>
                          {AuthorityLetterStatus[letter.status]}
                        </span>
                        <a href={letter.letterUrl} target="_blank" title="Open PDF" rel="noreferrer">
                          <Icon icon="picture_as_pdf" />
                        </a>
                        <a
                          href="#"
                          title="Edit Letter of Authority"
                          onClick={(e) => {
                            e.preventDefault();
                            editAuthorityLetter(letter);
                          }}>
                          <Icon icon="edit" />
                        </a>
                        <a
                          href="#"
                          title="Delete Letter of Authority"
                          onClick={(e) => {
                            e.preventDefault();
                            if (!deleting) {
                              deleteAuthorityLetter(letter);
                            }
                          }}>
                          <Icon icon={deleting ? <CircularProgress theme="secondary" /> : 'delete'} />
                        </a>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </form>
    </div>
  );
};
export default AuthorityDrawer;
