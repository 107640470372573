import React from 'react';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { CollapsibleList, List, SimpleListItem } from '@rmwc/list';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';

import { SkeletonDrawer } from 'components';
import { useAppState } from 'utilities';

import { ReactComponent as ProjectIcon } from 'assets/project.svg';
import { ReactComponent as HeatMeterIcon } from 'assets/heat-meter.svg';

import styles from './CarbonDrawer.module.scss';

export interface CarbonDrawerComponentProps {
  open?: boolean;
}

export const CarbonDrawerComponent: React.FC<CarbonDrawerComponentProps> = ({ open }) => {
  const { loadingAccountMetadata, loadingCarbonInventories, carbonInventories } = useAppState();

  const history = useHistory();

  const [collapsibleListOpen, setCollapsibleListOpen] = React.useState(
    window.location.pathname.indexOf('/app/carbon/inventories/') > -1
  );

  if (loadingAccountMetadata || loadingCarbonInventories) {
    return (
      <div className={`${styles.carbonDrawer} ${!open ? styles.closed : styles.open}`}>
        <div className={styles.drawerContent}>
          <SkeletonDrawer />
        </div>
      </div>
    );
  }

  const clone = carbonInventories ? JSON.parse(JSON.stringify(carbonInventories)) : [];
  clone.sort((a, b) => {
    const aDate = moment(a.startDate);
    const bDate = moment(b.startDate);
    if (aDate.isAfter(bDate)) {
      return -1;
    } else if (aDate.isBefore(bDate)) {
      return 1;
    } else {
      return 0;
    }
  });

  return (
    <div id="carbonDrawer" className={`${styles.carbonDrawer} ${!open ? styles.closed : styles.open} `}>
      <div className={styles.drawerContent}>
        <div className={styles.listGroup}>
          <label className={styles.listGroupHeading}>
            <Typography use="body2">Carbon Hub</Typography>
          </label>
          <List className={styles.list}>
            <Link
              to="/app/carbon"
              onClick={(e) => {
                if (carbonInventories.length > 0) {
                  history.replace(`/app/carbon/inventories/dataSource/${clone[0].inventoryId}`);
                  setCollapsibleListOpen(true);
                  e.preventDefault();
                } else {
                  setCollapsibleListOpen(false);
                }
              }}>
              <SimpleListItem
                graphic={<HeatMeterIcon />}
                text="Dashboard"
                activated={window.location.pathname === '/app/carbon'}
                className={window.location.pathname === '/app/carbon' ? styles.activated : null}
              />
            </Link>

            <CollapsibleList
              open={collapsibleListOpen}
              className={`${styles.collapsibleList} ${collapsibleListOpen ? styles.activated : null}  `}
              handle={
                <div className={styles.inventoriesLink}>
                  <SimpleListItem
                    onClick={() => {
                      setCollapsibleListOpen(!collapsibleListOpen);
                    }}
                    graphic={<ProjectIcon />}
                    metaIcon={'chevron_right'}
                    text="Inventories"
                    activated={collapsibleListOpen}
                    className={collapsibleListOpen ? styles.activated : null}
                  />
                </div>
              }>
              {clone.map((x, i) => {
                const itemSelected = window.location.href.indexOf(x.inventoryId) > -1;
                const text = `${moment(x.startDate).year()}-${moment(x.startDate).year() + 1}`;
                return (
                  <Link to={`/app/carbon/inventories/dataSource/${x.inventoryId}`} key={x.inventoryId}>
                    <SimpleListItem
                      key={x.inventoryId}
                      text={text}
                      graphic={<Icon icon={{ icon: 'assignment', basename: 'material-icons-outlined' }} />}
                      metaIcon={x.published ? 'lock' : 'lock_open'}
                      className={`${styles.inventoriesSubLink} ${
                        window.location.pathname.indexOf('/app/carbon/inventories') > -1 ? styles.activated : null
                      } ${itemSelected ? styles.itemSelected : null}`}
                    />
                  </Link>
                );
              })}
              <Link to="/app/carbon/inventories/organisation/new">
                <SimpleListItem
                  graphic={'add_circle_outline'}
                  text="Add New"
                  activated={window.location.pathname === '/app/carbon/inventories/organisation/new'}
                  className={
                    window.location.pathname === '/app/carbon/inventories/organisation/new' ? styles.activated : null
                  }
                />
              </Link>
            </CollapsibleList>
          </List>
        </div>
      </div>
    </div>
  );
};

export default CarbonDrawerComponent;
