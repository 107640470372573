import React from 'react';
import { UpdateProjectDto, CreateContactDto, VirtualGroupDto, AuthorityDto, RetailerDto, useAppState } from 'utilities';

import ActivitiesDrawer from './ActivitiesDrawer';
import ActivityDrawer from './ActivityDrawer';
import ProjectDrawer from './ProjectDrawer';
import VirtualGroupDrawer from './VirtualGroupDrawer';
import AuthorityDrawer from './AuthorityDrawer';
import AutomateDrawer from './AutomateDrawer';
import AddCardDrawer from './AddCardDrawer';
import AddDashboardDrawer from './AddDashboardDrawer';

import styles from './RightDrawer.module.scss';

export const RightDrawerComponent = () => {
  const {
    recentActivitiesData,
    loadingRecentActivities,
    rightDrawer,
    user,
    manageRightDrawer,
    updateNotification,
    updateProject,
    selectAreaById,
    updateVirtualGroup,
    createVirtualGroup,
    createContact,
    getAuthority,
    getAuthorityLetters,
    updateAuthority,
    createAuthority,
    getRetailers,
    getAuthorityLoads,
    createAuthorityLetter,
    updateAuthorityLetter,
    deleteAuthorityLetter,
    checkAutomationBillAuthority,
    createCarbonAuthority,
    changeCard,
    confirmChangeCard,
    addDashboard,
    getAccountBuildings,
  } = useAppState();
  React.useEffect(() => {
    if (rightDrawer?.open) {
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.overflow = 'auto';
    }
  }, [rightDrawer]);
  return (
    <div className={`${styles.rightDrawer} ${rightDrawer.open ? styles.open : styles.closed}`}>
      {rightDrawer.type === 'activities' && (
        <ActivitiesDrawer
          manageRightDrawer={manageRightDrawer}
          loadingRecentActivities={loadingRecentActivities}
          recentActivitiesData={recentActivitiesData}
          selectAreaById={selectAreaById}
        />
      )}
      {rightDrawer.type === 'activity' && (
        <ActivityDrawer
          activityId={rightDrawer.id}
          manageRightDrawer={manageRightDrawer}
          updateNotification={updateNotification}
        />
      )}
      {rightDrawer.type === 'project' && (
        <ProjectDrawer projectId={rightDrawer.id} manageRightDrawer={manageRightDrawer} updateProject={updateProject} />
      )}
      {rightDrawer.type === 'virtualGroup' && (
        <VirtualGroupDrawer
          groupId={rightDrawer.id}
          manageRightDrawer={manageRightDrawer}
          updateVirtualGroup={updateVirtualGroup}
          createVirtualGroup={createVirtualGroup}
          createContact={createContact}
          extraParams={rightDrawer.extraParams}
        />
      )}

      {rightDrawer.type === 'authority' && (
        <AuthorityDrawer
          authorityId={rightDrawer.id}
          manageRightDrawer={manageRightDrawer}
          updateAuthority={updateAuthority}
          createAuthority={createAuthority}
          getAuthority={getAuthority}
          getAuthorityLetters={getAuthorityLetters}
          getRetailers={getRetailers}
          getAuthorityLoads={getAuthorityLoads}
          createAuthorityLetter={createAuthorityLetter}
          updateAuthorityLetter={updateAuthorityLetter}
          deleteAuthorityLetter={deleteAuthorityLetter}
          extraParams={rightDrawer.extraParams}
        />
      )}

      {rightDrawer.type === 'automate' && (
        <AutomateDrawer
          user={user}
          manageRightDrawer={manageRightDrawer}
          checkAutomationBillAuthority={checkAutomationBillAuthority}
          getRetailers={getRetailers}
          createCarbonAuthority={createCarbonAuthority}
          extraParams={rightDrawer.extraParams}
        />
      )}

      {rightDrawer.type === 'addCard' && (
        <AddCardDrawer
          subscriptionId={rightDrawer.id}
          manageRightDrawer={manageRightDrawer}
          changeCard={changeCard}
          confirmChangeCard={confirmChangeCard}
          extraParams={rightDrawer.extraParams}
        />
      )}

      {rightDrawer.type === 'addDashboard' && (
        <AddDashboardDrawer
          manageRightDrawer={manageRightDrawer}
          addDashboard={addDashboard}
          getAccountBuildings={getAccountBuildings}
          extraParams={rightDrawer.extraParams}
        />
      )}
    </div>
  );
};

export default RightDrawerComponent;
