import React from 'react';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas';
import { toast } from 'react-toastify';
import { RetailerDto, isPhone, UserDto, AuthorityLetterDto } from 'utilities';
import { Calendar } from 'react-date-range';
import { Link } from 'react-router-dom';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import { Checkbox } from '@rmwc/checkbox';

import { Icon } from '@rmwc/icon';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { isEmail } from 'utilities';
import { LoaderComponent } from 'components';
import { ReactComponent as YayImage } from 'assets/yay.svg';

import DrawerContentHeading from './DrawerContentHeading';
import styles from './AutomateDrawer.module.scss';

interface AutomateDrawerProps {
  user: UserDto;
  extraParams?: any;
  manageRightDrawer: (open: boolean, type: string, id?: string) => void;
  checkAutomationBillAuthority: () => Promise<any>;
  createCarbonAuthority: (data: AuthorityLetterDto) => Promise<any>;
  getRetailers: () => Promise<RetailerDto[]>;
}

export const AutomateDrawer = ({
  user,
  extraParams,
  manageRightDrawer,
  checkAutomationBillAuthority,
  getRetailers,
  createCarbonAuthority,
}: AutomateDrawerProps) => {
  const [step, setStep] = React.useState('start');
  const [submitting, setSubmitting] = React.useState(false);

  const [loadedAutomateData, setLoadedAutomateData] = React.useState(false);
  const [automateData, setAutomateData] = React.useState([] as any);
  const [retailers, setRetailers] = React.useState([]);

  const handleNext = () => {
    const hiddenBtn = document.getElementById('hiddenBtn') as HTMLElement;
    if (hiddenBtn) {
      hiddenBtn.click();
    } else {
      setSubmitting(true);
      setTimeout(() => {
        setSubmitting(false);
      }, 1000);
    }
  };

  const submitForm = async (values) => {
    setSubmitting(true);
    const promises = values.map((x) => {
      return createCarbonAuthority(x);
    });
    const results = await Promise.allSettled(promises);
    // @ts-ignore
    if (results.find((x) => !x.value?.letter?.letterId)) {
      toast.error(`There was a problem automating these `);
      setSubmitting(false);
    } else {
      setStep('complete');
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (!loadedAutomateData) {
      const innerFn = async () => {
        const rResults = await getRetailers();
        setRetailers(rResults);
        const aResults = await checkAutomationBillAuthority();
        setAutomateData(aResults);
        setTimeout(async () => {
          setLoadedAutomateData(true);
        }, 0);
      };

      innerFn();
    }
    // eslint-disable-next-line
  }, [loadedAutomateData]);

  return (
    <div className={`${styles.automateDrawer}`}>
      <DrawerContentHeading
        title={'Automate'}
        titleSmall={true}
        titleIcon={<Icon icon={{ icon: 'autorenew', basename: 'material-icons-outlined' }} />}
        manageRightDrawer={manageRightDrawer}
      />

      {!loadedAutomateData && (
        <div className={styles.loadingContainer}>
          <LoaderComponent absolute />
        </div>
      )}

      {loadedAutomateData && step !== 'complete' && (
        <AutomateForm user={user} automateData={automateData} retailers={retailers} submitForm={submitForm} />
      )}

      {loadedAutomateData && step === 'complete' && (
        <div className={styles.complete}>
          <div className={styles.noDataWrapper}>
            <YayImage />
            <div className={styles.noDataHeading}>
              <Typography use="body1">Well done!</Typography>
            </div>
            <div className={styles.noDataContent}>
              <Typography use="body1">This has all been setup for you.</Typography>
            </div>
          </div>
        </div>
      )}

      <div className={`${styles.drawerFooter}`}>
        {step === 'complete' && (
          <Link
            to="/app/account-settings/authorities"
            onClick={(e) => {
              e.preventDefault();
              sessionStorage.setItem('clearAccountData', 'yes');
              manageRightDrawer(false, 'automate', null);
            }}>
            Close
          </Link>
        )}

        {step !== 'complete' && (
          <Button
            label={'CANCEL'}
            outlined
            type="button"
            disabled={submitting}
            onClick={() => {
              // todo
              manageRightDrawer(false, 'automate', null);
            }}
          />
        )}

        {step !== 'complete' && (
          <Button
            label={'AUTOMATE'}
            raised
            type="button"
            icon={submitting ? <CircularProgress theme="secondary" /> : null}
            disabled={submitting}
            onClick={handleNext}
          />
        )}
      </div>
    </div>
  );
};

const AutomateForm = ({ user, automateData, retailers, submitForm }) => {
  const defaultValues = {
    jobTitle: '',
    authoriser: user.profile.particulars.displayName,
    emailAddress: user.profile.particulars.emailAddress,
    mobileNumber: user.profile.particulars.mobile || '',
    letterDate: '',
    expiresOn: '',
    theSignature: null,
    retailers: automateData.map((x) => {
      const retailer = retailers.find((y) => y.retailerId === x.retailerId);
      return {
        authorityId: x.authorityId,
        retailerId: x.retailerId,
        label: retailer.label,
        frequency: x.frequency,
        selected: true,
      };
    }),
  };

  const { errors, control, register, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const { fields } = useFieldArray({
    control,
    name: 'retailers',
  });

  const [calendarOpen, setCalendarOpen] = React.useState(false);
  const [calendarOpen2, setCalendarOpen2] = React.useState(false);

  const frequencies = [
    { value: '1', label: 'Weekly' },
    { value: '2', label: 'Monthly' },
    { value: '3', label: 'Quarterly' },
    { value: '4', label: 'Half Yearly' },
    { value: '5', label: 'Yearly' },
  ];

  const signaturePadRef = React.useRef<any>(null);

  const onSubmit = (values) => {
    if (signaturePadRef.current.isEmpty()) {
      toast.warn(`You must draw a signature before continuing`);
      return;
    }
    const selectedRetailers = values?.retailers?.filter((x) => x.selected === undefined || x.selected === true) || [];
    if (selectedRetailers.length === 0) {
      toast.warn(`You must have at least one supplier selected before continuing`);
      return;
    }
    const signature = signaturePadRef.current.toDataURL('image/png').replace('data:image/png;base64,', '');

    const payloads = selectedRetailers.map((x) => {
      return {
        ...values,
        label: x.label,
        expiresOn: moment.utc(values.expiresOn, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00'),
        letterDate: moment.utc(values.letterDate, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00'),
        signature,
        authorityId: x.authorityId,
      };
    });
    submitForm(payloads);
  };

  return (
    <div className={styles.automateContent}>
      <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
        <div className={styles.createForm}>
          <div className={styles.row}>
            <div className={styles.col}>
              <Typography use="body1">
                Great. From the invoices, you have provided. We have an automated process for the following suppliers.
              </Typography>
            </div>
          </div>
          <div className={styles.automateHeading}>
            <Typography use="headline6">Suppliers</Typography>
          </div>

          {fields.map((field, index) => {
            // const retailer = retailers.find((x) => x.retailerId === field.retailerId);
            return (
              <div className={styles.row} key={index}>
                <div className={`${styles.col} ${styles.checkboxContainer}`}>
                  <Checkbox
                    label={''}
                    defaultChecked={field?.selected}
                    {...register(`retailers[${index}].selected`)}
                    onChange={async (evt) => {
                      // @ts-ignore
                      const checked = evt.target.checked;
                      setValue(`retailers[${index}].selected`, checked);
                    }}
                  />
                  <Controller
                    key={`${field.id}.label`}
                    name={`retailers.${index}.label`}
                    control={control}
                    defaultValue={field.label}
                    as={
                      <TextField
                        name={`retailers.${index}.label`}
                        outlined
                        label="Supplier Name"
                        defaultValue={field.label}
                        readOnly
                      />
                    }
                  />

                  <div style={{ display: 'none' }}>
                    <Controller
                      key={`${field.id}.retailerId`}
                      name={`retailers.${index}.retailerId`}
                      control={control}
                      defaultValue={field.retailerId}
                      as={
                        <TextField
                          name={`retailers.${index}.retailerId`}
                          outlined
                          label="Supplier Id"
                          defaultValue={field.retailerId}
                          readOnly
                        />
                      }
                    />
                    <Controller
                      key={`${field.id}.authorityId`}
                      name={`retailers.${index}.authorityId`}
                      control={control}
                      defaultValue={field.authorityId}
                      as={
                        <TextField
                          name={`retailers.${index}.authorityId`}
                          outlined
                          label="Supplier Authority Id"
                          defaultValue={field.authorityId}
                          readOnly
                        />
                      }
                    />
                  </div>
                </div>
                <div className={styles.col}>
                  <Controller
                    key={`${field.id}.frequency`}
                    name={`retailers.${index}.frequency`}
                    control={control}
                    defaultValue={field.frequency?.toString()}
                    as={
                      <Select
                        enhanced
                        className={styles.customSelect}
                        name={`retailers.${index}.frequency`}
                        options={frequencies?.map((x) => ({ value: x.value, label: x.label }))}
                        label="Frequency"
                        outlined
                        defaultValue={field.frequency?.toString()}
                      />
                    }
                  />
                  {errors?.retailers && errors?.retailers[index] && errors?.retailers[index].frequency && (
                    <div className={styles.error}>
                      <Typography use="caption">Please select a frequency</Typography>
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          <div className={styles.automateHeading}>
            <Typography use="headline6">Your Details</Typography>
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <TextField
                outlined
                label="Job Title"
                name="jobTitle"
                required
                inputRef={register({ required: true })}
                defaultValue={defaultValues?.jobTitle}
                floatLabel
              />
              {errors?.jobTitle && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a job title</Typography>
                </div>
              )}
            </div>
            <div className={styles.col}>
              <TextField
                outlined
                label="Authoriser"
                name="authoriser"
                required
                inputRef={register({ required: true })}
                defaultValue={defaultValues?.authoriser}
                floatLabel
              />
              {errors?.authoriser && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a authoriser</Typography>
                </div>
              )}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <TextField
                outlined
                label="Email"
                name="emailAddress"
                required
                inputRef={register({
                  validate: {
                    isEmail: (value) => isEmail(value),
                  },
                })}
                defaultValue={defaultValues?.emailAddress}
                floatLabel
              />
              {errors?.emailAddress && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a email</Typography>
                </div>
              )}
            </div>

            <div className={styles.col}>
              <TextField
                outlined
                label="Mobile Number"
                name="mobileNumber"
                required
                inputRef={register({
                  validate: {
                    isPhone: (value) => isPhone(value),
                  },
                })}
                defaultValue={defaultValues?.mobileNumber}
                floatLabel
              />
              {errors?.mobileNumber && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a mobile</Typography>
                </div>
              )}
            </div>
          </div>

          <div className={styles.row}>
            <div className={`${styles.col} ${styles.datePickerWrapper} `}>
              <TextField
                outlined
                label="Letter Date"
                className={styles.dueDate}
                type="text"
                name="letterDate"
                inputRef={register()}
                // required
                floatLabel
                defaultValue={
                  defaultValues?.letterDate
                    ? moment(defaultValues?.letterDate).format('DD-MM-YYYY')
                    : moment().format('DD-MM-YYYY')
                }
                onClick={(e) => {
                  e.preventDefault();
                  setCalendarOpen(!calendarOpen);
                  return false;
                }}
              />
              <Calendar
                className={`${styles.calendar} ${styles.leftCalendar}  ${calendarOpen ? styles.calendarOpen : ''} `}
                // minDate={new Date()}
                onChange={(item) => {
                  setValue('letterDate', moment(item).format('DD-MM-YYYY'));
                  setCalendarOpen(false);
                }}
                date={
                  defaultValues?.letterDate
                    ? moment(defaultValues?.letterDate, 'DD-MM-YYYY').toDate()
                    : moment().toDate()
                }
              />
              {errors.letterDate && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid date</Typography>
                </div>
              )}
            </div>
            <div className={`${styles.col} ${styles.datePickerWrapper} `}>
              <TextField
                outlined
                label="Expires On"
                className={styles.dueDate}
                type="text"
                name="expiresOn"
                inputRef={register()}
                floatLabel
                // required
                defaultValue={
                  defaultValues?.expiresOn
                    ? moment(defaultValues?.expiresOn).format('DD-MM-YYYY')
                    : moment().format('DD-MM-YYYY')
                }
                onClick={(e) => {
                  e.preventDefault();
                  setCalendarOpen2(!calendarOpen2);
                  return false;
                }}
              />
              <Calendar
                className={`${styles.calendar}  ${calendarOpen2 ? styles.calendarOpen : ''} `}
                // minDate={new Date()}
                onChange={(item) => {
                  setValue('expiresOn', moment(item).format('DD-MM-YYYY'));
                  setCalendarOpen2(false);
                }}
                date={
                  defaultValues?.expiresOn ? moment(defaultValues?.expiresOn, 'DD-MM-YYYY').toDate() : moment().toDate()
                }
              />
              {errors.expiresOn && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid date</Typography>
                </div>
              )}
            </div>
          </div>

          <div className={styles.row}>
            <div className={`${styles.col}`}>
              <div className={styles.signatureWrapper}>
                <label>
                  <Typography use="body1">
                    Signature <sup>*</sup>
                  </Typography>
                </label>
                <SignatureCanvas
                  ref={signaturePadRef}
                  clearOnResize={false}
                  canvasProps={{ width: 512, height: 152 }}
                />

                <Button
                  label={'Clear'}
                  icon="clear"
                  outlined
                  type="button"
                  className={styles.clearButton}
                  onClick={() => {
                    // @ts-ignore
                    signaturePadRef.current.clear();
                  }}
                />
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={`${styles.col}`}>
              <Typography use="body2">
                By clicking the automate button you're authorising ESP to collect the bills automatically from above
                selected suppliers
              </Typography>
            </div>
          </div>
        </div>
        <button id="hiddenBtn" type="submit" style={{ display: 'none' }} />
      </form>
    </div>
  );
};

export default AutomateDrawer;
