import React from 'react';
import moment from 'moment';

import { Controller, useForm } from 'react-hook-form';
import { Calendar } from 'react-date-range';
import { CircularProgress } from '@rmwc/circular-progress';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import { Button } from '@rmwc/button';
import { LoaderComponent } from 'components';
import {
  useAppState,
  ProjectDetailDto,
  UserDto,
  priorityTypes,
  UpdateProjectDto,
  utilityObj,
  ContactDto,
} from 'utilities';

import DrawerContentHeading from './DrawerContentHeading';
import styles from './ProjectDrawer.module.scss';

interface ProjectDrawerProps {
  projectId: string;
  manageRightDrawer: (open: boolean, type: string, id?: string) => void;
  updateProject: (projectId: string, data: UpdateProjectDto) => Promise<boolean>;
}

const priorities = [
  { value: '0', label: 'LOW' },
  { value: '1', label: 'MEDIUM' },
  { value: '2', label: 'HIGH' },
];

const utilities = [
  { value: '1', label: 'Electricity' },
  { value: '2', label: 'Water' },
  { value: '3', label: 'Gas' },
];

const statusList = [
  {
    value: '0',
    label: '',
  },
  {
    value: '1',
    label: 'New',
  },
  {
    value: '2',
    label: 'In Progress',
  },
  {
    value: '30',
    label: 'Completed',
  },
];

export const getAssignees = (projectDetails?: ProjectDetailDto, contacts?: ContactDto[], user?: UserDto) => {
  const output = [];
  if (user && projectDetails?.assignee?.contactId === user.profile.id) {
    output.push({ label: user.profile.particulars.displayName, value: user.profile.id });
  } else {
    contacts?.forEach((x) => {
      output.push({ label: x.displayName, value: x.contactId });
    });
  }
  return output;
};

export const ProjectDrawer = ({ projectId, manageRightDrawer, updateProject }: ProjectDrawerProps) => {
  const {
    loadProjectDetails,
    getProfileContacts,
    getAccountBuildings,
    loadingProjectDetails,
    projectDetails,
    getAreasByType,
  } = useAppState();
  // const [formLoading, setFormLoading] = React.useState(false);
  // const { loads } = getAreasByType();
  // loads.sort((a, b) => (a.name > b.name ? 1 : -1));

  const [submitting, setSubmitting] = React.useState(false);
  const isCreate = !projectId;

  let [contacts, setContacts] = React.useState([]);
  let [buildings, setBuildings] = React.useState([]);

  React.useEffect(() => {
    const loadContacts = async () => {
      const result = await getProfileContacts();
      setContacts(result);
    };
    const loadBuildings = async () => {
      const result = await getAccountBuildings();
      setBuildings(result);
    };
    if (!projectId) {
      loadContacts();
    } else {
      setContacts(projectDetails?.contacts);
    }
    loadBuildings();
    // eslint-disable-next-line
  }, [loadingProjectDetails]);
  React.useEffect(() => {
    if (!isCreate) {
      loadProjectDetails(projectId);
    }
    // eslint-disable-next-line
  }, [projectId]);

  return (
    <div id="ProjectDrawer" className={styles.projectDrawer} key={projectId || 'new-project'}>
      <DrawerContentHeading
        title={isCreate ? 'Create Project' : 'Project Details'}
        titleSmall={true}
        manageRightDrawer={manageRightDrawer}
      />

      <div className={`${styles.projectDetailsWrapper} projectDetailsWrapper`}>
        <>
          {loadingProjectDetails && !isCreate && <LoaderComponent absolute />}
          {!loadingProjectDetails && projectDetails && projectDetails.projectId && !isCreate ? (
            <div className={styles.projectDetailsContent}>
              <div className={styles.projectContentWrapper}>
                <ProjectForm
                  projectDetails={projectDetails}
                  contacts={projectDetails.contacts}
                  buildings={buildings}
                  updateProject={updateProject}
                  setSubmitting={setSubmitting}
                />
              </div>
            </div>
          ) : null}

          {isCreate && contacts.length > 0 ? (
            <div className={styles.projectDetailsContent}>
              <div className={styles.projectContentWrapper}>
                <ProjectForm
                  projectDetails={null}
                  contacts={contacts}
                  buildings={buildings}
                  updateProject={updateProject}
                  setSubmitting={setSubmitting}
                />
              </div>
            </div>
          ) : null}
        </>
      </div>
      <div className={`${styles.drawerFooter} ${styles.large}`}>
        <Button
          label={'CANCEL'}
          outlined
          type="button"
          onClick={() => {
            manageRightDrawer(false, 'projects', null);
          }}
        />

        <Button
          label={'Save'}
          raised
          type="button"
          icon={submitting ? <CircularProgress theme="secondary" /> : null}
          disabled={submitting}
          onClick={() => {
            document.getElementById('hiddenBtn').click();
          }}
        />
      </div>
    </div>
  );
};

export const ProjectForm = ({ projectDetails, contacts, buildings, updateProject, setSubmitting }) => {
  const [startCalendarOpen, setStartCalendarOpen] = React.useState(false);
  const [endCalendarOpen, setEndCalendarOpen] = React.useState(false);
  const [notifiedCalendarOpen, setNotifiedCalendarOpen] = React.useState(false);

  const { errors, control, register, handleSubmit, setValue, watch } = useForm({
    mode: 'onChange',
  });
  const { building, utilityType } = watch(['building', 'utilityType']);

  const assignees = getAssignees(projectDetails, contacts);
  let defaultAssignee = assignees.find((x) => x.value === projectDetails?.assignee?.contactId);
  if (!defaultAssignee) {
    defaultAssignee = assignees[0];
  }
  const defaultPriority = priorities.find((x) => Number(x.value) === Number(projectDetails?.priority));
  const defaultStatus = statusList.find((x) => Number(x.value) === Number(projectDetails?.status));

  let defStartDate = projectDetails?.startDate ? moment.utc(projectDetails.startDate) : null;
  let defImplementationDate = projectDetails?.implementationDate ? moment.utc(projectDetails.implementationDate) : null;
  let defNotifiedOn = projectDetails?.notifiedOn ? moment.utc(projectDetails.notifiedOn) : null;
  if (defImplementationDate?.isValid() && defImplementationDate?.get('year') >= 1900) {
    if (!defStartDate) {
      defStartDate = moment.utc(defImplementationDate).subtract(1, 'day');
    }
  }

  const loads = buildings?.filter((x) => x.id === building).flatMap((x) => x.nodes) || [];
  loads.sort((a, b) => (a.label > b.label ? 1 : -1));
  const loadArea = loads.find((x) => x.id === projectDetails?.loadId);
  const selectedUtility = utilities.find((x) => x.label.toLowerCase() === utilityObj[loadArea?.utility]?.toLowerCase());

  const defaultValues = {
    label: projectDetails?.label,
    building: {
      value: projectDetails?.buildingId,
      label: projectDetails?.building,
    },
    load: {
      value: projectDetails?.loadId,
      label: projectDetails?.load,
    },
    utilityType: {
      // todo
      value: selectedUtility?.value,
      label: selectedUtility?.label,
    },
    startDate: defStartDate ? defStartDate.format('DD-MM-YYYY') : '',
    implementationDate: defImplementationDate ? defImplementationDate.format('DD-MM-YYYY') : '',
    notifiedOn: defNotifiedOn ? defNotifiedOn.format('DD-MM-YYYY') : '',
    annualKwhSavings: projectDetails?.annualKwhSavings,
    annualSavings: projectDetails?.annualSavings,
    cost: projectDetails?.cost,
    priority: {
      value: projectDetails?.priority.toString(),
      label: priorityTypes[projectDetails?.priority],
    },
    assignee: defaultAssignee,
    comments: projectDetails?.comments,
    description: projectDetails?.description,
    status: projectDetails?.status || defaultStatus,
  };

  const onSubmit = async (data) => {
    setSubmitting(true);
    const variables = {
      ...data,
      siteId: buildings.find((x) => x.id === data.building).parentId,
      buildingId: data.building,
      loadId: data.load,
      priority: Number(data.priority),
      utilityType: Number(data.utilityType),
      annualSavings: Number(data.annualSavings),
      annualKwhSavings: Number(data.annualKwhSavings),
      cost: Number(data.cost),
      startDate: data.startDate ? moment.utc(data.startDate, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00') : null,
      implementationDate: data.implementationDate
        ? moment.utc(data.implementationDate, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00')
        : null,
      notifiedOn: data.notifiedOn
        ? moment.utc(data.notifiedOn, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00')
        : moment.utc().format('YYYY-MM-DDT00:00:00'),
      annualAdditionalSavings: 0,
      costThusFar: 0,
      cumulativeKwhSavings: 0,
      unitCost: 0,
      status: Number(data.status),
      assignee: { contactId: data.assignee },
      reporter: { contactId: data.assignee },
    };

    delete variables.building;
    delete variables.load;
    delete variables.assigneeContactId;
    delete variables.priority;
    delete variables.utilityType;
    await updateProject(projectDetails?.projectId, variables);
    setSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
      <div className={styles.projectForm}>
        <div className={styles.row}>
          <div className={styles.col}>
            <TextField
              outlined
              label="Project Title"
              name="label"
              required
              inputRef={register({ required: true })}
              defaultValue={defaultValues?.label}
              autoFocus
            />
            {errors?.label && (
              <div className={styles.error}>
                <Typography use="caption">Please enter in a project title</Typography>
              </div>
            )}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <Controller
              name="building"
              control={control}
              defaultValue={defaultValues?.building?.value}
              rules={{
                required: true,
              }}
              as={
                <Select
                  enhanced
                  className={styles.customSelect}
                  name="building"
                  options={buildings.map((x) => {
                    return { value: x.id, label: x.label, parentId: x.parentId };
                  })}
                  label="Building"
                  required
                  outlined
                  defaultValue={defaultValues?.building?.value}
                />
              }
            />

            {errors?.building && (
              <div className={styles.error}>
                <Typography use="caption">Please select a building</Typography>
              </div>
            )}
          </div>
          <div className={styles.col}>
            <Controller
              name="utilityType"
              defaultValue={defaultValues?.utilityType?.value}
              control={control}
              as={
                <Select
                  enhanced
                  name="utilityType"
                  className={`${styles.customSelect} ${styles.utilityType}`}
                  options={utilities}
                  label="Utility Type"
                  required
                  outlined
                  defaultValue={defaultValues?.utilityType?.value}
                />
              }
            />
            {errors?.utilityType && (
              <div className={styles.error}>
                <Typography use="caption">Please select a {!building ? 'building then a ' : ''} utility</Typography>
              </div>
            )}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col}>
            <Controller
              name="load"
              defaultValue={defaultValues?.load?.value}
              control={control}
              rules={{
                required: true,
              }}
              as={
                <Select
                  enhanced
                  className={styles.customSelect}
                  name="load"
                  options={loads
                    .filter((x) => {
                      if (utilityType?.toString()) {
                        return x.utility === Number(utilityType);
                      } else {
                        return true;
                      }
                    })
                    .map((x) => ({ value: x.id, label: x.label }))}
                  label="Load"
                  required
                  outlined
                  defaultValue={defaultValues?.load?.value}
                />
              }
            />

            {errors?.load && (
              <div className={styles.error}>
                <Typography use="caption">
                  Please select a {!building ? 'building then a ' : ''} {!utilityType ? 'utility type and then a ' : ''}{' '}
                  load
                </Typography>
              </div>
            )}
          </div>

          <div className={`${styles.col} ${styles.datePickerWrapper} `}>
            <TextField
              outlined
              id="txtStartDate"
              label="Start Date"
              className={styles.startDate}
              type="text"
              name="startDate"
              inputRef={register()}
              min={moment().format('DD-MM-YYYY')}
              defaultValue={defaultValues?.startDate}
              autoFocus
              onClick={(e) => {
                e.preventDefault();
                setStartCalendarOpen(!startCalendarOpen);
                return false;
              }}
            />

            <Calendar
              className={`${styles.calendar} ${startCalendarOpen ? styles.calendarOpen : ''} `}
              onChange={(item) => {
                setValue('startDate', moment(item).format('DD-MM-YYYY'));
                setStartCalendarOpen(false);
                document.getElementById('txtStartDate').focus();
              }}
              date={
                defaultValues?.startDate ? moment(defaultValues?.startDate, 'DD-MM-YYYY').toDate() : moment().toDate()
              }
            />

            {errors.startDate && (
              <div className={styles.error}>
                <Typography use="caption">Please select a valid date</Typography>
              </div>
            )}
          </div>
        </div>

        <div className={styles.row}>
          <div className={`${styles.col} ${styles.datePickerWrapper} `}>
            <TextField
              outlined
              label="End Date"
              id="txtEndDate"
              className={styles.implementationDate}
              type="text"
              name="implementationDate"
              inputRef={register()}
              defaultValue={defaultValues?.implementationDate}
              autoFocus
              onClick={(e) => {
                e.preventDefault();
                setEndCalendarOpen(!endCalendarOpen);
                return false;
              }}
            />

            <Calendar
              className={`${styles.calendar} ${endCalendarOpen ? styles.calendarOpen : ''} `}
              minDate={new Date()}
              onChange={(item) => {
                setValue('implementationDate', moment(item).format('DD-MM-YYYY'));
                setEndCalendarOpen(false);
                document.getElementById('txtEndDate').focus();
              }}
              date={
                defaultValues?.implementationDate
                  ? moment(defaultValues?.implementationDate, 'DD-MM-YYYY').toDate()
                  : moment().toDate()
              }
            />

            {errors.implementationDate && (
              <div className={styles.error}>
                <Typography use="caption">Please select a valid date</Typography>
              </div>
            )}
          </div>
          <div className={`${styles.col} ${styles.datePickerWrapper} `}>
            <TextField
              outlined
              label="Notified On"
              id="txtNotifiedOn"
              className={styles.notifiedOn}
              type="text"
              name="notifiedOn"
              inputRef={register()}
              min={moment().format('DD-MM-YYYY')}
              defaultValue={defaultValues?.notifiedOn}
              onClick={(e) => {
                e.preventDefault();
                setNotifiedCalendarOpen(!notifiedCalendarOpen);
                return false;
              }}
            />

            <Calendar
              className={`${styles.calendar} ${notifiedCalendarOpen ? styles.calendarOpen : ''} `}
              onChange={(item) => {
                setValue('notifiedOn', moment(item).format('DD-MM-YYYY'));
                setNotifiedCalendarOpen(false);
                document.getElementById('txtNotifiedOn').focus();
              }}
              date={
                defaultValues?.notifiedOn ? moment(defaultValues?.notifiedOn, 'DD-MM-YYYY').toDate() : moment().toDate()
              }
            />

            {errors.notifiedOn && (
              <div className={styles.error}>
                <Typography use="caption">Please select a valid date</Typography>
              </div>
            )}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col}>
            <TextField
              outlined
              label="Est. Annual Usage Savings"
              name="annualKwhSavings"
              defaultValue={defaultValues.annualKwhSavings}
              type="number"
              required
              inputRef={register({ required: true })}
              autoFocus
            />
            {errors?.annualKwhSavings && (
              <div className={styles.error}>
                <Typography use="caption">Please enter a valid number</Typography>
              </div>
            )}
          </div>
          <div className={styles.col}>
            <TextField
              outlined
              label="Est. Annual Cost Savings"
              name="annualSavings"
              defaultValue={defaultValues.annualSavings}
              type="number"
              required
              inputRef={register({ required: true })}
              autoFocus
            />
            {errors?.annualSavings && (
              <div className={styles.error}>
                <Typography use="caption">Please enter a valid number</Typography>
              </div>
            )}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <TextField
              outlined
              label="Implementation Costs"
              name="cost"
              defaultValue={defaultValues.cost}
              type="number"
              required
              inputRef={register({ required: true })}
              autoFocus
            />
            {errors?.cost && (
              <div className={styles.error}>
                <Typography use="caption">Please enter a valid number</Typography>
              </div>
            )}
          </div>

          <div className={styles.col}>
            <Controller
              name="priority"
              control={control}
              defaultValue={defaultPriority?.value}
              rules={{
                required: true,
              }}
              as={
                <Select
                  enhanced
                  className={styles.customSelect}
                  name="priority"
                  options={priorities}
                  label="Priority"
                  required
                  outlined
                  defaultValue={defaultPriority?.value}
                />
              }
            />
            {errors.priority && (
              <div className={styles.error}>
                <Typography use="caption">Please select an assignee</Typography>
              </div>
            )}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col}>
            <Controller
              name="status"
              control={control}
              defaultValue={defaultStatus?.value}
              rules={{
                required: true,
              }}
              as={
                <Select
                  enhanced
                  className={styles.customSelect}
                  name="status"
                  options={statusList}
                  label="Status"
                  required
                  outlined
                  defaultValue={defaultStatus?.value}
                />
              }
            />

            {errors.status && (
              <div className={styles.error}>
                <Typography use="caption">Please select an status</Typography>
              </div>
            )}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col}>
            <Controller
              name="assignee"
              control={control}
              defaultValue={defaultAssignee?.value}
              rules={{
                required: true,
              }}
              as={
                <Select
                  enhanced
                  className={styles.customSelect}
                  name="assignee"
                  options={assignees}
                  label="Assignee"
                  required
                  outlined
                  defaultValue={defaultAssignee?.value}
                />
              }
            />

            {errors.assignee && (
              <div className={styles.error}>
                <Typography use="caption">Please select an assignee</Typography>
              </div>
            )}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col}>
            <TextField
              defaultValue={defaultValues.description}
              textarea
              outlined
              rows={3}
              characterCount
              maxLength={500}
              label="Description"
              className={styles.comments}
              name="description"
              inputRef={register()}
              autoFocus
            />
          </div>
          <div className={styles.col}>
            <TextField
              defaultValue={defaultValues.comments}
              textarea
              outlined
              rows={3}
              characterCount
              maxLength={500}
              label="Comments"
              className={styles.comments}
              name="comments"
              inputRef={register()}
              autoFocus
            />
          </div>
        </div>
      </div>
      <button id="hiddenBtn" type="submit" style={{ display: 'none' }} />
    </form>
  );
};

export default ProjectDrawer;
