import React from 'react';
import { Link } from 'react-router-dom';
import { List, SimpleListItem } from '@rmwc/list';
import { Typography } from '@rmwc/typography';
import { ReactComponent as GroupIcon } from 'assets/group.svg';

import { SkeletonDrawer } from 'components';
import { contactRoleTypeRev, useAppState } from 'utilities';

import styles from './SettingsDrawer.module.scss';

export interface SettingsDrawerComponentProps {
  open?: boolean;
}

export const SettingsDrawerComponent: React.FC<SettingsDrawerComponentProps> = ({ open }) => {
  const { loadingAccountMetadata, selectedAccount } = useAppState();
  if (loadingAccountMetadata) {
    return (
      <div className={`${styles.settingsDrawer} ${!open ? styles.closed : styles.open}`}>
        <div className={styles.drawerContent}>
          <SkeletonDrawer />
        </div>
      </div>
    );
  }
  const hasAnalyticsAccess = selectedAccount?.subscriptions?.find((x) => x.type === 1);
  const hasCarbonHubAccess = selectedAccount?.subscriptions?.find((x) => x.type === 2);
  return (
    <div id="settingsDrawer" className={`${styles.settingsDrawer} ${!open ? styles.closed : styles.open} `}>
      <div className={styles.drawerContent}>
        <div className={styles.listGroup}>
          <label className={styles.listGroupHeading}>
            <Typography use="body2">Your Preferences</Typography>
          </label>
          <List className={styles.list}>
            <Link to="/app/account-settings">
              <SimpleListItem
                graphic="person_outline"
                text="Basic Info"
                activated={window.location.pathname === '/app/account-settings'}
                className={window.location.pathname === '/app/account-settings' ? styles.activated : null}
              />
            </Link>
            <Link to="/app/account-settings/security">
              <SimpleListItem
                graphic="security"
                text="Security"
                activated={window.location.pathname === '/app/account-settings/security'}
                className={window.location.pathname === '/app/account-settings/security' ? styles.activated : null}
              />
            </Link>
          </List>
        </div>

        {selectedAccount?.role === contactRoleTypeRev.Admin && (
          <>
            <div className={styles.divider} />
            <div className={styles.listGroup}>
              <label className={styles.listGroupHeading}>
                <Typography use="body2">Account Management</Typography>
              </label>

              <List className={styles.list}>
                {/* {hasCarbonHubAccess && ( */}
                <Link to="/app/account-settings/organisation">
                  <SimpleListItem
                    graphic={'account_balance'}
                    text="Organisation"
                    activated={window.location.pathname === '/app/account-settings/organisation'}
                    className={
                      window.location.pathname === '/app/account-settings/organisation' ? styles.activated : null
                    }
                  />
                </Link>
                {/* )} */}
                {hasAnalyticsAccess ? (
                  <Link to="/app/account-settings/groups">
                    <SimpleListItem
                      graphic={<GroupIcon />}
                      text="Groups"
                      activated={window.location.pathname === '/app/account-settings/groups'}
                      className={window.location.pathname === '/app/account-settings/groups' ? styles.activated : null}
                    />
                  </Link>
                ) : null}
                <Link to="/app/account-settings/authorities">
                  <SimpleListItem
                    graphic={'gavel'}
                    text="Authorities"
                    activated={window.location.pathname === '/app/account-settings/authorities'}
                    className={
                      window.location.pathname === '/app/account-settings/authorities' ? styles.activated : null
                    }
                  />
                </Link>
                {hasAnalyticsAccess ? (
                  <Link to="/app/account-settings/bills">
                    <SimpleListItem
                      graphic={'description'}
                      text="Bills"
                      activated={window.location.pathname.indexOf('/app/account-settings/bill') > -1}
                      className={
                        window.location.pathname.indexOf('/app/account-settings/bill') > -1 ? styles.activated : null
                      }
                    />
                  </Link>
                ) : null}
                {hasCarbonHubAccess ? (
                  <Link to="/app/account-settings/subscription">
                    <SimpleListItem
                      graphic={'credit_card'}
                      text="Subscription"
                      activated={window.location.pathname.indexOf('/app/account-settings/subscription') > -1}
                      className={
                        window.location.pathname.indexOf('/app/account-settings/subscription') > -1
                          ? styles.activated
                          : null
                      }
                    />
                  </Link>
                ) : null}
              </List>
            </div>

            <div className={styles.divider} />
            <div className={styles.listGroup}>
              <label className={styles.listGroupHeading}>
                <Typography use="body2">User Management</Typography>
              </label>
              <List className={styles.list}>
                <Link to="/app/account-settings/users">
                  <SimpleListItem
                    graphic={'supervisor_account'}
                    text="Users"
                    activated={window.location.pathname === '/app/account-settings/users'}
                    className={window.location.pathname === '/app/account-settings/users' ? styles.activated : null}
                  />
                </Link>
              </List>
            </div>
          </>
        )}
        <div className={styles.divider} />
        <div className={styles.listGroup}>
          <label className={styles.listGroupHeading}>
            <Typography use="body2">View Dashboards</Typography>
          </label>
          <List className={styles.list}>
            <Link to="/app/account-settings/dashboards">
              <SimpleListItem
                graphic={'devices_other'}
                text="View Dashboards"
                activated={window.location.pathname === '/app/account-settings/dashboards'}
                className={window.location.pathname === '/app/account-settings/dashboards' ? styles.activated : null}
              />
            </Link>
          </List>
        </div>
      </div>
    </div>
  );
};

export default SettingsDrawerComponent;
