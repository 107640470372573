import React from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Preview from 'react-dnd-preview';

import { Typography } from '@rmwc/typography';
import { SimpleListItem } from '@rmwc/list';
import { DrawerAppContent } from '@rmwc/drawer';
import { Portal } from '@rmwc/base';
import { Icon } from '@rmwc/icon';
import {
  RightDrawerComponent,
  DrawerComponent,
  NavbarComponent,
  LoaderComponent,
  SettingsDrawerComponent,
  CarbonDrawerComponent,
  WelcomeDialog,
} from 'components';
import { reflowCharts, useAppState } from 'utilities';

import styles from './AuthenticatedLayout.module.scss';

const generatePreview = ({ itemType, item, style }) => {
  return (
    <SimpleListItem
      text={item.name}
      graphic={item.icon}
      style={{ ...style, background: 'white', border: '1px solid grey', cursor: 'move', zIndex: 20 }}
    />
  );
};

let reflowTimer = null as any;
let resizeTimer = null as any;

export const AuthenticatedLayout: React.FC = ({ children }) => {
  const { rightDrawer, manageRightDrawer } = useAppState();

  const showSettingsDrawer = window.location.href.indexOf('account-settings') > -1;
  const showCarbonDrawer = window.location.href.indexOf('/carbon') > -1;
  const isBills = window.location.href.indexOf('account-settings/bills') > -1;

  const [open, setDrawerOpen] = React.useState(window.innerWidth > 841 && !isBills ? true : false);

  const [navOpen, setNavOpen] = React.useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(!open);
    clearTimeout(reflowTimer);
    reflowTimer = setTimeout(() => {
      reflowCharts();
    }, 500);
  };

  // Eventlistener
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      if (window.location.href.indexOf('/app/activities') === -1 && !isBills) {
        setDrawerOpen(window.innerWidth > 841);
      }

      clearTimeout(reflowTimer);
      reflowTimer = setTimeout(() => {
        reflowCharts();
      }, 500);
    }, 150);
  });

  return (
    <DndProvider backend={HTML5Backend} options={{ enabledMouseEvents: true, preview: false }}>
      {window.location.href.indexOf('account-settings/groups') === -1 && <Preview generator={generatePreview} />}

      <div className={`${styles['authenticated-layout']} ${rightDrawer.open ? styles.rightDrawerOpen : ''}`}>
        <span
          className={styles.opaqueBg}
          onClick={(e) => {
            if (rightDrawer.open) {
              manageRightDrawer(false, 'activities', null);
            }
          }}
        />
        <NavbarComponent toggleDrawer={toggleDrawer} open={navOpen} setOpen={setNavOpen} />
        <div className={styles['content']} onClick={() => setNavOpen(false)}>
          {showSettingsDrawer ? (
            <SettingsDrawerComponent open={open} />
          ) : showCarbonDrawer ? (
            <CarbonDrawerComponent open={open} />
          ) : (
            <DrawerComponent open={open} />
          )}
          <a
            href="#"
            className={`${styles.drawerToggle} ${open ? styles.open : styles.closed} drawerToggle ${
              open ? 'open' : 'closed'
            } `}
            onClick={(e) => {
              e.preventDefault();
              toggleDrawer();
            }}>
            <Icon icon={open ? 'chevron_left' : 'chevron_right'} />
          </a>
          <DrawerAppContent
            className={`${styles['drawer-content']} ${open ? styles.open : styles.closed} ${
              open ? 'drawerOpen' : 'drawerClosed'
            }`}>
            {children}
          </DrawerAppContent>
        </div>
        <RightDrawerComponent />
        <Portal />
      </div>

      <div className="overlay-loader">
        <Typography use="headline4">...exporting report please wait...</Typography>
        <LoaderComponent />
      </div>

      <WelcomeDialog />
    </DndProvider>
  );
};

export default React.memo(AuthenticatedLayout);
