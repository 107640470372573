import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { Icon } from '@rmwc/icon';

import { LoaderComponent } from 'components';

import DrawerContentHeading from './DrawerContentHeading';
import { ReactComponent as YayImage } from 'assets/yay.svg';

import styles from './AddCardDrawer.module.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

interface AddCardDrawerProps {
  subscriptionId: string;
  extraParams?: any;
  manageRightDrawer: (open: boolean, type: string, id?: string) => void;
  changeCard: (data: any) => Promise<any>;
  confirmChangeCard: (subscriptionId: string, identifier: string) => Promise<boolean>;
}

export const AddCardDrawer = ({
  subscriptionId,
  extraParams,
  manageRightDrawer,
  changeCard,
  confirmChangeCard,
}: AddCardDrawerProps) => {
  const [step, setStep] = React.useState('start');
  const [submitting, setSubmitting] = React.useState(false);
  const [clientSecret, setClientSecret] = React.useState('');

  React.useEffect(() => {
    const innerFn = async () => {
      const result = await changeCard(subscriptionId);
      if (result) {
        setClientSecret(result);
        setStep('ready');
      } else {
        toast.error(`There was a problem loading this form. Please reload the page and try again.`);
        manageRightDrawer(false, 'addCard', null);
      }
    };
    innerFn();
  }, []);

  return (
    <div className={`${styles.addCardDrawer}`}>
      <DrawerContentHeading
        title={'Change Card'}
        titleSmall={true}
        titleIcon={<Icon icon={{ icon: 'credit_card', basename: 'material-icons-outlined' }} />}
        manageRightDrawer={manageRightDrawer}
      />

      <div className={styles.addCardContent}>
        {step === 'start' && <LoaderComponent absolute />}

        {step === 'ready' && (
          <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
            <AddCardForm
              subscriptionId={subscriptionId}
              goNext={() => {
                setStep('complete');
              }}
              cancelSubmitting={() => {
                setSubmitting(false);
              }}
              confirmChangeCard={confirmChangeCard}
            />
          </Elements>
        )}

        {step === 'complete' && (
          <div className={styles.complete}>
            <div className={styles.noDataWrapper}>
              <YayImage />
              <div className={styles.noDataHeading}>
                <Typography use="body1">Well done!</Typography>
              </div>
              <div className={styles.noDataContent}>
                <Typography use="body1">That card has been saved for you.</Typography>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={`${styles.drawerFooter}`}>
        {step === 'complete' && (
          <Link
            to="/app/account-settings/subscription"
            onClick={(e) => {
              e.preventDefault();
              sessionStorage.setItem('clearAccountData', 'yes');
              manageRightDrawer(false, 'addCard', null);
            }}>
            Close
          </Link>
        )}

        {step !== 'complete' && (
          <Button
            label={'CANCEL'}
            outlined
            type="button"
            disabled={submitting}
            onClick={() => {
              // todo
              manageRightDrawer(false, 'addCard', null);
            }}
          />
        )}

        {step !== 'complete' && (
          <Button
            label={'SAVE'}
            raised
            type="button"
            icon={submitting ? <CircularProgress theme="secondary" /> : null}
            disabled={submitting}
            onClick={() => {
              setSubmitting(true);
              (document.getElementById('submitBtn') as HTMLElement).click();
            }}
          />
        )}
      </div>
    </div>
  );
};

const AddCardForm = ({ subscriptionId, goNext, cancelSubmitting, confirmChangeCard }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [formSetup, setFormSetup] = React.useState(false);

  const setupStripeForm = () => {
    try {
      const elementStyles = {
        base: {
          color: '#32325D',
          fontWeight: 500,
          fontFamily: '"Roboto", Helvetica, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#808080',
          },
          ':-webkit-autofill': {
            color: '#e39f48',
          },
        },
        invalid: {
          color: '#f44336',
          '::placeholder': {
            color: '#f44336',
          },
        },
      };
      const elementClasses = {
        focus: 'focused',
        empty: 'empty',
        invalid: 'invalid',
      };
      // @ts-ignore
      const paymentElement = elements.create('payment', {
        style: elementStyles,
        classes: elementClasses,
      });
      paymentElement.mount('#payment');
      setTimeout(() => {
        setFormSetup(true);
      }, 0);
    } catch (ex) {}
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: window.location.href + '?test',
      },
      redirect: 'if_required',
    });
    if (!error?.message) {
      await confirmChangeCard(subscriptionId, setupIntent.payment_method);
      goNext();
    } else {
      cancelSubmitting();
      toast.error(error?.message);
    }
  };

  React.useEffect(() => {
    if (elements) {
      setupStripeForm();
    }
  }, [elements]);
  return (
    <div className={`${styles.stripeForm} stripeForm`} style={{ position: 'relative' }}>
      <div style={{ opacity: formSetup ? 1 : 0 }}>
        <form id="stripeForm" onSubmit={handleSubmit}>
          <div id="payment" className="input empty"></div>
          <input type="submit" id="submitBtn" style={{ display: 'none' }} />
        </form>
      </div>

      {!formSetup && <LoaderComponent absolute />}
    </div>
  );
};

export default AddCardDrawer;
