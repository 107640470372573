import moment from 'moment';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting';
import HighchartsExportingData from 'highcharts/modules/export-data';
import HighchartsFullscreen from 'highcharts/modules/full-screen';
import IndicatorsCore from 'highcharts/indicators/indicators';
import bb from 'highcharts/indicators/bollinger-bands';

HighchartsExporting(Highcharts);
HighchartsOfflineExporting(Highcharts);
HighchartsExportingData(Highcharts);
HighchartsFullscreen(Highcharts);
IndicatorsCore(Highcharts);
bb(Highcharts);

(function (H) {
  var extend = H.extend;

  H.setOptions({
    lang: {
      thousandsSep: ',',
    },
  });
  // @ts-ignore
  H.wrap(H.Tooltip.prototype, 'move', function (proceed, x, y, anchorX, anchorY) {
    var tooltip = this,
      now = tooltip.now,
      animate =
        tooltip.options.animation !== false &&
        !tooltip.isHidden &&
        // When we get close to the target position, abort animation and
        // land on the right place (#3056)
        (Math.abs(x - now.x) > 1 || Math.abs(y - now.y) > 1),
      skipAnchor = false; //tooltip.followPointer || tooltip.len > 1;

    // Get intermediate values for animation
    extend(now, {
      x: animate ? (2 * now.x + x) / 3 : x,
      y: animate ? (now.y + y) / 2 : y,
      anchorX: skipAnchor ? undefined : animate ? (2 * now.anchorX + anchorX) / 3 : anchorX,
      anchorY: skipAnchor ? undefined : animate ? (now.anchorY + anchorY) / 2 : anchorY,
    });

    // Move to the intermediate value
    tooltip.getLabel().attr(now);

    // Run on next tick of the mouse tracker
    if (animate) {
      // Never allow two timeouts
      clearTimeout(this.tooltipTimeout);

      // Set the fixed interval ticking for the smooth tooltip
      this.tooltipTimeout = setTimeout(function () {
        // The interval function may still be running during destroy,
        // so check that the chart is really there before calling.
        if (tooltip) {
          tooltip.move(x, y, anchorX, anchorY);
        }
      }, 32);
    }
  });
  H.wrap(H.Chart.prototype, 'getDataRows', function (proceed, multiLevelHeaders) {
    var rows = proceed.call(this, multiLevelHeaders);
    let chartMinDate = Number(sessionStorage.getItem('chartMinDate'));
    let chartMaxDate = Number(sessionStorage.getItem('chartMaxDate'));
    let minDate = chartMinDate ? moment.utc(chartMinDate) : null;
    let maxDate = chartMaxDate ? moment.utc(chartMaxDate) : null;
    if (minDate && maxDate) {
      rows = rows.filter((row) => {
        if (row.x) {
          return moment(row.x).isSameOrAfter(minDate) && moment(row.x).isSameOrBefore(maxDate);
        } else {
          return true;
        }
      });
    }
    rows = rows.map((row) => {
      if (row.x) {
        row[0] = Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', row.x);
      }
      return row;
    });

    return rows;
  });
})(Highcharts);

// @ts-ignore
const getSVG = function (charts, options, callback) {
  var svgArr = [],
    top = 0,
    width = 0,
    addSVG = function (svgres) {
      // Grab width/height from exported chart
      // eslint-disable-next-line
      var svgWidth = +svgres.match(/^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/)[1],
        // eslint-disable-next-line
        svgHeight = +svgres.match(/^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/)[1],
        // Offset the position of this chart in the final SVG
        svg = svgres.replace('<svg', '<g transform="translate(0,' + top + ')" ');
      svg = svg.replace('</svg>', '</g>');
      top += svgHeight;
      width = Math.max(width, svgWidth);
      svgArr.push(svg);
    },
    exportChart = function (i) {
      if (i === charts.length) {
        return callback(
          '<svg height="' +
            top +
            '" width="' +
            width +
            '" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
            svgArr.join('') +
            '</svg>'
        );
      }
      charts[i].getSVGForLocalExport(
        options,
        {},
        function () {
          console.log('Failed to get SVG');
        },
        function (svg) {
          addSVG(svg);
          return exportChart(i + 1); // Export next only when this SVG is received
        }
      );
    };
  exportChart(0);
};

const exportToCsv = (charts, filename) => {
  const output = [];
  charts.forEach((chart, chartIndex) => {
    const rows = chart.getCSV().split('\n');
    rows.forEach((row, rowIndex) => {
      const cols = row.split(',');
      if (chartIndex === 0) {
        output.push(cols);
      } else {
        cols.splice(0, 1);
        output[rowIndex] = output[rowIndex].concat(cols);
      }
    });
  });
  let csvStr = '';
  output.forEach((row) => {
    csvStr += row.join(', ') + '\n';
  });
  var blob = new Blob([csvStr], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const exportCharts = function (options) {
  const now = moment();
  const charts = [];
  // let filename = '';
  Highcharts.charts.forEach((x) => {
    if (x) {
      // if (charts.length > 0) {
      //   filename = 'Comparing ' + x.userOptions.exporting.filename.split('|')[0];
      // } else {
      //   filename = x.userOptions.exporting.filename.replace('| ', '');
      // }
      charts.push(x);
    }
  });
  const dateStr = `${now.format('YYYYMMDD_HHmmss')}`;
  // filename = `${filename} ${dateStr}`;
  options = Highcharts.merge(Highcharts.getOptions().exporting, options);
  options.filename = `Esp Hub Export ${dateStr}`;

  if (options.type === 'text/csv') {
    exportToCsv(charts, options.filename + '.csv');
  } else {
    // Get SVG asynchronously and then download the resulting SVG
    getSVG(charts, options, function (svg) {
      Highcharts.downloadSVGLocal(svg, options, function () {
        console.log('Failed to export on client side');
      });
    });
  }
};

const reflowCharts = () => {
  Highcharts.charts.forEach((x) => {
    if (x) {
      x.reflow();
    }
  });
};
// @ts-ignore
window.Highcharts = Highcharts;
// @ts-ignore
export { Highcharts, HighchartsReact, reflowCharts, exportCharts };
