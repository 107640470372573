export type MainDto = {
  // API
  authenticated?: boolean;
  authenticating?: boolean;
  loadingAccountMetadata?: boolean;
  loadingChartData?: boolean;
  loadingTableData?: boolean;
  loadingOperations?: boolean;
  loadingOperationsChart?: boolean;
  loadingOperationsComparison?: boolean;
  loadingOperationsActivities?: boolean;
  loadingOperationsWorstPf: boolean;
  loadingActivities?: boolean;
  loadingRecentActivities?: boolean;
  loadingActivityDetails?: boolean;
  loadingProjectDetails?: boolean;
  loadingCarbonBaseline?: boolean;
  loadingCarbonInventories?: boolean;
  noChartData?: boolean;
  noTableData?: boolean;
  noOperationsData?: boolean;
  noOperationsChartData?: boolean;
  noOperationsComparisonData?: boolean;
  noOperationsActivitiesData?: boolean;
  user?: UserDto;
  // UI
  selectedAccount?: AccountDto;
  areas?: AreaDto[];
  selectedAreaType: string;
  chartStartDate?: number;
  chartEndDate?: number;
  chartUtilityFilter?: string;
  dataTableSort?: TableSort;

  chartPeriod: string;
  chartView: string;
  billsPeriod: number;

  comparingModeEnabled: boolean;
  // operations
  operationsData?: OperationsDto;
  operationsWorstPf?: OperationsWorstPfDto;

  operationsChartData?: OperationsChartDto;
  operationsComparisonData?: OperationsComparisonDto;
  operationsActivitiesData?: OperationsActivitiesDto;
  // activities
  activitiesData?: ActivitiesDto;
  // activities
  recentActivitiesData?: NotificationsBellItem[];
  // activities
  activityDetails?: NotificationsDetailsInterface;
  // projects
  projectDetails?: ProjectDetailDto;
  // carbon
  carbonInventories?: CarbonInventoryDto[];

  rightDrawer?: {
    open: boolean;
    type: string;
    id?: string;
    extraParams?: any;
  };
};

export type ChartPeriodDto = {
  startDate: string;
  endDate: string;
};

export type UserDto = {
  profile?: ProfileDto;
  // todo: implement below
  favourites?: any;
  recents?: any;
  notifications?: any;
  settings?: any;
};

export type ProfileDto = {
  id: string;
  sessionId: string;
  espUser: boolean;
  particulars: {
    salutation: string;
    firstName: string;
    lastName: string;
    displayName: string;
    avatar: string;
    emailAddress: string;
    invalidExpiry: string;
    resetExpiry: string;
    lastLoggedIn: string;
    role: number;
    status: number;
    phoneNumber: string;
    mobile: string;
  };
  accounts: AccountDto[];
};

export type AccountDto = {
  id: string;
  label: string;
  role: number;
  roleStatus: number;
  subscriptions: SubscriptionDto[];
  financialStartMonth: number;
  address?: AddressDto;
  sectorId?: string;
  yearsInBusiness?: number;
};

export type SubscriptionDto = {
  commenceOn: string;
  id: string;
  label: string;
  type: number;
};

export type AccountMetadataDto = {
  account: {
    id: string;
    type: number;
    nodes?: NodeDto[];
    financialStartMonth?: number;
    address?: AddressDto;
    sectorId?: string;
    yearsInBusiness?: number;
  };
  permittedReports?: [
    {
      type: number;
      startDate: string;
    }
  ];
};

export type NodeDto = {
  id: string;
  index?: number;
  label?: string;
  type?: number;
  // mainIncomer?: boolean;
  // city?: string;
  // utility?: string;
  // unit?: string;
  nodes?: NodeDto[];
  loads?: LoadDto[];
  parentId?: string;
};

export type LoadDto = {
  id: string;
  label?: string;
  mainIncomer?: boolean;
  type?: number;
  utility?: number;
  channels?: ChannelDto[];
};

export type ChannelDto = {
  id: string;
  interval?: string;
  label?: string;
  type?: number;
  unit?: string;
  nature?: number;
};

export type AreaDto = {
  id: string;
  name: string;
  type: string;
  mainIncomer?: boolean;
  city?: string;
  unit?: string;
  utility?: string;
  utilities: UtilityDto[];
  brushUtilities?: UtilityDto[];
  subareas: AreaDto[];
  selected?: boolean;
  favourite?: boolean;
  recent?: boolean;
  parentNames?: string[];
  parentIds?: string[];
  tableUtilities?: ComparisonDimensionsUtilitiesNewDto[];
  overlayId?: string;
  index?: number;
  interval?: string;
  // only for root object
  financialStartMonth?: number;
  address?: AddressDto;
  sectorId?: string;
  yearsInBusiness?: number;
  // channel field
  nature?: number;
};

export type UtilityDto = {
  id: string;
  name: string;
  type: string;
  label: string;
  data: TimeData[];
  // tableData?: TableData;
};

export type MeterDto = {
  name: string;
  type: string;
  data: TimeData[];
  tableData?: TableData;
};

export interface AnalyticsRequestSingleDto {
  identifiers: string[];
  start: string;
  end: string;
  interval: number;
  listNatureType: number[];
  unit: string;
  utility: number;
  type: number;
  includeBands?: boolean;
}

export type AnalyticsDto = {
  result: number;
  dimensions: DimensionsDto[];
};

export type DimensionsDto = {
  id: string;
  utilities: DimensionsUtilityDto[];
};

export type DimensionsUtilityDto = {
  type: number;
  unit: string;
  consumption: AnalyticsConsumptionData[];
};

export type AnalyticsConsumptionData = {
  event: string;
  usage: number;
  lower?: number;
  upper?: number;
  average?: number;
};

export type ComparisonDto = {
  result: number;
  dimensions: ComparisonDimensionsDto[];
};

export type ComparisonDimensionsDto = {
  id: string;
  label: string;
  utilities: ComparisonDimensionsUtilitiesDto[];
};

export type ComparisonDimensionsUtilitiesDto = {
  type: number;
  unit: string;
  usage: ComparisonDimensionsUtilitiesUsageDto;
  change: number;
  total: number;
};

export type ComparisonDimensionsUtilitiesUsageDto = {
  current: number;
  previous: number;
};

export type ComparisonNewDto = {
  result: number;
  dimensions: ComparisonDimensionsUtilitiesNewDto[];
};

export type ComparisonDimensionsUtilitiesNewDto = {
  utility: number;
  usage: ComparisonDimensionsUtilitiesUsageDto;
  change: number;
  total: number;
  id: string;
};

export type ChartDto = {
  areaId: string;
  areaName: string;
  type: string;
  name: string;
  utilityType: string;
  utilityLabel: string;
  data: any[];
  mainIncomer?: boolean;
};

export type TableData = {
  usage: number;
  change: number;
  total: number;
};

export type TimeData = {
  x: number;
  y: number;
};

export type OperationsUsageDto = {
  type: number;
  name: string;
  data: [
    { type: number; baseline: number; target: number; actual: number; unit: string },
    { type: number; baseline: number; target: number; actual: number; unit: string },
    { type: number; baseline: number; target: number; actual: number; unit: string }
  ];
};

export type OperationsCurrentCumulativeSavingsDto = {
  total: number;
  target: number;
  totalCarbon: number;
  targetCarbon: number;
  totalTargetSavings: number;
  utilities: OperationsCurrentCumulativeSavingsUtilitiesDto[];
};

export type OperationsCurrentCumulativeSavingsUtilitiesDto = {
  type: number;
  savings: number;
  usage: number;
  target: number;
  unit: string;
  carbon: number;
};

export type OperationsEfficientRatingDto = { percentage: number; usage: { value: number; unit: string } };

export type OperationsPeakOffPeakDto = {
  utilities: OperationsPeakOffPeakUtilityDto[];
};

export type OperationsPeakOffPeakUtilityDto = {
  type: number;
  peak: {
    percentage: number;
    value: number;
  };
  offPeak: {
    percentage: number;
    value: number;
  };
  total: {
    percentage: number;
    value: number;
  };
};
export type OperationsPotentialSavingsDto = {
  target: number;
  activities: [{ name: string; value: number }, { name: string; value: number }, { name: string; value: number }];
};

export type OperationsUsageVsTargetDto = {
  utilities: [
    { type: number; usage: number; target: number; annualTarget: number; unit: string },
    { type: number; usage: number; target: number; annualTarget: number; unit: string },
    { type: number; usage: number; target: number; annualTarget: number; unit: string }
  ];
};

export type OperationsEstimatedSpendVsBudgetDto = {
  utilities: [
    { type: number; spend: number; target: number; annualTarget: number },
    { type: number; spend: number; target: number; annualTarget: number },
    { type: number; spend: number; target: number; annualTarget: number }
  ];
};

export type OperationsWorstPfDto = {
  loads: OperationsWorstPfInnerDto[];
};

export type OperationsWorstPfInnerDto = {
  loadId: string;
  capacity: number;
  label: string;
  maxDemand: number;
  maxDemandDate: string;
  worstPF: number;
  worstPFDate: string;
};

export type OperationsDto = {
  startDate: string;
  usage: OperationsUsageDto[];
  currentAndCumulativeSavings: {
    current: OperationsCurrentCumulativeSavingsDto;
    cumulative: OperationsCurrentCumulativeSavingsDto;
  };
  efficientRating: OperationsEfficientRatingDto;
  peakOffPeak: OperationsPeakOffPeakDto;
  potentialSavings: OperationsPotentialSavingsDto;
  usageVsTarget: OperationsUsageVsTargetDto;
  estimatedSpendVsBudget: OperationsEstimatedSpendVsBudgetDto;
  result: number;
};

export type OperationsChartDto = {
  result: number;
  utilities: OperationChartDimensionUtilityDto[];
};

export type OperationChartDimensionUtilityDto = {
  type: number;
  unit: string;
  utility: number;
  data: OperationChartDimensionUtilityConsumptionDto[];
};

export type OperationChartDimensionUtilityConsumptionDto = {
  event: string;
  usage: number;
};

export type OperationsComparisonDto = {
  result: number;
  areas: OperationsComparisonAreaDto[];
};

export type OperationsComparisonAreaDto = {
  id: string;
  label: string;
  utilities: OperationsComparisonUtilityDto[];
};

export type OperationsComparisonUtilityDto = {
  type: number;
  current: {
    usage: number;
    cost: number;
  };
  previous: {
    usage: number;
    cost: number;
  };
  change: number;
};

export type OperationsActivitiesDto = {
  result: number;
  alerts: OperationsActivityDto[];
  projects: OperationsActivityDto[];
};

export type OperationsActivityDto = {
  id: string;
  label: string;
  count: number;
  savings: number;
  usage: number;
};

//Notifications Utility APIs
export type NotificationsInterface = {
  result?: number;
  errorMessage?: string;
  count: number;
  page: number;
  size: number;
  notifications: Notification[];
};

export type NotificationDetailInterface = {
  notification: Notification;
  result: number;
  errorMessage: string;
};

export type NotificationsBellInterface = {
  data: NotificationsBellItem[];
  result: number;
  errorMessage: string;
};

//Notifications Utility APIs
export type NotificationsDetailsInterface = {
  result?: number;
  errorMessage?: string;
  notificationDetails: {
    events: NotificationEvent[];
    notification: Notification;
    contacts?: ContactDto[]; // seperate api call
  };
};

export type NotificationEvent = {
  new: boolean;
  eventId: string;
  event: string;
  consumption: number;
  score: number;
  pValue: number;
  status: number;
};

export type NotificationUsage = {
  event: string;
  value: number;
};

export type Notification = {
  new: boolean;
  notificationId: string;
  status: number;
  buildingId: string;
  buildingLabel: string;
  loadUtilityType: number;
  loadId: string;
  loadLabel: string;
  channelId: string;
  channelLabel: string;
  channelUnitOfMeasure: string;
  channelIntervalFrequency: string;
  channelKey: string;
  from: string;
  to: string;
  reviewer: string;
  reviewerLabel: string;
  assignee: string;
  assigneeLabel: string;
  label: string;
  assessment: string;
  rootCause: number;
  dueDate: string;
  createdOn: string;
  impacts: NotificationImpact[];
  logs: NotificationLog[];
  eventCount: number;
};

export type NotificationLog = {
  new: boolean;
  logId: string;
  notificationId: string;
  type: number;
  event: string;
  contactId: string;
  contactLabel: string;
  message: string;
};

export type NotificationImpact = {
  type: number;
  symbol: string;
  value: number;
};

export type NotificationsBellItem = {
  notificationId: string;
  type: number;
  new: boolean;
  event: string;
  dueDate: string;
  createdOn: string;
  label: string;
  buildingId: string;
  buildingLabel: string;
  loadId: string;
  loadLabel: string;
  assigneeId: string;
  assigneeLabel: string;
  reviewerId: string;
  reviewerLabel: string;
  loadUtilityType: number;
};

export type ProjectDetailDto = {
  result: number;
  projectId: string;

  label: string;
  description: string;
  comments: string;
  status: number;

  site: string;
  siteId: string;
  buildingId: string;
  building: string;
  loadId: string;
  load: string;
  utilityType: string;
  priority: number;

  annualAdditionalSavings: number;
  annualKwhSavings: number;
  annualSavings: number;
  cost: number;
  costThusFar: number;
  unitCost: number;
  cumulativeKwhSavings: number;

  notifiedOn: string;
  startDate: string;
  implementationDate: string;

  createdOn: string;
  updatedOn: string;

  assignee?: {
    contactId: string;
    displayName: string;
  };
  reporter?: {
    contactId: string;
    displayName: string;
  };

  // custom addition
  contacts?: ContactDto[];
};

export type CommentsDto = {
  commentId: string;
  contactId: string;
  name: string;
  body: string;
  status: number;
};

export type ActivitiesDto = {
  result: number;
  count: number;
  size: number;
  page: number;
  notifications: Notification[];
  projects: ProjectDto[];
};

export type ActivitiesParams = {
  filterByStatus: number;
  searchFor: string;
  sortBy: string;
  sortDirection: string;
  page: number;
  size: number;
  type: string;
};

export type AlertDto = {
  id: string;
  site: string;
  building: string;
  load: string;
  channel: string;

  summary: string;
  status: number;
  utilityType: number;
  priority: number;

  energy: {
    carbonImpact: number;
    costImpact: number;
    percentageChange: number;
    totalExpectedUsage: number;
    usageImpact: number;
  };
  water: {
    carbonImpact: number;
    costImpact: number;
    percentageChange: number;
    totalExpectedUsage: number;
    usageImpact: number;
  };

  action: number;

  dueDate: string;

  notificationStartDate: string;
  notificationEndDate: string;
  updatedOn: string;

  assignee: {
    contactId: string;
    displayName: string;
  };
};

export type ProjectDto = {
  id: string;

  label: string;
  description: string;
  status: number;

  site: string;
  building: string;
  load: string;
  utilityType: string;
  priority: number;

  annualAdditionalSavings: number;
  annualKwhSavings: number;
  annualSavings: number;
  cost: number;
  costThusFar: number;
  unitCost: number;
  cumulativeKwhSavings: number;

  notifiedOn: string;

  startDate: string;
  implementationDate: string;
  createdOn: string;
  updatedOn: string;
};

export interface SearchResult {
  index: number;
  jsx: any;
  area: AreaDto;
}

export interface TableSort {
  index: number;
  direction: string;
}

export interface UpdateProjectDto {
  projectId: string;

  label?: string;
  description?: string;
  comments?: string;

  buildingId: string;
  loadId: string;
  utilityType: number;
  priority: number;

  annualKwhSavings: number;
  annualSavings: number;
  cost: number;

  notifiedOn: string;
  startDate: string;
  implementationDate: string;

  assignee?: {
    contactId: string;
    displayName: string;
  };
  reporter?: {
    contactId: string;
    displayName: string;
  };
}

export interface ContactsDto {
  contacts: ContactDto[];
  result: number;
}

export interface ContactDto {
  contactId: string;
  salutation: string;
  first: string;
  last: string;
  displayName: string;
  email: string;
  mobile: string;
  phone: string;
  role?: number;
  status?: number;
  invalidExpiry?: string;
  resetExpiry?: string;
  lastLoggedIn?: string;
}

export interface UtilityUnit {
  utility: number;
  unit: string;
  id?: string;
  nature?: number;
}

export interface VirtualGroupReqDto {
  nodes: VirtualGroupDto[];
}

export interface VirtualGroupDto {
  id?: string;
  index?: number;
  label?: string;
  nodes?: VirtualGroupDto[];
  loads?: VirtualGroupLoadItem[];
  permissions?: VirtualGroupPermissionsDto[];
  restricted: boolean;
  updatedOn?: string;
  owner?: {
    label: string;
    id: string;
  };
  // UI props
  parentId?: string;
  tier?: number;
  hasPermissions?: boolean;
  partialPermissions?: boolean;
  parentNames?: string[];
}

export interface VirtualGroupLoadItem {
  loadId: string;
}

export interface VirtualGroupPermissionsDto {
  contactId: string;
}

export interface VirtualGroupLoadsReqDto {
  loads: VirtualGroupLoadsDto[];
}

export interface VirtualGroupLoadsDto {
  building?: string;
  label?: string;
  loadId?: string;
  site?: string;
  usedIn: string[];
  utilityType: number;
}

export interface CreateContactDto {
  role: number;
  emailAddress: string;
}

export interface UpsertContactDto {
  accountId?: string;
  contactId?: string;
  role: number;
  emailAddress: string;
  status?: number;
}

export interface AuthorityLoadsSearchDto {
  result: number;
  loads: AuthorityLoadSearchDto[];
}

export interface AuthorityLoadSearchDto {
  building?: string;
  identifier?: string;
  label?: string;
  loadId?: string;
  site?: string;
  utilityType?: number;
}

export interface AuthoritiesSearchDto {
  count: number;
  items: AuthorityDto[];
}

export interface AuthorityResponseDto {
  result: number;
  authority: AuthorityDto;
}

export interface AuthorityDto {
  authorityId?: string;
  accountId?: string;
  label?: string;
  retailer?: {
    retailerId?: string;
    label?: string;
  };
  enableReminders?: boolean;
  emailAddress?: string;
  status?: number;
  frequency?: number;
  commenceOn?: string;
  createdOn?: string;
  updatedOn?: string;
  loads?: AuthorityLoadDto[];
}

export interface AuthorityLoadDto {
  loadId?: string;
  building?: string;
  description?: string;
  icpNumber?: string;
  label?: string;
  mainIncomer?: boolean;
  status?: number;
  utilityType?: number;
}

export interface RetailersDto {
  items: RetailerDto[];
}
export interface RetailerDto {
  cost: number;
  label: string;
  retailerId: string;
}

export interface AuthorityLettersDto {
  items: AuthorityLetterDto[];
  result: number;
}

export interface AuthorityLetterDto {
  label?: string;
  letterDate?: string;
  expiresOn?: string;
  authoriser?: string;
  jobTitle?: string;
  emailAddress?: string;
  mobileNumber?: string;
  signature?: string;
  // from response
  letterId?: string;
  authorityId?: string;
  createdOn?: string;
  letterUrl?: string;
  status?: number;
}

export interface AuthorityLetterResponseDto {
  letter: AuthorityLetterDto;
  result: number;
}

export interface BillsDto {
  result: number;
  items: BillDto[];
}

export interface BillDto {
  billId: string;
  authority: BillAuthorityDto;
  documents: DocumentDto[];
  processedOn: string;
  receivedOn: string;
  updatedOn: string;
  status: number;
}

export interface BillAuthorityDto {
  authorityId: string;
  label: string;
  retailer: {
    retailerId: string;
    label: string;
    cost: number;
  };
}

export interface DocumentDto {
  documentId: string;
  icpNumber: string;
  accountNumber: string;
  serviceDescription: string;
  type: number;
  from: string;
  to: string;
  status: number;
  averageRate: {
    value: number;
    unit: string;
  };
  totalCost: {
    value: number;
    unit: string;
  };
  totalUse: {
    value: number;
    unit: string;
  };
}

export interface BillsUtilitiesDto {
  result: number;
  items: BillsUtilityDto[];
}

export interface BillsUtilityDto {
  cost: number;
  uom: string;
  use: number;
  utilityType: number;
}

export interface BillsRetailersDto {
  result: number;
  items: BillsRetailerDto[];
}

export interface BillsRetailerDto {
  cost: number;
  label: string;
  retailerId: string;
}

export interface BillsPeriodsDto {
  result: number;
  series: BillsPeriodDto[];
}

export interface BillsPeriodDto {
  utilityType: number;
  values: BillsPeriodValuesDto[];
}

export interface BillsPeriodValuesDto {
  cost: number;
  period: string;
}

export interface BillDetailsDto {
  result: number;
  bill: BillDetailsBillDto;
}

export interface BillDetailsBillDto {
  billId: string;
  authority: BillAuthorityDto;
  document: BillDetailsDocumentDto;
  processedOn: string;
  receivedOn: string;
  updatedOn: string;
  status: number;
}

export interface BillDetailsDocumentDto {
  documentId: string;
  documentUrl: string;
  invoices: InvoiceDto[];
  status: string;
  type: string;
}

export interface InvoiceDto {
  accountNumber: string;
  charges: ChargeDto[];
  currentCharges: number;
  days: number;
  dueDate: string;
  from: string;
  gst: number;
  gstNumber: string;
  icpNumber: string;
  invoiceDate: string;
  invoiceId: string;
  invoiceNumber: string;
  lastBalance: number;
  openingBalance: number;
  page: number;
  payments: number;
  serviceDescription: string;
  subTotal: number;
  to: string;
  total: number;
  discount: number;
  usages: UsageDto[];
}

export interface ChargeDto {
  utilityType: number;
  label: string;
  energy: number;
  energyUom: string;
  rate: number;
  rateUom: string;
  total: number;
  updatedOn: string;
  isLossCharge: boolean;
  type: number;
}

export interface UsageDto {
  calorificValue: number;
  currentReading: number;
  energyUsed: number;
  meter: string;
  meterFactor: number;
  previousReading: number;
  readingType: string;
  updatedOn: string;
  utilityType: number;
  volumeUsed: number;
}

export interface CreateAccountResponse {
  account: {
    id: string;
  };
  result: number;
}
export interface AddressDto {
  addressId?: string;
  accountId?: string;
  type?: number;
  name?: string;
  contactName?: string;
  line1?: string;
  line2?: string;
  line3?: string;
  composite?: string;
  postalCode?: string;
  postOfficeBox?: string;
  city?: string;
  state?: string;
  country?: string;
  shippingMethod?: string;
  freightTerms?: string;
  latitude?: number;
  longitude?: number;
  fax?: string;
  phone1?: string;
  phone2?: string;
  phone3?: string;
}

export interface SectorsDto {
  errorMessage: number;
  result: number;
  sectors: SectorDto[];
}

export interface SectorDto {
  label: string;
  sectorId: string;
}

export interface IndustriesDto {
  errorMessage: number;
  result: number;
  industries: IndustryDto[];
}

export interface IndustryDto {
  label: string;
  industryId: string;
}

export interface EmissionFactorsDto {
  errorMessage: number;
  result: number;
  emissionFactors: EmissionFactorDto[];
}

export interface EmissionFactorDto {
  label: string;
  description: string;
  url: string;
  emissionFactorId: string;
}

export interface GetCarbonInventoryDto {
  errorMessage: string;
  inventory: CarbonInventoryDto;
  inventoryData: any;
  inventoryDataCount: number;
  dataId: string;
  inventoryId: string;
  result: number;
}

export interface GetCarbonInventoriesDto {
  errorMessage: string;
  inventories: CarbonInventoryDto[];
  result: number;
}

export interface CarbonInventoryDto {
  inventoryId?: string;
  assessmentId?: string;
  accountId?: string;
  contactId?: string;
  industryId: string;
  sectorId: string;
  startDate: string;
  endDate: string;
  frequency: number;
  emissionFactorId: string;
  normalisingFactor: number;
  grossRevenue?: number;
  fte?: number;
  published: boolean;
  yearsInBusiness: number;
}

export interface CreateCarbonInventoryResponseDto {
  inventoryId: string;
  result: number;
  errorMessage: string;
}

export interface UpdateCarbonInventoryDto {
  assessmentId?: string;
  frequency?: number;
  normalisingFactor?: number;
  grossRevenue?: number;
  fte?: number;
}

export interface CreateCarbonInventoryLineDto {
  dataId?: string;
  period?: string;
  value?: number;
  unit?: string;
  sourceId?: string;
  parentId?: string;
  notes?: string;
  total: number;
  electricity?: {
    icpNumber?: string;
    serviceDescription?: string;
  };
  travel?: {
    departureCode: string;
    departureLabel: string;
    arrivalCode: string;
    arrivalLabel: string;
    pax: number;
    class: number;
    distance: number;
  };
  document?: any;
}

export interface GetCarbonInventoryByTypeDto {
  errorMessage: string;
  count: number;
  lines: CarbonInventoryLineDto[];
  inventoryDataCount: number;
  inventoryId: string;
  dataId: string;
  result: number;
}

export interface CarbonInventoryLineDto {
  dataId: string;
  notes: string;
  period: string;
  sourceId: string;
  total: number;
  unit: number;
  document: {
    documentId: string;
    fileName: string;
    isBill: boolean;
    size: number;
    url: string;
  };
  electricity: {
    icpNumber: string;
    serviceDescription: string;
  };
  travel: {
    departureCode: string;
    departureLabel: string;
    arrivalCode: string;
    arrivalLabel: string;
    pax: number;
    class: number;
    distance: number;
  };
}

export interface GetCarbonInventoryEmissionFactorSourcesDto {
  categoryId: string;
  emissionFactorId: string;
  emissionType: number;
  errorMessage: string;
  items: CarbonEmissionFactorSourceDto[];
  label: string;
  result: number;
  sourceId: string;
}

export interface CarbonEmissionFactorSourceDto {
  label: string;
  sourceId: string;
  total: string;
  unit: string;
  items: CarbonEmissionFactorSourceDto[];
}

export interface CarbonSummaryDto {
  electricity: CarbonSummaryItemDto;
  freight: CarbonSummaryItemDto;
  fuel: CarbonSummaryItemDto;
  travel: CarbonSummaryItemDto;
  waste: CarbonSummaryItemDto;
  graph: CarbonSummaryGraphDto[];
  percentageAdvancement: number;
  inventoryTarget: number;
  baselineTotal: number;
  result: number;
}

export interface CarbonSummaryItemDto {
  carbon: number;
  percentage: number;
  dataEntries: number;
  baselineCarbon: number;
}

export interface CarbonSummaryGraphDto {
  month: number;
  total: number;
  electricity: CarbonSummaryItemDto;
  freight: CarbonSummaryItemDto;
  fuel: CarbonSummaryItemDto;
  travel: CarbonSummaryItemDto;
  waste: CarbonSummaryItemDto;
}

export interface CarbonSummaryRoadmapDto {
  inventories: CarbonSummaryRoadmapInventory[];
  result: number;
}

export interface CarbonSummaryRoadmapInventory {
  electricity: CarbonSummaryItemDto;
  freight: CarbonSummaryItemDto;
  fuel: CarbonSummaryItemDto;
  travel: CarbonSummaryItemDto;
  waste: CarbonSummaryItemDto;
  year: number;
  inventoryId: number;
  published: boolean;
}

export interface CarbonBaselineDto {
  accountId: string;
  inventoryId: string;
  year: number;
  targetReduction: number;
  targetYear: number;
}

export interface CarbonSubscriptionDto {
  result: number;
  errorMessage: string;
  savedCard: {
    cardId: string;
    expiry: string;
    pan: string;
    type: number;
    updatedOn: string;
  };
  subscriptions: SubscriptionDetailDto[];
}

export interface SubscriptionDetailDto {
  balance: number;
  balanceAsAt: string;
  ceasedOn: string;
  commerceOn: string;
  nextPayment: number;
  nextPaymentDate: string;
  plan: {
    amount: number;
    description: string;
    label: string;
    planId: string;
    type: number;
  };
  status: number;
  subscriptionId: string;
}

export interface SubscriptionTransactionsDto {
  errorMessage: string;
  result: number;
  transactions: SubscriptionTransactionDto[];
}

export interface SubscriptionTransactionDto {
  amount: number;
  description: string;
  event: string;
  invoiceId: string;
  paidOn: string;
  status: number;
  subscriptionId: string;
  transactionId: string;
}

export interface GetDashboardsDto {
  result: number;
  dashboardViews: DashboardDto[];
}

export interface DashboardDto {
  dashboardId: string;
  dashboardName: string;
  buildingId: string;
  buildingLabel: string;
  url: string;
  code: string;
  dashboardStatus: number;
}

export interface DashboardDetailDto {
  backgroundColor: number[];
  backgroundImageL: string;
  backgroundImageP: string;
  backgroundOpacity: number;
  buildingId: string;
  buildingLabel: string;
  carbonColor: number[];
  code: string;
  customPages: CustomPageDto[];
  dashboardId: string;
  dashboardName: string;
  electricityColor: number;
  errorMessage: string;
  footerLeftImageL: string;
  footerLeftImageP: string;
  footerRightImageL: string;
  footerRightImageP: string;
  gasColor: number[];
  hasBackground: boolean;
  result: number;
  showCarbon: boolean;
  showElectricity: boolean;
  showGas: boolean;
  showWaste: boolean;
  showWater: boolean;
  showWeather: boolean;
  status: number;
  url: string;
  wasteColor: number[];
  waterColor: number[];
  weatherColor: number[];
}

export interface CustomPageDto {
  customPageId?: any;
  status?: number;
  numberOrder?: number;
  imageFirstL?: string;
  imageFirstP?: string;
  imageSecondL?: string;
  imageSecondP?: string;
  imageThirdP?: string;
  imageThirdL?: string;
  imageFourthP?: string;
  imageFourthL?: string;
}

export interface DashboardCreateDto {
  buildingId: string;
  dashboardLabel: string;
  isViewBoxNeeded: boolean;
  comment: string;
}

export interface AccountAdminDto {
  accountId: string;
  errorMessage: string;
  establishedOn: string;
  financialStartMonth: number;
  industryId: string;
  organisationName: string;
  result: number;
  sectorId: string;
  yearsInBusiness: number;
}

export interface SaveAccountAdminDto {
  accountId: string;
  organisationName: string;
  establishedOn: string;
  sectorId: string;
  financialStartMonth: number;
}

export const typeObj = {
  0: 'unknown',
  1: 'dashboard',
  2: 'group',
  3: 'load',
  4: 'channel',
};

export const utilityObj = {
  0: null,
  1: 'electricity',
  2: 'water',
  3: 'gas',
  4: 'production',
  5: 'heat',
  6: 'steam',
  7: 'weather',
  8: 'enviromental',
  9: 'ghgEmmisions',
  10: 'other',
};

export const utilityObjRev = {
  electricity: 1,
  water: 2,
  gas: 3,
  production: 4,
  heat: 5,
  steam: 6,
  weather: 7,
  enviromental: 8,
  ghgEmmissions: 9,
  other: 10,
};

export const typeObjRev = {
  unknown: 0,
  dashboard: 1,
  group: 2,
  load: 3,
  channel: 4,
};

export const intervalObj = {
  None: 0,
  Yearly: 1,
  Months: 2,
  Weeks: 3,
  Days: 4,
  Hours: 5,
  HalfHourly: 6,
  Minutes: 7,
};

export const intervalObjRev = {
  0: 'N/A',
  1: 'Monthly',
  2: 'Monthly',
  3: 'Weekly',
  4: 'Daily',
  5: 'Hourly',
  6: 'Half Hourly',
  7: '15 Minutes',
};

export const voltTypes = ['L1V', 'L2V', 'L3V', 'VoltsA', 'VoltsB', 'VoltsC'];

export const ampTypes = ['L1A', 'L1A Max', 'L2A', 'L2A Max', 'L3A', 'L3A Max'];

export const voltAmpTypes = ampTypes.concat(voltTypes);

export const mlQualificationStatusTypes = [
  'TO_BE_REVIEWED',
  'TO_INVESTIGATE_LATER_BY_ESP',
  'DISQUALIFIED_BY_ESP',
  'QUALIFIED_NOT_ESCALATED',
  'QUALIFIED_BY_ESP',
  'UNDER_INVESTIGATION_BY_CUSTOMER',
  'PENDING_ACTION',
  'QUALIFIED_BY_CUSTOMER',
  'DISQUALIFIED_BY_CUSTOMER',
];

export const priorityTypes = ['LOW', 'MEDIUM', 'HIGH'];

export const contactRoleType = {
  0: 'Unknown',
  1: 'User',
  2: 'Admin',
};

export const contactRoleTypeRev = {
  Unknown: 0,
  User: 1,
  Admin: 2,
};

export const contactStatus = {
  0: 'Unknown',
  1: 'Active',
  2: 'Inactive',
  3: 'Suspended',
  4: 'Invited',
};

export const contactStatusReverse = {
  Unknown: 0,
  Active: 1,
  Inactive: 2,
  Suspended: 3,
  Invited: 4,
};

export const intervalMins = [1, 15, 30, 60, 1440, 10080, 10080, 43920];

export const intervalFrequency = [
  '1 minute',
  '15 minute',
  '30 minute',
  '60 minute',
  'Daily',
  'Weekly Starting Monday',
  'Weekly Starting Sunday',
  'Monthly',
];

export const intervalFrequencyToType = {
  '1 minute': intervalObj.Minutes,
  '15 minute': intervalObj.Minutes,
  '30 minute': intervalObj.HalfHourly,
  '60 minute': intervalObj.Hours,
  Daily: intervalObj.Days,
  'Weekly Starting Monday': intervalObj.Weeks,
  'Weekly Starting Sunday': intervalObj.Weeks,
  Monthly: intervalObj.Months,
};

export const AuthorityStatus = {
  0: 'Unknown',
  1: 'Active',
  2: 'Deleted',
};

export const AuthorityLetterStatus = {
  0: 'Unknown',
  1: 'Active',
  2: 'Expired',
  3: 'Deleted',
};

export const AuthorityLetterStatusRev = {
  Unknown: 0,
  Active: 1,
  Expired: 2,
  Deleted: 3,
};

export const AuthorityFrequency = {
  0: 'Unknown',
  1: 'Weekly',
  2: 'Monthly',
  3: 'Quarterly',
  4: 'HalfYearly',
  5: 'Yearly',
};

export const BillStatus = {
  0: 'Pending',
  1: 'Processed',
  2: 'Feedback',
  3: 'Errors',
  4: 'Deleted',
};

export const BillDocumentStatus = {
  0: 'Pending',
  1: 'Processed',
  2: 'Errors',
  3: 'Deleted',
};

export const DocumentType = {
  0: 'Unknown',
  1: 'Pdf',
  2: 'Csv',
  3: 'Excel',
};

export const emissionTypeObjRev = {
  Unknown: 0,
  Electricity: 1,
  Fuel: 2,
  Travel: 3,
  Waste: 4,
  Freight: 5,
};

export const AssessmentTravelClassRev = {
  Unknown: 0,
  Economy: 1,
  Business: 2,
  Private: 3,
};

export const AssessmentTravelClass = {
  0: 'Unknown',
  1: 'Economy',
  2: 'Business',
  3: 'Private',
};
