import React from 'react';
import axios, { CancelTokenSource } from 'axios';
import LogRocket from 'logrocket';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import {
  OperationsDto,
  AccountMetadataDto,
  AreaDto,
  ChartDto,
  MainDto,
  ProfileDto,
  UserDto,
  NodeDto,
  UtilityDto,
  AccountDto,
  AnalyticsDto,
  DimensionsUtilityDto,
  ComparisonDto,
  typeObjRev,
  intervalObj,
  utilityObj,
  voltAmpTypes,
  intervalObjRev,
  typeObj,
  voltTypes,
  ampTypes,
  OperationsChartDto,
  OperationsComparisonDto,
  ActivitiesDto,
  OperationsActivitiesDto,
  ActivitiesParams,
  ContactsDto,
  ProjectDetailDto,
  UpdateProjectDto,
  ContactDto,
  utilityObjRev,
  DimensionsDto,
  UtilityUnit,
  ComparisonDimensionsUtilitiesNewDto,
  ComparisonNewDto,
  OperationsWorstPfDto,
  VirtualGroupReqDto,
  VirtualGroupDto,
  VirtualGroupLoadsReqDto,
  AuthoritiesSearchDto,
  AuthorityDto,
  AuthorityLetterDto,
  AuthorityLetterResponseDto,
  AuthorityLettersDto,
  AuthorityResponseDto,
  BillDto,
  BillsDto,
  BillsPeriodDto,
  BillsPeriodsDto,
  BillsRetailerDto,
  BillsRetailersDto,
  BillsUtilitiesDto,
  BillsUtilityDto,
  ChannelDto,
  CreateContactDto,
  LoadDto,
  RetailerDto,
  RetailersDto,
  UpsertContactDto,
  VirtualGroupLoadsDto,
  IndustriesDto,
  SectorsDto,
  SectorDto,
  IndustryDto,
  EmissionFactorDto,
  EmissionFactorsDto,
  CarbonInventoryDto,
  CreateCarbonInventoryResponseDto,
  CreateCarbonInventoryLineDto,
  GetCarbonInventoryByTypeDto,
  CarbonInventoryLineDto,
  GetCarbonInventoriesDto,
  CarbonEmissionFactorSourceDto,
  GetCarbonInventoryEmissionFactorSourcesDto,
  CarbonSummaryDto,
  CarbonBaselineDto,
  emissionTypeObjRev,
  CarbonSummaryRoadmapDto,
  CarbonSummaryRoadmapInventory,
  CarbonSubscriptionDto,
  SubscriptionTransactionsDto,
  GetDashboardsDto,
  DashboardDto,
  DashboardDetailDto,
  DashboardCreateDto,
  AccountAdminDto,
  SaveAccountAdminDto,
  UpdateCarbonInventoryDto,
  NotificationsBellInterface,
  Notification,
  NotificationsDetailsInterface,
  AnalyticsRequestSingleDto,
  AnalyticsConsumptionData,
} from './interfaces';

import { getParameterByName, toCamel } from './utils';
import { dateRanges } from './dateRanges';
import { logout, queueRenewal } from './auth';
import {
  AddressDto,
  AuthorityLoadSearchDto,
  AuthorityLoadsSearchDto,
  BillDetailsBillDto,
  BillDetailsDto,
  getCookie,
  getHighestIntervalFromLoads,
  getLowestIntervalFromLoads,
} from 'utilities';

const API_ENV = process.env.REACT_APP_API_ENV || '';
const api = axios.create({ baseURL: `${API_ENV}/` });

let cancelTokenChart = null as CancelTokenSource;
let cancelTokenBrush = null as CancelTokenSource;
let cancelTokenTable = null as CancelTokenSource;
let cancelTokenOperations = null as CancelTokenSource;
let cancelTokenOperationsChart = null as CancelTokenSource;
let cancelTokenOperationsComparison = null as CancelTokenSource;
let cancelTokenOperationsActivities = null as CancelTokenSource;
let cancelTokenOperationsWorstPf = null as CancelTokenSource;
let cancelTokenOperationsCapVsDem = null as CancelTokenSource;
let cancelTokenRecentAlerts = null as CancelTokenSource;
let cancelTokenNotificationDetail = null as CancelTokenSource;
let cancelTokenLoadActivities = null as CancelTokenSource;
let cancelTokenGetAuthorities = null as CancelTokenSource;
let cancelTokenGetAuthority = null as CancelTokenSource;
let cancelTokenGetAuthorityLetters = null as CancelTokenSource;

let cancelTokenGetBills = null as CancelTokenSource;
let cancelTokenGetBill = null as CancelTokenSource;
let cancelTokenGetBillsByUtilities = null as CancelTokenSource;
let cancelTokenGetBillsByRetailers = null as CancelTokenSource;
let cancelTokenGetBillsByPeriod = null as CancelTokenSource;
let cancelTokenCreateAccount = null as CancelTokenSource;
let cancelTokenCreateAccountAddress = null as CancelTokenSource;
let cancelTokenCreateAccountBilling = null as CancelTokenSource;

let cancelTokenGetCarbonInventories = null as CancelTokenSource;
let cancelTokenGetCarbonDashboards = null as CancelTokenSource;
let cancelTokenCreateCarbonInventory = null as CancelTokenSource;

let cancelTokenGetCarbonSummary = null as CancelTokenSource;

interface AppProviderProps extends RouteComponentProps {
  children: any;
}

// @ts-ignore
window.moment = moment;
export const defaultState: MainDto = {
  // API
  authenticated: false,
  authenticating: true,
  loadingAccountMetadata: true,
  loadingChartData: true,
  loadingTableData: true,
  loadingOperations: true,
  loadingOperationsWorstPf: true,
  loadingOperationsChart: true,
  loadingOperationsComparison: true,
  loadingOperationsActivities: true,
  loadingActivities: true,
  loadingRecentActivities: true,
  loadingActivityDetails: true,
  loadingProjectDetails: true,
  loadingCarbonInventories: true,
  loadingCarbonBaseline: true,

  noChartData: false,
  noTableData: false,
  noOperationsData: false,
  noOperationsChartData: false,
  noOperationsComparisonData: false,
  noOperationsActivitiesData: false,
  user: null,
  // UI
  selectedAccount: null,
  areas: [],
  selectedAreaType: 'dashboard',
  chartStartDate: null,
  chartEndDate: null,
  dataTableSort: null,
  chartPeriod: 'Last 12 Months',
  chartView: 'Overlay Charts',
  chartUtilityFilter: 'all',
  comparingModeEnabled: false,
  rightDrawer: {
    type: 'activities',
    id: null,
    open: false,
  },
  operationsChartData: null,
  operationsComparisonData: null,
  operationsData: null,
  operationsActivitiesData: null,
  operationsWorstPf: null,
  projectDetails: null,
  recentActivitiesData: null,
  activitiesData: null,
  activityDetails: null,

  billsPeriod: null,

  carbonInventories: [],
};

/**
 * Methods this file provides externally
 */
export interface StateMethods {
  getState: () => MainDto;
  checkAuthentication: () => void;

  selectAccount: (account: AccountDto) => void;
  getAreaById: (id: string, areas: AreaDto[], parentId?: string) => AreaDto;
  getAccountBuildings: () => Promise<NodeDto[]>;
  getSelectedAreas: () => AreaDto[];
  unselectAreas: () => void;
  selectAreaById: (id: string, multiple?: boolean, fromPopState?: boolean, parentId?: string) => Promise<void>;
  selectArea: (area: AreaDto, onlySingle?: boolean, fromPopState?: boolean) => Promise<void>;
  favouriteArea: (area: AreaDto) => void;

  updateChartUtilityFilter: (name: string) => void;
  getFormattedChartData: (processBrushData?: boolean) => ChartDto[];

  setChartPeriod: (period: string) => void;
  setChartView: (view: string) => void;

  setComparingModeEnabled: (enabled: boolean) => void;

  getAreasByType: (
    areas?: AreaDto[]
  ) => {
    account: AreaDto;
    groups: AreaDto[];
    loads: AreaDto[];
  };

  loadSelectedBrushChartData: (start: Date, end: Date) => void;

  getIntervalFromDates: (start: Date, end: Date) => { interval: number; intervalStr: string };

  loadChartAndTableDataOntoAreas: (changeToFromLoad?: boolean, dontChangeFilter?: boolean) => void;
  loadAllOperationDashboardData: () => void;

  exportPowerFactor: () => Promise<boolean>;

  loadActivities: (params: ActivitiesParams) => Promise<ActivitiesDto>;
  loadActivityDetails: (id: string) => void;
  loadProjectDetails: (id: string) => void;

  getVirtualGroups: () => Promise<VirtualGroupDto[]>;
  getVirtualGroupsLoads: () => Promise<VirtualGroupLoadsDto[]>;
  moveVirtualGroup: (items: string[], groupName?: string, newParentId?: string) => Promise<boolean>;
  createVirtualGroup: (group: VirtualGroupDto) => Promise<VirtualGroupDto>;
  updateVirtualGroup: (group: VirtualGroupDto) => Promise<boolean>;
  deleteVirtualGroup: (group: VirtualGroupDto) => Promise<boolean>;
  reorderVirtualGroup: (group: VirtualGroupDto, index: number) => Promise<boolean>;

  getProfileContacts: () => Promise<ContactDto[]>;
  updateContact: (contactInfo: ContactDto) => Promise<boolean>;
  createContact: (contact: CreateContactDto) => Promise<boolean>;
  upsertContact: (contact: UpsertContactDto) => Promise<boolean>;
  acceptWelcomeInvite: (contactId: string, accountId: string, accept: boolean) => Promise<boolean>;

  updateNotification: (notificationId: string, data: Notification) => Promise<boolean>;
  updateProject: (projectId?: string, data?: UpdateProjectDto) => Promise<boolean>;

  getRetailers: () => Promise<RetailerDto[]>;

  getAuthorityLoads: () => Promise<AuthorityLoadSearchDto[]>;
  getAuthority: (authorityId: string) => Promise<AuthorityDto>;
  getAuthorities: (params: { page; size; query }) => Promise<AuthoritiesSearchDto>;
  getAuthorityLetters: (authorityId: string) => Promise<AuthorityLetterDto[]>;

  createAuthority: (authority: AuthorityDto) => Promise<AuthorityDto>;
  updateAuthority: (authority: AuthorityDto) => Promise<AuthorityDto>;
  deleteAuthority: (authorityId: string, label: string) => Promise<boolean>;
  createAuthorityLetter: (authorityId: string, authorityLetter: AuthorityLetterDto) => Promise<AuthorityLetterDto>;
  updateAuthorityLetter: (authorityLetter: AuthorityLetterDto) => Promise<AuthorityLetterDto>;
  deleteAuthorityLetter: (authorityLetter: AuthorityLetterDto) => Promise<boolean>;

  getBills: (identifier?: string) => Promise<BillDto[]>;
  getBill: (billId: string, docId: string) => Promise<BillDetailsBillDto>;
  getBillsByUtilities: () => Promise<BillsUtilityDto[]>;
  getBillsByRetailers: () => Promise<BillsRetailerDto[]>;
  getAllBillRetailers: () => Promise<BillsRetailerDto[]>;
  getBillsByPeriod: () => Promise<BillsPeriodDto[]>;
  setBillsPeriod: (period: number) => void;
  getBillsPeriodAndQueryStr: () => { str: string; period: number; from: string; to: string };
  uploadBill: (file: any) => Promise<any>;

  createAccount: (contactId: string, name: string) => Promise<string>;
  createAccountSubscription: (accountId: string, address: AddressDto, promoCode: string) => Promise<boolean>;
  getStripeKey: (accountId: string) => Promise<any>;

  getCarbonIndustries: () => Promise<IndustryDto[]>;
  getCarbonSectorsByIndustry: (industryId: string) => Promise<SectorDto[]>;
  getCarbonEmissionFactors: (year: number) => Promise<EmissionFactorDto[]>;
  getCarbonEmissionFactorSources: (emissionFactorId: string, type: number) => Promise<CarbonEmissionFactorSourceDto[]>;

  getCarbonInventories: (includeState?: boolean) => Promise<CarbonInventoryDto[]>;
  getCarbonBaseline: (id?: string) => Promise<any>;
  setCarbonBaseline: (data: CarbonBaselineDto) => Promise<any>;
  getCarbonInventory: (id: string) => Promise<CarbonInventoryDto>;
  createCarbonInventory: (data: CarbonInventoryDto) => Promise<any>;
  updateCarbonInventory: (data: UpdateCarbonInventoryDto) => Promise<any>;
  publishCarbonInventory: (inventoryId: string, publish: boolean) => Promise<boolean>;
  getCarbonInventoryByType: (inventoryId: string, type: number) => Promise<CarbonInventoryLineDto[]>;
  upsertCarbonInventoryByType: (
    inventoryId: string,
    type: number,
    data: CreateCarbonInventoryLineDto
  ) => Promise<string>;
  deleteCarbonInventoryByType: (inventoryId: string, dataId: string, type: number) => Promise<boolean>;

  searchAirport: (text: string) => Promise<any>;
  getCarbonSummary: (inventoryId?: string) => Promise<CarbonSummaryDto>;
  getCarbonSummaryRoadmap: (from: string, to: string) => Promise<CarbonSummaryRoadmapInventory[]>;

  getCarbonReport: (inventoryId?: string) => Promise<any>;
  checkAutomationBillAuthority: () => Promise<any>;
  createCarbonAuthority: (data: AuthorityLetterDto) => Promise<any>;
  getCarbonSubscription: () => Promise<CarbonSubscriptionDto>;
  getCarbonSubscriptionTransactions: () => Promise<any[]>;

  changeCard: (subscriptionId: string) => Promise<any>;
  downloadSubscriptionInvoice: (invoiceId: string) => Promise<string>;
  confirmChangeCard: (subscriptionId: string, identifier: string) => Promise<boolean>;
  makePayment: (id: string) => Promise<boolean>;
  cancelSubscription: (subscriptionId: string) => Promise<boolean>;
  uncancelSubscription: (subscriptionId: string) => Promise<boolean>;

  getDashboards: () => Promise<DashboardDto[]>;
  getDashboard: (dashboardId: string) => Promise<DashboardDetailDto>;
  upsertDashboard: (dashboardId: string, data: DashboardDetailDto) => Promise<DashboardDetailDto>;
  upsertCustomPage: (dashboardId: string, imageType: number, customPage: number, data: any) => Promise<any>;
  deleteCustomPage: (dashboardId: string, customPage: string, imageType?: number) => Promise<boolean>;

  addDashboard: (props: DashboardCreateDto) => Promise<boolean>;
  deleteDashboard: (dashboardId: string) => Promise<boolean>;

  getPreferences: () => Promise<any[]>;
  setPreferences: (reports: any[]) => Promise<boolean>;

  getAccountAdmin: () => Promise<AccountAdminDto>;
  saveAccountAdmin: (data: SaveAccountAdminDto) => Promise<boolean>;

  requestAnalyticsSingle: (requestParams: AnalyticsRequestSingleDto) => Promise<AnalyticsConsumptionData[]>;

  manageRightDrawer: (open: boolean, type: string, id?: string, extraParams?: any) => void;
}

// Context Setup for Global Use
export interface AppContextProps extends MainDto, StateMethods {}
export const AppContext = React.createContext<Partial<AppContextProps>>(defaultState);
export const AppConsumer = AppContext.Consumer;
export const useAppState = () => React.useContext(AppContext);

/**
 * App provider that handles all logic around data and chart/tables
 */
export class AppProvider extends React.Component<AppProviderProps, MainDto> {
  public state: MainDto = { ...defaultState };
  public resizeTimeout = null as any;
  public popStateTimeout = null as any;

  /**
   * On component loading
   */
  public async componentDidMount() {
    if (window.location.href.indexOf('verify/') === -1) {
      this.checkAuthentication();
    } else {
      this.setState({ authenticated: false, authenticating: false, user: null });
    }

    window.removeEventListener('popstate', () => {
      this.handlePopState();
    });
    window.addEventListener('popstate', () => {
      this.handlePopState();
    });
  }

  /**
   * Provies methods application state
   */
  public render() {
    const { children } = this.props;
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          getState: () => this.state,
          checkAuthentication: this.checkAuthentication,
          selectAccount: this.selectAccount,

          getAreaById: this.getAreaById,
          getAccountBuildings: this.getAccountBuildings,
          getSelectedAreas: this.getSelectedAreas,
          unselectAreas: this.unselectAreas,
          selectArea: this.selectArea,
          selectAreaById: this.selectAreaById,
          favouriteArea: this.favouriteArea,

          updateChartUtilityFilter: this.updateChartUtilityFilter,
          getFormattedChartData: this.getFormattedChartData,

          setChartPeriod: this.setChartPeriod,
          setChartView: this.setChartView,
          setComparingModeEnabled: this.setComparingModeEnabled,

          getAreasByType: this.getAreasByType,

          loadSelectedBrushChartData: this.loadSelectedBrushChartData,

          getIntervalFromDates: this.getIntervalFromDates,

          loadChartAndTableDataOntoAreas: this.loadChartAndTableDataOntoAreas,
          loadAllOperationDashboardData: this.loadAllOperationDashboardData,
          exportPowerFactor: this.exportPowerFactor,

          loadActivities: this.loadActivities,
          loadActivityDetails: this.loadActivityDetails,

          updateNotification: this.updateNotification,

          loadProjectDetails: this.loadProjectDetails,
          updateProject: this.updateProject,

          getProfileContacts: this.getProfileContacts,
          updateContact: this.updateContact,
          createContact: this.createContact,
          upsertContact: this.upsertContact,
          acceptWelcomeInvite: this.acceptWelcomeInvite,

          getVirtualGroups: this.getVirtualGroups,
          getVirtualGroupsLoads: this.getVirtualGroupsLoads,
          createVirtualGroup: this.createVirtualGroup,
          moveVirtualGroup: this.moveVirtualGroup,
          updateVirtualGroup: this.updateVirtualGroup,
          deleteVirtualGroup: this.deleteVirtualGroup,
          reorderVirtualGroup: this.reorderVirtualGroup,

          getRetailers: this.getRetailers,
          getAuthorityLoads: this.getAuthorityLoads,
          getAuthority: this.getAuthority,
          getAuthorities: this.getAuthorities,
          getAuthorityLetters: this.getAuthorityLetters,

          updateAuthority: this.updateAuthority,
          createAuthority: this.createAuthority,
          deleteAuthority: this.deleteAuthority,
          createAuthorityLetter: this.createAuthorityLetter,
          updateAuthorityLetter: this.updateAuthorityLetter,
          deleteAuthorityLetter: this.deleteAuthorityLetter,

          getBills: this.getBills,
          getBill: this.getBill,
          getBillsByUtilities: this.getBillsByUtilities,
          getBillsByRetailers: this.getBillsByRetailers,
          getAllBillRetailers: this.getAllBillRetailers,

          getBillsByPeriod: this.getBillsByPeriod,
          setBillsPeriod: this.setBillsPeriod,
          getBillsPeriodAndQueryStr: this.getBillsPeriodAndQueryStr,
          uploadBill: this.uploadBill,

          createAccount: this.createAccount,
          createAccountSubscription: this.createAccountSubscription,
          getStripeKey: this.getStripeKey,

          getCarbonIndustries: this.getCarbonIndustries,
          getCarbonBaseline: this.getCarbonBaseline,
          setCarbonBaseline: this.setCarbonBaseline,
          getCarbonSectorsByIndustry: this.getCarbonSectorsByIndustry,
          getCarbonEmissionFactors: this.getCarbonEmissionFactors,
          getCarbonEmissionFactorSources: this.getCarbonEmissionFactorSources,

          getCarbonInventories: this.getCarbonInventories,
          createCarbonInventory: this.createCarbonInventory,
          updateCarbonInventory: this.updateCarbonInventory,
          publishCarbonInventory: this.publishCarbonInventory,
          getCarbonInventoryByType: this.getCarbonInventoryByType,
          upsertCarbonInventoryByType: this.upsertCarbonInventoryByType,
          deleteCarbonInventoryByType: this.deleteCarbonInventoryByType,
          searchAirport: this.searchAirport,
          getCarbonSummary: this.getCarbonSummary,
          getCarbonSummaryRoadmap: this.getCarbonSummaryRoadmap,
          getCarbonReport: this.getCarbonReport,

          checkAutomationBillAuthority: this.checkAutomationBillAuthority,
          createCarbonAuthority: this.createCarbonAuthority,
          getCarbonSubscription: this.getCarbonSubscription,
          getCarbonSubscriptionTransactions: this.getCarbonSubscriptionTransactions,
          changeCard: this.changeCard,
          confirmChangeCard: this.confirmChangeCard,
          downloadSubscriptionInvoice: this.downloadSubscriptionInvoice,
          makePayment: this.makePayment,
          cancelSubscription: this.cancelSubscription,
          uncancelSubscription: this.uncancelSubscription,
          // getAutomateBillData: this.getAutomateBillData;
          // updateAutomateBillData: this.updateAutomateBillData;

          getDashboards: this.getDashboards,
          getDashboard: this.getDashboard,
          upsertDashboard: this.upsertDashboard,
          upsertCustomPage: this.upsertCustomPage,
          deleteCustomPage: this.deleteCustomPage,
          addDashboard: this.addDashboard,
          deleteDashboard: this.deleteDashboard,

          getPreferences: this.getPreferences,
          setPreferences: this.setPreferences,

          getAccountAdmin: this.getAccountAdmin,
          saveAccountAdmin: this.saveAccountAdmin,

          requestAnalyticsSingle: this.requestAnalyticsSingle,

          manageRightDrawer: this.manageRightDrawer,
        }}>
        {children}
      </AppContext.Provider>
    );
  }

  private getStorageData = () => {
    const user = { profile: null, favourites: [], recents: [], notifications: [], settings: [] } as UserDto;
    let token = localStorage.getItem('token') as string;
    let sessionId = localStorage.getItem('sessionId') as string;
    let expires = localStorage.getItem('expires') as string;
    let selectedAccount = JSON.parse(sessionStorage.getItem('selectedAccount')) as AccountDto;
    const selectedAreaType = sessionStorage.getItem('selectedAreaType') || defaultState.selectedAreaType;
    let chartPeriod = sessionStorage.getItem('chartPeriod') || defaultState.chartPeriod;
    const chartView = sessionStorage.getItem('chartView') || defaultState.chartView;
    const chartUtilityFilter = sessionStorage.getItem('chartUtilityFilter') || defaultState.chartUtilityFilter;
    let profile = JSON.parse(sessionStorage.getItem('profile')) as ProfileDto;

    if (!token && getCookie('token')) {
      token = getCookie('token');
      sessionId = getCookie('sessionId');
      expires = getCookie('expires');
      localStorage.setItem('token', token);
      localStorage.setItem('sessionId', sessionId);
      localStorage.setItem('expires', expires);
    }
    if (getParameterByName('chartMinDate')) {
      const chartMinDate = new Date(Number(getParameterByName('chartMinDate')));
      const chartMaxDate = new Date(Number(getParameterByName('chartMaxDate')));
      const startStr = moment(chartMinDate).format('DD/MM/YYYY');
      const endStr = moment(chartMaxDate).format('DD/MM/YYYY');
      chartPeriod = `Viewing: ${startStr} - ${endStr}`;
    }

    return {
      user,
      token,
      sessionId,
      expires,
      selectedAccount,
      selectedAreaType,
      chartPeriod,
      chartView,
      chartUtilityFilter,
      profile,
    };
  };

  /**
   *
   */
  private checkAuthentication = async () => {
    const storageData = this.getStorageData();
    if (window.location.href.indexOf('federated') > -1) {
      return;
    }

    if (window.location.href.indexOf('register') > -1) {
      sessionStorage.clear();
      localStorage.clear();
      this.setState({ authenticated: false, authenticating: false, user: null });
      return;
    }

    // check token and session id
    if (!storageData.token && !storageData.sessionId) {
      if (window.location.href.indexOf('register') === -1) {
        sessionStorage.clear();
        localStorage.clear();
      }

      this.setState({ authenticated: false, authenticating: false, user: null });
      return false;
    } else {
      this.setState({ authenticated: true, authenticating: false });
    }

    // load profile
    if (!storageData.user?.profile?.id) {
      const profileResult = await this.getProfile();
      if (profileResult) {
        storageData.user.profile = profileResult;
        storageData.user.profile.accounts.sort((a, b) => (a.label > b.label ? 1 : -1));
      } else {
        logout(true, true);
        return;
      }
    }
    // queue renewal now
    queueRenewal();

    // reset default state
    const newState = {
      loadingAccountMetadata: false,
      loadingChartData: true,
      loadingTableData: true,
      loadingOperations: true,
      loadingOperationsWorstPf: true,
      loadingOperationsChart: true,
      loadingOperationsComparison: true,
      loadingOperationsActivities: true,
      loadingActivities: true,
      loadingRecentActivities: true,
      selectedAreaType: storageData.selectedAreaType,
      chartPeriod: storageData.chartPeriod,
      chartView: storageData.chartView,
      chartUtilityFilter: storageData.chartUtilityFilter,
      billsPeriod: this.getBillsPeriodAndQueryStr().period,
    } as MainDto;

    let tmpSelectedAccount = storageData.selectedAccount;

    const paramId = getParameterByName('accountId');

    if (paramId) {
      const accountFound = storageData.user.profile.accounts.find((x) => x.id === paramId);
      const selectedAreaType = getParameterByName('type');
      if (accountFound) {
        tmpSelectedAccount = accountFound;
        newState.selectedAreaType = selectedAreaType;
        sessionStorage.setItem('selectedAccount', JSON.stringify(tmpSelectedAccount));
        this.refreshChartBrushMinAndMaxDate();
      }
    }
    if (!tmpSelectedAccount) {
      tmpSelectedAccount = storageData.user.profile.accounts.length > 0 ? storageData.user.profile.accounts[0] : null;
      if (tmpSelectedAccount) {
        sessionStorage.setItem('selectedAccount', JSON.stringify(tmpSelectedAccount));
        this.refreshChartBrushMinAndMaxDate();
      }
    }

    if (!tmpSelectedAccount) {
      this.setState({
        ...newState,
        user: storageData.user,
        selectedAccount: tmpSelectedAccount,
        areas: [],
        loadingAccountMetadata: false,
        loadingActivities: false,
        loadingTableData: false,
        loadingChartData: false,
        noChartData: true,
        noTableData: true,
      });
      return;
    }
    // notify log rocket
    this.notifyLogRocket(storageData.user, tmpSelectedAccount);
    // notify hot jar
    // this.notifyHotjar(storageData.user);
    const { areas, account } = await this.getAccountMetadata(tmpSelectedAccount.id, tmpSelectedAccount.label);
    this.setState({
      ...newState,
      user: storageData.user,
      selectedAccount: { ...tmpSelectedAccount, ...account },
      areas,
    });
    this.loadInitialPageData(areas);
  };

  /**
   * Get Profile using access token
   */
  private getProfile = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await api.get<ProfileDto>('profile', { headers: { Authorization: `Bearer ${token}` } });
      if (data && data.id) {
        return data;
      } else {
        throw Error();
      }
    } catch (error) {
      return null;
    }
  };

  /**
   * Get Profile using access token
   */
  private getAccountMetadata = async (accountId: string, accountLabel: string) => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await api.get<AccountMetadataDto>(`profile/${accountId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (data?.permittedReports) {
        sessionStorage.setItem('permittedReports', JSON.stringify(data.permittedReports || []));
      }
      if (data && data.account?.id) {
        const recentAreaIds = this.getRecentAreaIds();
        let areas = [] as AreaDto[];
        let dashboard = {
          id: data.account?.id,
          name: 'Dashboard',
          type: 'dashboard', // aka account
          utilities: [],
          parentNames: [],
          parentIds: [],
          subareas: [],
        } as AreaDto;
        if (data?.account?.nodes?.length > 0) {
          const areaId = getParameterByName('area') || '';
          dashboard.selected = areaId === data.account?.id;
          dashboard.subareas = this.convertNodesToAreas(
            data.account?.nodes,
            [accountLabel],
            [data.account?.id],
            recentAreaIds
          );

          const showLoadGroupOnly = (area: AreaDto) => {
            if (area.subareas.length === 1) {
              const legacySite = area.subareas[0];
              if (legacySite.subareas.length === 1) {
                return legacySite.subareas[0];
              }
            }
            return null;
          };

          const loadGroup = showLoadGroupOnly(dashboard);
          if (loadGroup) {
            dashboard = loadGroup;
            dashboard.name = 'Dashboard';
            dashboard.parentIds = [];
            dashboard.parentNames = [];
            dashboard.subareas.forEach((x) => {
              x.parentIds = [dashboard.id];
              x.parentNames = [dashboard.name];
            });
          } else if (dashboard.subareas.length === 1) {
            const firstChild = dashboard.subareas[0];
            if (firstChild.subareas.length > 0 && firstChild.subareas[0].type === 'load') {
              dashboard = firstChild;
              dashboard.name = 'Dashboard';
              dashboard.parentIds = [];
              dashboard.parentNames = [];
              dashboard.subareas.forEach((x) => {
                x.parentIds = [dashboard.id];
                x.parentNames = [dashboard.name];
              });
            }
          }
        } else {
          dashboard.selected = true;
        }
        areas.push(dashboard);

        return { areas: areas, account: data.account };
      } else {
        throw Error();
      }
    } catch (error) {
      console.log(error);
      return { areas: [], account: null };
    }
  };

  /**
   * Get Account Buildings from legacy profile APi
   */
  private getAccountBuildings = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await api.get<any>(`profile/v1/${this.state.selectedAccount.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      let buildings = [] as NodeDto[];
      if (data?.nodes?.length > 0) {
        const innerFn = (nodes: NodeDto[], parentId?: string) => {
          nodes.forEach((node) => {
            if (node.type === 4) {
              buildings.push({ ...node, parentId: parentId });
            } else {
              innerFn(node.nodes || [], node.id);
            }
          });
        };
        innerFn(data.nodes, null);
      }
      return buildings;
    } catch (error) {
      console.log(error);
      return [] as NodeDto[];
    }
  };

  private clearCachedData = () => {
    sessionStorage.removeItem('reloadAccountMetadata');
    sessionStorage.removeItem('contacts');
    sessionStorage.removeItem('virtualGroups');
    sessionStorage.removeItem('virtualGroupsLoads');
    sessionStorage.removeItem('clearAccountData');
    sessionStorage.removeItem('groupsExpandedIds');
    sessionStorage.removeItem('authorityLoads');
    sessionStorage.removeItem('cachedAuthorities');
    sessionStorage.removeItem('cachedBills');
    sessionStorage.removeItem('billsPeriod');
    sessionStorage.removeItem('dashboards');
  };
  /**
   * Select account from account picker
   * @param account
   */
  private selectAccount = async (account: AccountDto) => {
    this.clearCachedData();
    this.setState({
      areas: [],
      selectedAreaType: 'dashboard',
      selectedAccount: account,
      loadingAccountMetadata: true,
      loadingChartData: true,
      loadingTableData: true,
      loadingOperations: true,
      loadingOperationsWorstPf: true,
      loadingOperationsChart: true,
      loadingOperationsComparison: true,
      loadingOperationsActivities: true,
      loadingActivities: true,
      loadingRecentActivities: true,
      billsPeriod: this.getBillsPeriodAndQueryStr().period,
    });
    this.props.history.push(`/app`);
    const { areas } = await this.getAccountMetadata(account.id, account.label);
    this.setState({
      loadingAccountMetadata: false,
      areas: areas,
      selectedAccount: account,
      selectedAreaType: areas?.length > 0 ? areas[0].type : 'dashboard',
      chartPeriod: 'Last 12 Months',
      chartView: 'Overlay Charts',
      chartUtilityFilter: 'all',
    });
    sessionStorage.removeItem('customDateRange');
    sessionStorage.removeItem('tableSort');
    sessionStorage.removeItem('dashboards');
    sessionStorage.setItem('selectedAccount', JSON.stringify(account));
    sessionStorage.setItem('selectedAreaType', 'dashboard');
    sessionStorage.setItem('chartPeriod', 'Last 12 Months');
    sessionStorage.setItem('chartOverlay', 'Overlay Charts');
    sessionStorage.setItem('chartUtilityFilter', 'all');
    sessionStorage.setItem('usageTab', '0');
    sessionStorage.setItem('operationsChartTab', '0');
    sessionStorage.setItem('cumulativeTab', '0');
    const permittedReports = JSON.parse(sessionStorage.getItem('permittedReports')) || [];
    const opReport = permittedReports.find((x) => x.type === 1);
    if (opReport) {
      let startDate = null;
      let endDate = null;
      const opReportMonth = opReport.startDate ? moment(opReport.startDate).month() : moment().month();
      const currentMonth = moment().month();
      if (opReportMonth >= currentMonth) {
        startDate = moment().month(opReportMonth).subtract(12, 'month').startOf('month');
        endDate = moment().month(opReportMonth).subtract(1, 'month').endOf('month');
        if (endDate.isAfter(moment())) {
          endDate = moment().subtract(1, 'month').endOf('month');
        }
      } else {
        startDate = moment().month(opReportMonth).startOf('month');
        endDate = moment().subtract(1, 'month').endOf('month');
      }
      let defaultPickerState = {
        from: { month: Number(startDate.format('M')), year: Number(startDate.format('YYYY')) },
        to: { month: Number(endDate.format('M')), year: Number(endDate.format('YYYY')) },
      };
      sessionStorage.setItem('pickerDate', JSON.stringify(defaultPickerState));
    }
    this.refreshChartBrushMinAndMaxDate();
    this.loadInitialPageData(areas);
  };

  private loadInitialPageData = (areas: AreaDto[]) => {
    this.loadRecentActivities();
    const hasCarbonHubAccess = this.state.selectedAccount?.subscriptions?.find((x) => x.type === 2);

    setTimeout(async () => {
      const viewingCarbon = window.location.href.indexOf('/app/carbon') > -1;
      if (hasCarbonHubAccess && !viewingCarbon && !this.isViewingAccountSettings()) {
        await this.getCarbonInventories(true);
        this.props.history.push(`/app/carbon`);
        this.setState({
          loadingAccountMetadata: false,
        });
        return;
      } else if (this.isViewingCarbon()) {
        await this.getCarbonInventories(true);
        this.setState({
          loadingAccountMetadata: false,
        });
      } else if (areas.length === 0) {
        this.setState({
          loadingAccountMetadata: false,
          loadingActivities: false,
          loadingTableData: false,
          loadingChartData: false,
          noChartData: true,
          noTableData: true,
        });
      } else if (this.isViewingOperations()) {
        await this.loadAllOperationDashboardData();
      } else if (this.isViewingActivities()) {
        // do nothing: handled internally
        this.setState({ loadingActivities: false, selectedAreaType: 'dashboard' });
      } else if (this.isViewingAccountSettings()) {
        // do nothing
      } else {
        await this.loadChartAndTableDataOntoAreas();
      }
    }, 0);
  };

  /**
   * Load chart and table data onto areas
   */
  private loadChartAndTableDataOntoAreas = async (changeToFromLoad?: boolean, dontChangeFilter?: boolean) => {
    const { selectedAreaType, chartPeriod, chartView, chartUtilityFilter } = this.state;
    if (sessionStorage.getItem('reloadAccountMetadata')) {
      sessionStorage.removeItem('reloadAccountMetadata');
      setTimeout(() => {
        window.location.reload();
      }, 0);
      return;
    }
    let period = chartPeriod;
    let view = chartView;
    let filter = chartUtilityFilter;
    if (changeToFromLoad) {
      view = 'Overlay Charts';
      if (selectedAreaType === 'load') {
        period = 'Last 7 Days';
      } else {
        period = 'Last 12 Months';
      }
      sessionStorage.setItem('chartPeriod', period);
      sessionStorage.setItem('chartView', view);
    }

    if (!dontChangeFilter) {
      // always reset the filter on these conditions
      if (changeToFromLoad || selectedAreaType === 'load') {
        filter = this.getDefaultChartFilter();
        sessionStorage.setItem('chartUtilityFilter', filter);
      }
    }

    this.setState({
      noChartData: false,
      noTableData: false,
      noOperationsData: false,
      noOperationsChartData: false,
      noOperationsComparisonData: false,
      loadingChartData: true,
      loadingTableData: selectedAreaType !== 'load',
      chartPeriod: period,
      chartView: view,
      chartUtilityFilter: filter,
    });

    setTimeout(async () => {
      let chartMinDate = Number(sessionStorage.getItem('chartMinDate'));
      let chartMaxDate = Number(sessionStorage.getItem('chartMaxDate'));
      const start = moment(chartMinDate).toDate();
      const end = moment(chartMaxDate).toDate();
      const allPromises = [];
      const chartPromise = new Promise<boolean>(async (resolve) => {
        const promises = [this.loadChartDataOntoAreas(), this.loadSelectedBrushChartData(start, end)];
        await Promise.all(promises);
        this.setState({ loadingChartData: false });
        resolve(true);
      });
      allPromises.push(chartPromise);
      if (selectedAreaType !== 'load') {
        allPromises.push(this.loadTableData());
      }
      await Promise.all(allPromises);
    }, 0);
  };

  private loadChartDataOntoAreas = async () => {
    const token = localStorage.getItem('token');
    const selectedAreas = this.getSelectedAreas();
    try {
      //Check if there are any previous pending requests
      if (cancelTokenChart && typeof cancelTokenChart != typeof undefined) {
        cancelTokenChart.cancel('cancelled');
      }
      cancelTokenChart = axios.CancelToken.source();
      const type = typeObjRev[selectedAreas[0].type];
      const interval = intervalObj.Days;
      let start = moment().startOf('day').subtract(10, 'years');
      let end = moment().endOf('day');
      const startDate = getParameterByName('startDate');
      const endDate = getParameterByName('endDate');
      if (startDate && endDate) {
        start = moment(startDate, 'DD-MM-YYYY').startOf('day');
        end = moment(endDate, 'DD-MM-YYYY').endOf('day');
      }
      const params = {
        type: type,
        identifiers: selectedAreas.map((x) => x.id),
        interval: interval,
        start: start.format('YYYY-MM-DDTHH:mm:ss'),
        end: end.format('YYYY-MM-DDTHH:mm:ss'),
        listNatureType: [1],
      };
      if (type === typeObjRev.load) {
        params.type = typeObjRev.channel;
        params.identifiers = [];
        const newinterval = getLowestIntervalFromLoads(selectedAreas);
        if (newinterval < interval) {
          params.interval = newinterval;
        }
      }
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenChart.token,
      };

      const utilsUnits = this.getUtilitiesAndUnitsFromSelectedAreas(
        selectedAreas,
        type,
        selectedAreas.length > 1,
        []
      ) as UtilityUnit[];

      const allPromises = utilsUnits.map((utilityUnit) => {
        return new Promise<DimensionsDto[]>(async (resolve, reject) => {
          const innerParams = {
            ...params,
            unit: utilityUnit.unit,
            utility: utilityUnit.utility,
            listNatureType: [utilityUnit.nature],
          };
          if (utilityUnit.id) {
            innerParams.identifiers = [utilityUnit.id];
          }

          const { data } = await api.post<AnalyticsDto>('analytics/usage', innerParams, options);
          const updatedData = toCamel(data) as AnalyticsDto;
          if (updatedData.dimensions?.length > 0) {
            resolve(updatedData.dimensions);
          } else {
            resolve([] as DimensionsDto[]);
          }
        });
      });

      const results = await Promise.all(allPromises);
      const groupedResults = results.flatMap((result) => {
        return result.flatMap((item) => {
          return { id: item.id, utilities: item.utilities };
        });
      });

      let hasData = false;
      selectedAreas.forEach((area) => {
        let dimensions = groupedResults
          .filter((x) => {
            if (type === typeObjRev.load) {
              const channelIds = area.subareas.flatMap((x) => x.id);
              return channelIds.indexOf(x.id) > -1;
            } else {
              return x.id === area.id;
            }
          })
          .flatMap((x) => x.utilities);

        area.utilities = this.convertAreaAndDimesionUtilityToUtilities(area, dimensions);
        const data = area.utilities.flatMap((x) => x.data);
        if (data.length > 0) {
          hasData = true;
        }
      });

      if (!hasData) {
        throw Error();
      }
      this.setState({ noChartData: false });
    } catch (ex) {
      console.log(ex, selectedAreas);
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        this.setState({ noChartData: true });
      }
    }
  };

  /**
   * Load and return brush chart data
   * Doesn't manage own loading state
   */
  private loadSelectedBrushChartData = async (startDate: Date, endDate: Date) => {
    const token = localStorage.getItem('token');
    const selectedAreas = this.getSelectedAreas();
    try {
      //Check if there are any previous pending requests
      if (cancelTokenBrush && typeof cancelTokenBrush != typeof undefined) {
        cancelTokenBrush.cancel('cancelled');
      }
      cancelTokenBrush = axios.CancelToken.source();
      const type = typeObjRev[selectedAreas[0].type];
      const identifiers = selectedAreas.map((x) => x.id);
      const { interval } = this.getIntervalFromDates(startDate, endDate);
      const { start, end } = this.getRealDatesFromBrush(startDate, endDate);
      const params = {
        type,
        identifiers,
        interval,
        start,
        end,
        listNatureType: [1],
      };

      // request at channel level
      if (type === typeObjRev.load) {
        params.type = typeObjRev.channel;
        params.identifiers = [];
      }

      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenBrush.token,
      };

      const utilsUnits = this.getUtilitiesAndUnitsFromSelectedAreas(
        selectedAreas,
        type,
        selectedAreas.length > 1,
        []
      ) as UtilityUnit[];

      const allPromises = utilsUnits.map((utilityUnit) => {
        return new Promise<DimensionsDto[]>(async (resolve, reject) => {
          const innerParams = { ...params, unit: utilityUnit.unit, utility: utilityUnit.utility };
          if (utilityUnit.id) {
            innerParams.identifiers = [utilityUnit.id];
            innerParams.listNatureType = [utilityUnit.nature];
          }
          const { data } = await api.post<AnalyticsDto>('analytics/usage', { ...innerParams, ...utilityUnit }, options);
          const updatedData = toCamel(data) as AnalyticsDto;
          if (updatedData.dimensions?.length > 0) {
            resolve(updatedData.dimensions);
          } else {
            resolve([] as DimensionsDto[]);
          }
        });
      });

      const results = await Promise.all(allPromises);
      const groupedResults = results.flatMap((result) => {
        return result.flatMap((item) => {
          return { id: item.id, utilities: item.utilities };
        });
      });
      selectedAreas.forEach((area) => {
        let dimensions = groupedResults
          .filter((x) => {
            if (type === typeObjRev.load) {
              const channelIds = area.subareas.flatMap((x) => x.id);
              return channelIds.indexOf(x.id) > -1;
            } else {
              return x.id === area.id;
            }
          })
          .flatMap((x) => x.utilities);

        if (dimensions.length > 0) {
          area.brushUtilities = this.convertAreaAndDimesionUtilityToUtilities(area, dimensions);
        } else {
          area.brushUtilities = [];
        }
      });

      this.setState({ areas: this.state.areas });
    } catch (ex) {
      console.log(ex, selectedAreas);
      selectedAreas.forEach((area) => {
        area.brushUtilities = [];
      });
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
      }
    }
  };

  /**
   * Theoretically, use area id to make api call
   * @param areas
   */
  private loadTableData = async () => {
    const token = localStorage.getItem('token');
    const selectedAreas = this.getSelectedAreas();
    try {
      //Check if there are any previous pending requests
      if (cancelTokenTable && typeof cancelTokenTable != typeof undefined) {
        cancelTokenTable.cancel('cancelled');
      }
      cancelTokenTable = axios.CancelToken.source();

      const subareas = selectedAreas.flatMap((x) => x.subareas);
      const type = typeObjRev[subareas[0].type];
      let start = moment().startOf('day').subtract(1, 'month');
      let end = moment();
      const params = {
        type: type,
        identifiers: subareas.map((x) => x.id),
        start: start.format('YYYY-MM-DDTHH:mm:ss'),
        end: end.format('YYYY-MM-DDTHH:mm:ss'),
        listNatureType: [1],
      };

      if (type === typeObjRev.load) {
        params.type = typeObjRev.channel;
        params.identifiers = [];
      }

      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenTable.token,
      };

      const utilsUnits = this.getUtilitiesAndUnitsFromSelectedAreas(subareas, type, true, []) as UtilityUnit[];

      const allPromises = utilsUnits.map((utilityUnit) => {
        return new Promise<ComparisonDimensionsUtilitiesNewDto[]>(async (resolve, reject) => {
          const innerParams = {
            ...params,
            unit: utilityUnit.unit,
            utility: utilityUnit.utility,
          };
          if (utilityUnit.id) {
            innerParams.identifiers = [utilityUnit.id];
          }
          const { data } = await api.post<ComparisonDto>('analytics/usage/comparison', innerParams, options);
          const updatedData = toCamel(data) as ComparisonNewDto;
          if (updatedData.dimensions?.length > 0) {
            resolve(updatedData.dimensions);
          } else {
            resolve([] as ComparisonDimensionsUtilitiesNewDto[]);
          }
        });
      });

      const results = await Promise.all(allPromises);
      const groupedResults = results.flatMap((result) => result.flatMap((item) => item));

      // const results = await Promise.all(allPromises);
      // const groupedResults = results.flatMap((result) => {
      //   return result.flatMap((item) => {
      //     return { id: item.id, utilities: item.utilities };
      //   });
      // });

      // TODO: PUT ME BACK
      // if (groupedResults.filter((x) => x.change === 0).length === groupedResults.length) {
      //   throw new Error('no data');
      // }
      subareas.forEach((area) => {
        let dimensions = groupedResults.filter((x) => {
          if (area.type === 'load') {
            return area.subareas.find((y) => y.id === x.id);
          }
          return x.id === area.id;
        });

        area.tableUtilities = dimensions.map((x) => {
          return {
            ...x,
            type: utilityObj[x.utility],
          };
        });
      });

      this.setState({ loadingTableData: false, noTableData: false });
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        console.log(ex, selectedAreas);
        this.setState({ loadingTableData: false, noTableData: true });
      }
    }
  };

  // /**
  //  * Theoretically, use area id to make api call
  //  * @param areas
  //  */
  // private loadTableData = async () => {
  //   const token = localStorage.getItem('token');
  //   const selectedAreas = this.getSelectedAreas();
  //   try {
  //     //Check if there are any previous pending requests
  //     if (cancelTokenTable && typeof cancelTokenTable != typeof undefined) {
  //       cancelTokenTable.cancel('cancelled');
  //     }
  //     cancelTokenTable = axios.CancelToken.source();
  //     const type = typeObjRev[selectedAreas[0].type];
  //     let start = moment().startOf('day').subtract(1, 'month');
  //     let end = moment();
  //     const params = {
  //       type: type,
  //       identifiers: selectedAreas.map((x) => x.id),
  //       start: start.format(),
  //       end: end.format(),
  //     };

  //     // dirty hack where if no groups, then we are showing load list,
  //     // so show comparison at the load aka building level
  //     if (selectedAreas[0].type === 'dashboard') {
  //       const firstArea = selectedAreas[0].subareas[0];
  //       if (firstArea?.type === 'load') {
  //         params.type = typeObjRev.building;
  //         const lastId = firstArea.parentIds[firstArea.parentIds.length - 1];
  //         params.identifiers = [lastId];
  //       }
  //     }

  //     const options = {
  //       headers: { Authorization: `Bearer ${token}` },
  //       cancelToken: cancelTokenTable.token,
  //     };
  //     // make request
  //     const { data } = await api.post<ComparisonDto>('dimensions/comparison', params, options);
  //     if (data && data.dimensions.length > 0) {
  //       const subareas = selectedAreas.flatMap((x) => x.subareas);
  //       subareas.forEach((area) => {
  //         const dimension = data.dimensions.find(
  //           (dimension) => dimension.id === area.id || dimension.label === area.name
  //         );
  //         area.tableUtilities =
  //           dimension?.utilities.map((x) => {
  //             return {
  //               ...x,
  //               type: utilityObj[x.type],
  //             };
  //           }) || [];
  //       });
  //     } else {
  //       throw new Error('no data');
  //     }
  //     this.setState({ loadingTableData: false, noTableData: false });
  //   } catch (ex) {
  //     if (ex.message === 'cancelled') {
  //       // do nothing
  //     } else {
  //       console.log(ex, selectedAreas);
  //       this.setState({ loadingTableData: false, noTableData: true });
  //     }
  //   }
  // };

  /**
   * Utility method to load all operation data
   */
  private loadAllOperationDashboardData = async () => {
    await new Promise(async (resolve) => {
      await Promise.all([
        this.loadOperationsDashboard(),
        this.loadOperationsDashboardChart(),
        this.loadOperationsDashboardComparison(),
        this.loadOperationsActivities(),
        this.loadOperationalWorstPf(),
      ]);
      resolve(true);
    });
  };

  /**
   * Exporet power factor report
   */
  private exportPowerFactor = async () => {
    const token = localStorage.getItem('token');
    const selectedAreas = this.getSelectedAreas();
    try {
      const params = this.getOperationsDashboardsParams();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      // const { selectedAreaType } = this.state;
      const result = await api.post<OperationsDto>('/reports/operational/worstpf/export', params, options);
      if (result.data) {
        window.open(`data:text/csv;charset=utf-8,${result.data}`);
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
        return false;
      } else {
        console.log(ex, selectedAreas);
        return false;
      }
    }
  };

  private getOperationsDashboardsParams = () => {
    const selectedAreas = this.getSelectedAreas();
    const type = typeObjRev[selectedAreas[0].type];
    let start = moment().startOf('day').subtract(1, 'years');
    let end = moment().endOf('day');
    const pickerDate = sessionStorage.getItem('pickerDate');
    if (pickerDate) {
      const dt = JSON.parse(pickerDate);
      start = moment(`01-${dt.from.month}-${dt.from.year}`, 'DD-M-YYYY').startOf('day');
      end = moment(`01-${dt.to.month}-${dt.to.year}`, 'DD-M-YYYY').endOf('month').endOf('day');
    }

    const params = {
      type: type === typeObjRev.group ? 7 : type,
      accountId: this.state.selectedAccount.id,
      identifiers: selectedAreas.map((x) => x.id),
      start: start.format('YYYY-MM-DDTHH:mm:ss'),
      end: end.format('YYYY-MM-DDTHH:mm:ss'),
    };
    return params;
  };

  /**
   * Load operations dashboard data
   */
  private loadOperationsDashboard = async () => {
    const token = localStorage.getItem('token');
    const selectedAreas = this.getSelectedAreas();
    try {
      const { loads } = this.getAreasByType(selectedAreas);
      const hasNoMainIncomers = loads.every((x) => !x.mainIncomer);
      if (hasNoMainIncomers) {
        throw Error('No Main Incomers');
      }
      //Check if there are any previous pending requests
      if (cancelTokenOperations && typeof cancelTokenOperations != typeof undefined) {
        cancelTokenOperations.cancel('cancelled');
      }
      cancelTokenOperations = axios.CancelToken.source();

      const params = this.getOperationsDashboardsParams();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenOperations.token,
      };
      // const { selectedAreaType } = this.state;
      this.setState({
        loadingOperations: true,
        operationsData: null,
        noOperationsData: false,
      });

      const result = await api.post<OperationsDto>('/reports/operational', params, options);
      this.setState({
        loadingOperations: false,
        operationsData: result.data,
        noOperationsData: false,
      });
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        console.log(ex, selectedAreas);
        this.setState({
          loadingOperations: false,
          operationsData: null,
          noOperationsData: true,
        });
      }
    }
  };

  /**
   * Load operations dashboard data WORST PF
   */
  private loadOperationalWorstPf = async () => {
    this.setState({
      loadingOperationsWorstPf: false,
      operationsWorstPf: null,
    });
    const token = localStorage.getItem('token');
    const selectedAreas = this.getSelectedAreas();
    try {
      //Check if there are any previous pending requests
      if (cancelTokenOperationsWorstPf && typeof cancelTokenOperationsWorstPf != typeof undefined) {
        cancelTokenOperationsWorstPf.cancel('cancelled');
      }
      cancelTokenOperationsWorstPf = axios.CancelToken.source();

      const params = this.getOperationsDashboardsParams();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenOperationsWorstPf.token,
      };
      // const { selectedAreaType } = this.state;
      this.setState({
        loadingOperationsWorstPf: true,
        operationsWorstPf: null,
      });

      const result = await api.post<OperationsWorstPfDto>('/reports/operational/worstpf', params, options);

      this.setState({
        loadingOperationsWorstPf: false,
        // @ts-ignore
        operationsWorstPf: result.data,
      });
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        console.log(ex, selectedAreas);
        this.setState({
          loadingOperationsWorstPf: false,
          operationsWorstPf: null,
        });
      }
    }
  };

  /**
   * Load operations dashboard chart data
   */
  private loadOperationsDashboardChart = async () => {
    const token = localStorage.getItem('token');
    const selectedAreas = this.getSelectedAreas();
    try {
      const { loads } = this.getAreasByType(selectedAreas);
      const hasNoMainIncomers = loads.every((x) => !x.mainIncomer);
      if (hasNoMainIncomers) {
        throw Error('No Main Incomers');
      }
      //Check if there are any previous pending requests
      if (cancelTokenOperationsChart && typeof cancelTokenOperationsChart != typeof undefined) {
        cancelTokenOperationsChart.cancel('cancelled');
      }
      cancelTokenOperationsChart = axios.CancelToken.source();
      const type = typeObjRev[selectedAreas[0].type];
      let start = moment().startOf('day').subtract(1, 'years');
      let end = moment().endOf('day');
      const pickerDate = sessionStorage.getItem('pickerDate');
      if (pickerDate) {
        const dt = JSON.parse(pickerDate);
        // start = moment(`01-${dt.from.month}-${dt.from.year}`, 'DD-M-YYYY').startOf('day');
        end = moment(`01-${dt.to.month}-${dt.to.year}`, 'DD-M-YYYY').endOf('month').endOf('day');
        start = moment(end).subtract(1, 'year').add(1, 'month').startOf('month').startOf('day');
      }
      const params = {
        type: type === typeObjRev.group ? 7 : type,
        accountId: this.state.selectedAccount.id,
        identifiers: selectedAreas.map((x) => x.id),
        start: start.format('YYYY-MM-DDTHH:mm:ss'),
        end: end.format('YYYY-MM-DDTHH:mm:ss'),
        interval: 1,
      };
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenOperationsChart.token,
      };

      this.setState({
        loadingOperationsChart: true,
        operationsChartData: null,
        noOperationsChartData: false,
      });

      const result = await api.post<OperationsChartDto>('/reports/operational/metrics', params, options);
      if (result && result.data) {
        let hasNoData =
          result.data.utilities.filter((x) => x.data.length === 0).length === result.data.utilities.length;

        let reallyHasNoData = true;
        result.data.utilities.forEach((x) => {
          if (x.data.find((x) => x.usage)) {
            reallyHasNoData = false;
            return;
          }
        });
        if (hasNoData || reallyHasNoData) {
          throw Error();
        }
        this.setState({
          loadingOperationsChart: false,
          operationsChartData: result.data,
          noOperationsChartData: false,
        });
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        this.setState({
          loadingOperationsChart: false,
          operationsChartData: null,
          noOperationsChartData: true,
        });
      }
    }
  };

  /**
   * Load operations dashboard comparison data
   */
  private loadOperationsDashboardComparison = async () => {
    const token = localStorage.getItem('token');
    const selectedAreas = this.getSelectedAreas();
    try {
      const { loads } = this.getAreasByType(selectedAreas);
      const hasNoMainIncomers = loads.every((x) => !x.mainIncomer);
      if (hasNoMainIncomers) {
        throw Error('No Main Incomers');
      }
      //Check if there are any previous pending requests
      if (cancelTokenOperationsComparison && typeof cancelTokenOperationsComparison != typeof undefined) {
        cancelTokenOperationsComparison.cancel('cancelled');
      }
      cancelTokenOperationsComparison = axios.CancelToken.source();
      const type = typeObjRev[selectedAreas[0].type];

      let currentStart = moment().startOf('day').startOf('month');
      let currentEnd = moment(currentStart).endOf('day').endOf('month');
      let previousStart = moment(currentStart).subtract(1, 'year');
      let previousEnd = moment(previousStart).endOf('day').endOf('month');
      const pickerDate = sessionStorage.getItem('pickerDate');
      if (pickerDate) {
        const dt = JSON.parse(pickerDate);
        currentEnd = moment(`01-${dt.to.month}-${dt.to.year}`, 'DD-M-YYYY').endOf('month').endOf('day');
        currentStart = moment(currentEnd).startOf('day').startOf('month');
        previousStart = moment(currentStart).subtract(1, 'year');
        previousEnd = moment(previousStart).endOf('day').endOf('month');
      }

      const params = {
        type: type === typeObjRev.group ? 7 : type,
        accountId: this.state.selectedAccount.id,
        identifiers: selectedAreas.map((x) => x.id),
        interval: 1,
        currentStart: currentStart.format('YYYY-MM-DDTHH:mm:ss'),
        currentEnd: currentEnd.format('YYYY-MM-DDTHH:mm:ss'),
        previousStart: previousStart.format('YYYY-MM-DDTHH:mm:ss'),
        previousEnd: previousEnd.format('YYYY-MM-DDTHH:mm:ss'),
      };

      // dirty hack where if no groups, then we are showing load list,
      // so show comparison at the load aka building level
      // if (selectedAreas[0].type === 'dashboard') {
      //   const firstArea = selectedAreas[0].subareas[0];
      //   if (firstArea?.type === 'load') {
      //     params.type = typeObjRev.building;
      //     const lastId = firstArea.parentIds[firstArea.parentIds.length - 1];
      //     params.identifiers = [lastId];
      //   }
      // }

      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenOperationsComparison.token,
      };

      this.setState({
        loadingOperationsComparison: true,
        operationsComparisonData: null,
        noOperationsComparisonData: false,
      });

      const result = await api.post<OperationsComparisonDto>('/reports/operational/comparison', params, options);
      if (result && result.data && result.data.areas && result.data.areas.length > 0) {
        this.setState({
          loadingOperationsComparison: false,
          operationsComparisonData: result.data,
          noOperationsComparisonData: false,
        });
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        this.setState({
          loadingOperationsComparison: false,
          operationsComparisonData: null,
          noOperationsComparisonData: true,
        });
      }
    }
  };

  /**
   * Load operations activities dashboard data
   */
  private loadOperationsActivities = async () => {
    const token = localStorage.getItem('token');
    const selectedAreas = this.getSelectedAreas();
    try {
      const { loads } = this.getAreasByType(selectedAreas);
      const hasNoMainIncomers = loads.every((x) => !x.mainIncomer);
      if (hasNoMainIncomers) {
        throw Error('No Main Incomers');
      }
      this.setState({
        loadingOperationsActivities: true,
        operationsActivitiesData: null,
      });
      //Check if there are any previous pending requests
      if (cancelTokenOperationsActivities && typeof cancelTokenOperationsActivities != typeof undefined) {
        cancelTokenOperationsActivities.cancel('cancelled');
      }
      cancelTokenOperationsActivities = axios.CancelToken.source();
      const type = typeObjRev[selectedAreas[0].type];
      let start = moment().startOf('day').subtract(1, 'years');
      let end = moment().endOf('day');
      const pickerDate = sessionStorage.getItem('pickerDate');
      if (pickerDate) {
        const dt = JSON.parse(pickerDate);
        start = moment(`01-${dt.from.month}-${dt.from.year}`, 'DD-M-YYYY').startOf('day');
        end = moment(`01-${dt.to.month}-${dt.to.year}`, 'DD-M-YYYY').endOf('month').endOf('day');
      }

      const params = {
        type: type === typeObjRev.group ? 7 : type,
        accountId: this.state.selectedAccount.id,
        identifiers: selectedAreas.map((x) => x.id),
        start: start.format('YYYY-MM-DDTHH:mm:ss'),
        end: end.format('YYYY-MM-DDTHH:mm:ss'),
      };

      // dirty hack where if no groups, then we are showing load list,
      // so show comparison at the load aka building level
      // if (selectedAreas[0].type === 'dashboard') {
      //   const firstArea = selectedAreas[0].subareas[0];
      //   if (firstArea?.type === 'load') {
      //     params.type = typeObjRev.building;
      //     const lastId = firstArea.parentIds[firstArea.parentIds.length - 1];
      //     params.identifiers = [lastId];
      //   }
      // }

      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenOperationsActivities.token,
      };

      const result = await api.post<OperationsActivitiesDto>(`/reports/operational/activities`, params, options);

      if (result && result.data) {
        this.setState({
          loadingOperationsActivities: false,
          noOperationsActivitiesData: false,
          operationsActivitiesData: result.data,
        });
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        this.setState({
          loadingOperationsActivities: false,
          noOperationsActivitiesData: true,
          operationsActivitiesData: null,
        });
      }
    }
  };

  /**
   * Load activities page data
   */
  private loadActivities = async (params: ActivitiesParams) => {
    const token = localStorage.getItem('token');
    try {
      const accountId = this.state.selectedAccount.id;
      // const selectedAreas = this.getSelectedAreas();
      //Check if there are any previous pending requests
      if (cancelTokenLoadActivities && typeof cancelTokenLoadActivities != typeof undefined) {
        cancelTokenLoadActivities.cancel('cancelled');
      }
      cancelTokenLoadActivities = axios.CancelToken.source();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenLoadActivities.token,
      };

      const url =
        params.type === 'alerts'
          ? `/notifications/${accountId}/search?size=${params.size}&page=${params.page}&filterByStatus=${
              params.filterByStatus === -1 ? '' : params.filterByStatus
            }&sortBy=${params.sortBy}&sortByDirection=${params.sortDirection}&searchFor=${params.searchFor}`
          : `/projects/${accountId}/search`;
      // const type = typeObjRev.dashboard; //typeObjRev[selectedAreas[0].type];
      const updatedParams = {
        ...params,
        type: typeObjRev.dashboard,
        accountId: this.state.selectedAccount.id,
        identifiers: [this.state.selectedAccount.id], // selectedAreas.map((x) => x.id),
      };

      const result =
        params.type === 'alerts'
          ? await api.get<ActivitiesDto>(url, options)
          : await api.post<ActivitiesDto>(url, updatedParams, options);

      if (result && result.data) {
        return result.data;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return { notifications: [], projects: [], count: 0, page: 0, result: 0, size: 0 };
      }
    }
  };

  /**
   * Load recent activities  data
   */
  private loadRecentActivities = async () => {
    const token = localStorage.getItem('token');
    const accountId = this.state.selectedAccount.id;
    try {
      this.setState({
        loadingRecentActivities: true,
        recentActivitiesData: null,
      });
      //Check if there are any previous pending requests
      if (cancelTokenRecentAlerts && typeof cancelTokenRecentAlerts != typeof undefined) {
        cancelTokenRecentAlerts.cancel('cancelled');
      }
      cancelTokenRecentAlerts = axios.CancelToken.source();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenRecentAlerts.token,
      };

      const result = await api.get<NotificationsBellInterface>(`/notifications/${accountId}/bell`, options);

      if (result && result.data && result.data.data) {
        this.setState({
          loadingRecentActivities: false,
          recentActivitiesData: result.data.data,
        });
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        this.setState({
          loadingRecentActivities: false,
          recentActivitiesData: null,
        });
      }
    }
  };

  /**
   * Load activity details
   */
  private loadActivityDetails = async (id: string) => {
    const token = localStorage.getItem('token');
    const accountId = this.state.selectedAccount.id;
    try {
      this.setState({
        loadingActivityDetails: true,
        activityDetails: null,
      });
      //Check if there are any previous pending requests
      if (cancelTokenNotificationDetail && typeof cancelTokenNotificationDetail != typeof undefined) {
        cancelTokenNotificationDetail.cancel('cancelled');
      }
      cancelTokenNotificationDetail = axios.CancelToken.source();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenNotificationDetail.token,
      };
      const result = await api.get<NotificationsDetailsInterface>(`/notifications/${accountId}/details/${id}`, options);

      if (result && result.data) {
        const activityDetails = result.data;
        const contactsResult = await api.get<ContactsDto>(`/profile/${accountId}/contacts`, options);
        if (contactsResult && contactsResult.data && contactsResult.data.contacts) {
          activityDetails.notificationDetails.contacts = contactsResult.data.contacts;
        }

        this.setState({
          loadingActivityDetails: false,
          activityDetails: activityDetails,
        });
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        this.setState({
          loadingActivityDetails: false,
          activityDetails: null,
        });
      }
    }
  };

  /**
   * Load project details
   */
  private loadProjectDetails = async (id: string) => {
    const token = localStorage.getItem('token');
    const accountId = this.state.selectedAccount.id;
    try {
      this.setState({
        loadingProjectDetails: true,
        projectDetails: null,
      });
      //Check if there are any previous pending requests
      if (cancelTokenNotificationDetail && typeof cancelTokenNotificationDetail != typeof undefined) {
        cancelTokenNotificationDetail.cancel('cancelled');
      }
      cancelTokenNotificationDetail = axios.CancelToken.source();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenNotificationDetail.token,
      };
      const result = await api.get<ProjectDetailDto>(`/projects/${accountId}/${id}`, options);

      if (result && result.data && result.data) {
        const projectDetails = result.data;
        const contactsResult = await api.get<ContactsDto>(`/profile/${accountId}/contacts`, options);
        if (contactsResult && contactsResult.data && contactsResult.data.contacts) {
          projectDetails.contacts = contactsResult.data.contacts;
        }

        this.setState({
          loadingProjectDetails: false,
          projectDetails: projectDetails,
        });
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        this.setState({
          loadingProjectDetails: false,
          projectDetails: null,
        });
      }
    }
  };

  /**
   * Get profile contacts
   */
  private getProfileContacts = async () => {
    const token = localStorage.getItem('token');
    const accountId = this.state.selectedAccount.id;
    let contacts = [];

    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const contactsResult = await api.get<ContactsDto>(`/profile/${accountId}/contacts`, options);
      if (contactsResult && contactsResult.data && contactsResult.data.contacts) {
        contacts = contactsResult.data.contacts;
      }
    } catch (ex) {
      // error
    }
    return contacts;
  };

  /**
   * Update Contact
   */
  private updateContact = async (contact: ContactDto) => {
    const token = localStorage.getItem('token');
    // const accountId = this.state.selectedAccount.id;
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.put<any>(`/profile/contact/${contact.contactId}`, contact, options);

      if (result && [200, 204].indexOf(result.status) > -1) {
        toast.success(`User successfully updated`);
        const newUser = { ...this.state.user };
        newUser.profile.particulars.firstName = result.data.contacts.first;
        newUser.profile.particulars.lastName = result.data.contacts.last;
        newUser.profile.particulars.displayName = result.data.contacts.displayName;
        newUser.profile.particulars.emailAddress = result.data.contacts.email;
        newUser.profile.particulars.mobile = result.data.contacts.mobile;
        // newUser.profile.particulars.phone = result.data.contacts.phone;
        this.setState({ user: newUser });
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      toast.error(`Sorry, there was a problem updating this user. Please reload the page and try again`);
      return false;
    }
  };

  /**
   * Create Contact
   */
  private createContact = async (contact: CreateContactDto) => {
    const token = localStorage.getItem('token');
    const accountId = this.state.selectedAccount.id;
    try {
      // contact.accountId = accountId;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.put<CreateContactDto>(
        `/admin/contacts/create/${accountId}`,
        {
          email: contact.emailAddress,
          role: contact.role,
        },
        options
      );

      if (result && [200, 204].indexOf(result.status) > -1) {
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      return false;
    }
  };

  /**
   * Upsert Contacts
   */
  private upsertContact = async (contact: UpsertContactDto) => {
    const token = localStorage.getItem('token');
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.put<UpsertContactDto>(`/admin/contactsrole`, contact, options);

      if (result && [200, 204].indexOf(result.status) > -1) {
        toast.success(`Users successfully ${contact.contactId ? 'updated' : 'created'}`);
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      toast.error(
        `Sorry, there was a problem ${
          contact.contactId ? 'updating' : 'creating'
        } this user. Please reload the page and try again`
      );
      return false;
    }
  };

  /**
   * Accept welcome invite
   */
  private acceptWelcomeInvite = async (contactId: string, accountId: string, accept: boolean) => {
    const token = localStorage.getItem('token');
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.put<UpsertContactDto>(
        `/profile/contact/welcome-invite`,
        { contactId, accountId, accept },
        options
      );

      if (result && [200, 204].indexOf(result.status) > -1) {
        toast.success(`Permission successfully set!`);
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      toast.error(
        `Sorry, there was a problem setting the permissions on this account. Please reload the page and try again`
      );

      return false;
    }
  };

  /**
   * Update Notification
   * Toggles load activities to force page to refresh
   */
  private updateNotification = async (notificationId: string, data: Notification) => {
    const token = localStorage.getItem('token');
    const accountId = this.state.selectedAccount.id;
    try {
      this.setState({ loadingActivities: true });
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.put<Notification>(
        `/notifications/${accountId}/details/${notificationId}`,
        data,
        options
      );

      if (result && result.status === 204) {
        toast.success(`Alert successfully updated`);
        this.setState({ loadingActivities: false });
        this.loadRecentActivities();
        this.manageRightDrawer(false, 'activities', null);
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      toast.error(`Sorry, there was a problem updating this alert. Please reload the page and try again`);
      this.setState({ loadingActivities: false });
      return false;
    }
  };

  /**
   * Update Project
   * Toggles load activities to force page to refresh
   */
  private updateProject = async (projectId?: string, data?: UpdateProjectDto) => {
    const token = localStorage.getItem('token');
    const accountId = this.state.selectedAccount.id;
    try {
      this.setState({ loadingActivities: true });
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };

      let validResult = false;

      if (projectId) {
        const result = await api.put<ProjectDetailDto>(`/projects/${accountId}/${projectId}`, data, options);
        validResult = result?.status === 204;
      } else {
        const result = await api.post<ProjectDetailDto>(`/projects/${accountId}`, data, options);
        validResult = result?.status === 200;
      }
      if (validResult) {
        toast.success(`Project successfully updated`);
        this.setState({ loadingActivities: false });
        this.loadRecentActivities();
        this.manageRightDrawer(false, 'projects', null);
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      toast.error(`Sorry, there was a problem updating this project. Please reload the page and try again`);
      this.setState({ loadingActivities: false });
      return false;
    }
  };

  /**
   * API to return virtual groups
   */
  private getVirtualGroups = async () => {
    const token = localStorage.getItem('token');
    try {
      const accountId = this.state.selectedAccount.id;

      const result = await api.get<VirtualGroupReqDto>(`admin/structures/${accountId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (result?.data?.nodes.length > 0) {
        const innerFn = (nodes: VirtualGroupDto[], parentId: string, tier: number) => {
          nodes.forEach((x) => {
            x.parentId = parentId;
            x.tier = tier;
            x.updatedOn = moment(x.updatedOn).format('DD/MM/YYYY');
            innerFn(x.nodes, x.id, tier + 1);
          });
        };
        const innerNodes = result.data.nodes.filter(
          (x) => x.label?.toLowerCase().indexOf('saving') === -1 && x.label?.toLowerCase().indexOf('variable') === -1
        );
        innerFn(innerNodes, null, 1);
        return innerNodes;
      } else {
        throw Error();
      }
    } catch (ex) {
      return [];
    }
  };

  /**
   * API to return virtual groups
   */
  private getVirtualGroupsLoads = async () => {
    const token = localStorage.getItem('token');
    try {
      const accountId = this.state.selectedAccount.id;
      const result = await api.get<VirtualGroupLoadsReqDto>(`admin/structures/${accountId}/loads`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (result?.data?.loads.length > 0) {
        return result.data.loads.filter(
          (x) => x.label?.toLowerCase().indexOf('saving') === -1 && x.label?.toLowerCase().indexOf('variable') === -1
        );
      } else {
        throw Error();
      }
    } catch (ex) {
      return [];
    }
  };

  /**
   * Create Contact
   */
  private createVirtualGroup = async (group: VirtualGroupDto) => {
    const token = localStorage.getItem('token');
    const accountId = this.state.selectedAccount.id;
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.post<VirtualGroupDto>(`/admin/structures/${accountId}`, group, options);

      if (result && [200, 204].indexOf(result.status) > -1) {
        toast.success(`Group "${group.label}" successfully created`);
        return result.data;
      } else {
        throw Error();
      }
    } catch (ex) {
      toast.error(`Sorry, there was a problem creating this group. Please reload the page and try again`);
      return null;
    }
  };

  /**
   * Update Virtual Group
   */
  private updateVirtualGroup = async (group: VirtualGroupDto) => {
    const token = localStorage.getItem('token');
    const accountId = this.state.selectedAccount.id;
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.put<VirtualGroupDto>(`/admin/structures/${accountId}/${group.id}`, group, options);
      if (result && [200, 204].indexOf(result.status) > -1) {
        toast.success(`Group "${group.label}" successfully updated`);
        // reload account metadata
        sessionStorage.setItem('reloadAccountMetadata', 'yes');
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      toast.error(
        `Sorry, there was a problem updating the group "${group.label}". Please reload the page and try again`
      );
      return false;
    }
  };

  /**
   * Move Virtual Group
   */
  private moveVirtualGroup = async (items: string[], groupName: string, newParentId?: string) => {
    const token = localStorage.getItem('token');
    const accountId = this.state.selectedAccount.id;
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.put<any>(`/admin/structures/${accountId}`, { parentId: newParentId, items }, options);

      if (result && [200, 204].indexOf(result.status) > -1) {
        toast.success(`Group "${groupName}" moved`);
        // reload account metadata
        sessionStorage.setItem('reloadAccountMetadata', 'yes');
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      toast.error(`Sorry, there was a problem moving the group "${groupName}". Please reload the page and try again`);
      return false;
    }
  };

  /**
   * Delete Virtual Group
   */
  private deleteVirtualGroup = async (group: VirtualGroupDto) => {
    const token = localStorage.getItem('token');
    const accountId = this.state.selectedAccount.id;

    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.delete<VirtualGroupDto>(`/admin/structures/${accountId}/${group.id}`, options);
      if (result && [200, 204].indexOf(result.status) > -1) {
        toast.success(`Group "${group.label}" successfully deleted`);
        // reload account metadata
        sessionStorage.setItem('reloadAccountMetadata', 'yes');
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      toast.error(
        `Sorry, there was a problem deleteing the group "${group.label}". Please reload the page and try again`
      );
      return false;
    }
  };

  /**
   * Reorder Virtual Group
   */
  private reorderVirtualGroup = async (group: VirtualGroupDto, index: number) => {
    const token = localStorage.getItem('token');
    const accountId = this.state.selectedAccount.id;
    try {
      const newGroup = {
        ...group,
        label: group.label,
        restricted: group.restricted,
        permissions: group.permissions,
        index: index,
      };

      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.put<any>(`/admin/structures/${accountId}/${group.id}/${index}`, newGroup, options);

      if (result && [200, 204].indexOf(result.status) > -1) {
        toast.success(`Group "${group.label}" reordered`);
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      toast.error(`Sorry, there was a problem reordering group "${group.label}". Please reload the page and try again`);
      return false;
    }
  };

  /**
   * Get Retailers
   */
  private getRetailers = async () => {
    const token = localStorage.getItem('token');
    let retailers = [] as RetailerDto[];

    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const retailersResult = await api.get<RetailersDto>(`/admin/authority/retailers`, options);
      if (retailersResult && retailersResult.data?.items?.length > 0) {
        retailers = retailersResult.data.items;
      }
    } catch (ex) {
      // error
    }
    retailers.sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
    return retailers as RetailerDto[];
  };

  /**
   * API to return authority loads
   */
  private getAuthorityLoads = async () => {
    const token = localStorage.getItem('token');
    try {
      const accountId = this.state.selectedAccount.id;
      const result = await api.get<AuthorityLoadsSearchDto>(`admin/authority/loads?accountId=${accountId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (result?.data?.loads?.length > 0) {
        return result.data.loads;
      } else {
        throw Error();
      }
    } catch (ex) {
      return [];
    }
  };

  /**
   * Load bill authorities
   */
  private getAuthorities = async (params: { page; size; query }) => {
    const token = localStorage.getItem('token');
    try {
      //Check if there are any previous pending requests
      if (cancelTokenGetAuthorities && typeof cancelTokenGetAuthorities != typeof undefined) {
        cancelTokenGetAuthorities.cancel('cancelled');
      }
      cancelTokenGetAuthorities = axios.CancelToken.source();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenGetAuthorities.token,
      };

      const url = '/admin/authority/search';
      const updatedParams = {
        ...params,
        accountId: this.state.selectedAccount.id,
      };
      const result = await api.post<AuthoritiesSearchDto>(url, updatedParams, options);
      if (result && result.data) {
        return result.data;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return null;
      }
    }
  };

  /**
   * Get Authority
   */
  private getAuthority = async (authorityId: string) => {
    const token = localStorage.getItem('token');
    try {
      //Check if there are any previous pending requests
      if (cancelTokenGetAuthority && typeof cancelTokenGetAuthority != typeof undefined) {
        cancelTokenGetAuthority.cancel('cancelled');
      }
      cancelTokenGetAuthority = axios.CancelToken.source();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenGetAuthority.token,
      };

      const url = `/admin/authority/${authorityId}`;
      const result = await api.get<AuthorityResponseDto>(url, options);
      if (result && result.data) {
        return result.data.authority;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return null;
      }
    }
  };

  /**
   * Load bill letter of authorities
   */
  private getAuthorityLetters = async (authorityId: string) => {
    const token = localStorage.getItem('token');
    try {
      //Check if there are any previous pending requests
      if (cancelTokenGetAuthorityLetters && typeof cancelTokenGetAuthorityLetters != typeof undefined) {
        cancelTokenGetAuthorityLetters.cancel('cancelled');
      }
      cancelTokenGetAuthorityLetters = axios.CancelToken.source();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenGetAuthorityLetters.token,
      };

      const url = `/admin/authority/${authorityId}/letter`;

      const result = await api.get<AuthorityLettersDto>(url, options);
      if (result && result.data?.items?.length > 0) {
        return result.data.items;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return [];
      }
    }
  };

  /**
   * Create Authority
   */
  private createAuthority = async (authority: AuthorityDto) => {
    const token = localStorage.getItem('token');
    const accountId = this.state.selectedAccount.id;
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.post<AuthorityResponseDto>(
        `/admin/authority`,
        { authority: { ...authority, accountId: accountId } },
        options
      );

      if (result && [200, 204].indexOf(result.status) > -1) {
        toast.success(`Authority "${authority.label}" successfully created`);
        return result.data.authority;
      } else {
        throw Error();
      }
    } catch (ex) {
      toast.error(`Sorry, there was a problem creating this authority. Please reload the page and try again`);
      return null;
    }
  };

  /**
   * Update Authority
   */
  private updateAuthority = async (authority: AuthorityDto) => {
    const token = localStorage.getItem('token');
    const accountId = this.state.selectedAccount.id;
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const data = {
        authority: { ...authority, accountId: accountId, loads: authority.loads.map((x) => ({ loadId: x.loadId })) },
      };
      const result = await api.put<AuthorityResponseDto>(`/admin/authority/${authority.authorityId}`, data, options);
      if (result && [200, 204].indexOf(result.status) > -1) {
        toast.success(`Authority "${authority.label}" successfully updated`);
        // reload account metadata
        sessionStorage.setItem('reloadAccountMetadata', 'yes');
        return authority;
      } else {
        throw Error();
      }
    } catch (ex) {
      toast.error(
        `Sorry, there was a problem updating the authority "${authority.label}". Please reload the page and try again`
      );
      return null;
    }
  };

  /**
   * Delete Authority
   */
  private deleteAuthority = async (authorityId: string, label: string) => {
    const token = localStorage.getItem('token');
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.delete<any>(`/admin/authority/${authorityId}`, options);

      if (result && [200, 204].indexOf(result.status) > -1) {
        toast.success(`Authority "${label}" successfully deleted`);
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      toast.error(`Sorry, there was a problem deleting this authority. Please reload the page and try again`);
      return false;
    }
  };

  /**
   * Create Authority Letter
   */
  private createAuthorityLetter = async (authorityId: string, authorityLetter: AuthorityLetterDto) => {
    const token = localStorage.getItem('token');
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.post<AuthorityLetterResponseDto>(
        `/admin/authority/${authorityId}/letter`,
        authorityLetter,
        options
      );

      if (result && [200, 204].indexOf(result.status) > -1) {
        toast.success(`Letter of Authority "${authorityLetter.label}" successfully created`);
        return result.data.letter;
      } else {
        throw Error();
      }
    } catch (ex) {
      toast.error(`Sorry, there was a problem creating this letter of authority. Please reload the page and try again`);
      return null;
    }
  };

  /**
   * Update Authority Letter
   */
  private updateAuthorityLetter = async (authorityLetter: AuthorityLetterDto) => {
    const token = localStorage.getItem('token');
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.put<any>(
        `/admin/authority/${authorityLetter.authorityId}/letter/${authorityLetter.letterId}`,
        authorityLetter,
        options
      );
      if (result && [200, 204].indexOf(result.status) > -1) {
        // reload account metadata
        sessionStorage.setItem('reloadAccountMetadata', 'yes');
        return authorityLetter;
      } else {
        throw Error();
      }
    } catch (ex) {
      return null;
    }
  };

  /**
   * Delete Authority Letter
   */
  private deleteAuthorityLetter = async (authorityLetter: AuthorityLetterDto) => {
    const token = localStorage.getItem('token');
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.delete<any>(
        `/admin/authority/${authorityLetter.authorityId}/letter/${authorityLetter.letterId}`,
        options
      );
      if (result && [200, 204].indexOf(result.status) > -1) {
        // reload account metadata
        sessionStorage.setItem('reloadAccountMetadata', 'yes');
        toast.success(`Letter of Authority "${authorityLetter.label}" successfully deleted`);
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      toast.error(`Sorry, there was a problem deleting this letter of authority. Please reload the page and try again`);
      return false;
    }
  };

  /**
   * Get Bills
   */
  private getBills = async (identifier?: string) => {
    const token = localStorage.getItem('token');
    try {
      //Check if there are any previous pending requests
      if (cancelTokenGetBills && typeof cancelTokenGetBills != typeof undefined) {
        cancelTokenGetBills.cancel('cancelled');
      }
      cancelTokenGetBills = axios.CancelToken.source();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenGetBills.token,
      };

      let url = `/admin/bills/${this.state.selectedAccount.id}?${this.getBillsPeriodAndQueryStr().str}`;
      if (identifier) {
        url = `/admin/bills/${this.state.selectedAccount.id}?from=2010-01-01T00:00:00&to=${moment().format(
          'YYYY-MM-DDT23:59:59'
        )}&identifier=${identifier}&utility=0`;
      }
      const result = await api.get<BillsDto>(url, options);
      if (result && result.data) {
        return result.data.items;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return [];
      }
    }
  };

  /**
   * Get Bill Document by Bill Id and Document Id
   */
  private getBill = async (billId: String, docId: string) => {
    const token = localStorage.getItem('token');
    try {
      //Check if there are any previous pending requests
      if (cancelTokenGetBill && typeof cancelTokenGetBill != typeof undefined) {
        cancelTokenGetBill.cancel('cancelled');
      }
      cancelTokenGetBill = axios.CancelToken.source();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenGetBill.token,
      };

      const url = `/admin/bills/${this.state.selectedAccount.id}/${billId}/${docId}`;
      const result = await api.get<BillDetailsDto>(url, options);
      if (result && result.data && result.data.bill) {
        return result.data.bill;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return null;
      }
    }
  };

  /**
   * Get Bills Utilities
   */
  private getBillsByUtilities = async () => {
    const token = localStorage.getItem('token');
    try {
      //Check if there are any previous pending requests
      if (cancelTokenGetBillsByUtilities && typeof cancelTokenGetBillsByUtilities != typeof undefined) {
        cancelTokenGetBillsByUtilities.cancel('cancelled');
      }
      cancelTokenGetBillsByUtilities = axios.CancelToken.source();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenGetBillsByUtilities.token,
      };

      const url = `/admin/bills/${this.state.selectedAccount.id}/utilities?${this.getBillsPeriodAndQueryStr().str}`;
      const result = await api.get<BillsUtilitiesDto>(url, options);
      if (result && result.data) {
        return result.data.items;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return [] as BillsUtilityDto[];
      }
    }
  };

  /**
   * Get All Retailers
   */
  private getAllBillRetailers = async () => {
    const token = localStorage.getItem('token');
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const queryStr = this.getBillsPeriodAndQueryStr().str;
      const url = `/admin/bills/${this.state.selectedAccount.id}/retailers?${queryStr.split('&utility')[0]}`;
      const result = await api.get<BillsRetailersDto>(url, options);
      if (result && result.data) {
        return result.data.items;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return [] as BillsRetailerDto[];
      }
    }
  };

  /**
   * Get Bills Retailers
   */
  private getBillsByRetailers = async () => {
    const token = localStorage.getItem('token');
    try {
      //Check if there are any previous pending requests
      if (cancelTokenGetBillsByRetailers && typeof cancelTokenGetBillsByRetailers != typeof undefined) {
        cancelTokenGetBillsByRetailers.cancel('cancelled');
      }
      cancelTokenGetBillsByRetailers = axios.CancelToken.source();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenGetBillsByRetailers.token,
      };

      const url = `/admin/bills/${this.state.selectedAccount.id}/retailers?${this.getBillsPeriodAndQueryStr().str}`;
      const result = await api.get<BillsRetailersDto>(url, options);
      if (result && result.data) {
        return result.data.items;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return [] as BillsRetailerDto[];
      }
    }
  };

  /**
   * Get Bills period
   */
  private getBillsByPeriod = async () => {
    const token = localStorage.getItem('token');
    try {
      //Check if there are any previous pending requests
      if (cancelTokenGetBillsByPeriod && typeof cancelTokenGetBillsByPeriod != typeof undefined) {
        cancelTokenGetBillsByPeriod.cancel('cancelled');
      }
      cancelTokenGetBillsByPeriod = axios.CancelToken.source();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenGetBillsByPeriod.token,
      };

      const url = `/admin/bills/${this.state.selectedAccount.id}/period?${this.getBillsPeriodAndQueryStr().str}`;
      const result = await api.get<BillsPeriodsDto>(url, options);
      if (result && result.data) {
        return result.data.series;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return [] as BillsPeriodDto[];
      }
    }
  };

  /**
   * API to uploads bills
   */
  private uploadBill = async (file: any) => {
    const token = localStorage.getItem('token');
    try {
      const options = {
        'Content-Type': 'multipart/form-data',
        headers: { Authorization: `Bearer ${token}` },
      };
      const url = `/bills/${this.state.selectedAccount.id}/manual`;

      const result = await api.post<any>(url, file, {
        ...options,
      });

      if (result?.data?.document) {
        return result.data;
      } else {
        return null;
      }
    } catch (ex) {
      toast.error(`Sorry, there was a problem uploading this bill. Please reload the page and try again`);
      return null;
    }
  };

  /** Set Bills Period */
  private setBillsPeriod = (period: number) => {
    if (period !== this.state.billsPeriod) {
      sessionStorage.setItem('billsPeriod', period.toString());
      this.setState({ billsPeriod: period });
    }
  };

  /**
   * Get the bills default period and query str for the bills api queries
   * IF current month is apr, return last year by default
   */
  private getBillsPeriodAndQueryStr = () => {
    let fsm = this.state?.selectedAccount?.financialStartMonth
      ? (this.state.selectedAccount.financialStartMonth + 1).toString()
      : '4';
    if (Number(fsm) < 10) {
      fsm = `0${fsm}`;
    }
    let period = Number(moment().format('YYYY'));
    if (sessionStorage.getItem('billsPeriod')) {
      period = Number(sessionStorage.getItem('billsPeriod'));
    } else if (this.state.billsPeriod) {
      period = Number(this.state.billsPeriod);
    } else if (moment().format('MMM') === 'APR') {
      period = Number(moment().subtract(1, 'year').format('YYYY'));
    } else {
      period = Number(moment().format('YYYY'));
    }
    let from = moment().year(period).format(`YYYY-${fsm}-01T00:00:00`);
    let to = moment(from).add(364, 'days').endOf('month').format('YYYY-MM-DDT23:59:59');
    if (moment(to).isAfter(moment())) {
      to = moment().endOf('month').format('YYYY-MM-DDT23:59:59');
    }
    const utility = sessionStorage.getItem('billsUtility') ? sessionStorage.getItem('billsUtility') : '0';
    const retailer = sessionStorage.getItem('billsRetailer') ? sessionStorage.getItem('billsRetailer') : '';
    return {
      str: `from=${from}&to=${to}&utility=${utility}${retailer ? `&retailerId=${retailer}` : ''}`,
      from,
      to,
      period,
    };
  };

  /**
   * Method to create a new account for a contact
   */
  private createAccount = async (contactId: string, name: string) => {
    try {
      //Check if there are any previous pending requests
      if (cancelTokenCreateAccount && typeof cancelTokenCreateAccount != typeof undefined) {
        cancelTokenCreateAccount.cancel('cancelled');
      }
      cancelTokenCreateAccount = axios.CancelToken.source();
      const options = {
        cancelToken: cancelTokenCreateAccount.token,
      };
      const url = `/profile/account`;
      // todo: add address
      const result = await api.post<any>(url, { contactId, name }, options);
      if (result && result.data?.account?.id) {
        return result.data.account.id;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return null;
      }
    }
  };

  /**
   * Method to create a new account billing for the account
   */
  private createAccountSubscription = async (accountId: string, address: AddressDto, promoCode: string) => {
    try {
      //Check if there are any previous pending requests
      if (cancelTokenCreateAccountBilling && typeof cancelTokenCreateAccountBilling != typeof undefined) {
        cancelTokenCreateAccountBilling.cancel('cancelled');
      }
      cancelTokenCreateAccountBilling = axios.CancelToken.source();
      const options = {
        cancelToken: cancelTokenCreateAccountBilling.token,
        validateStatus: (status) => status === 400 || status === 200,
      };
      const result = await api.post<any>(
        `/subscription`,
        {
          accountId,
          planId: '3ba62bfc-e66f-4e93-a188-755fbd7ecd10',
          countryCode: address.country,
          address: address,
          taxNumber: '',
          promotionCode: promoCode,
        },
        options
      );
      if (!result?.data?.errorMessage) {
        return result.data;
      } else {
        toast.error(result.data.errorMessage);
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return null;
      }
    }
  };

  /**
   * Get a stripe url to redirect the client to
   */
  private getStripeKey = async (accountId: string) => {
    try {
      const url = `/subscription/transactions`;
      const result = await api.post<any>(url, {
        accountId,
        planId: '3ba62bfc-e66f-4e93-a188-755fbd7ecd10',
      });
      if (result && result.data?.clientSecret) {
        return { clientSecret: result.data.clientSecret, amount: result.data.amount };
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return null;
      }
    }
  };

  /**
   * API to get carbon industries
   */
  private getCarbonIndustries = async () => {
    try {
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.get<IndustriesDto>(`/carbon/industries`, options);
      if (result && result.data?.industries?.length > 0) {
        const industries = result.data.industries;
        industries.sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
        return industries;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return [];
      }
    }
  };

  /**
   * API to get carbon sectors
   */
  private getCarbonSectorsByIndustry = async (industryId: string) => {
    try {
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.get<SectorsDto>(`/carbon/industries/${industryId}/sectors`, options);
      if (result && result.data?.sectors?.length > 0) {
        const sectors = result.data.sectors;
        sectors.sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
        return sectors;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return [];
      }
    }
  };

  /**
   * API to get carbon emission factors
   */
  private getCarbonEmissionFactors = async (year: number) => {
    try {
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.get<EmissionFactorsDto>(`/carbon/emissionfactors?year=${year}`, options);
      if (result && result.data?.emissionFactors?.length > 0) {
        const emissionFactors = result.data.emissionFactors;
        emissionFactors.sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
        return emissionFactors;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return [];
      }
    }
  };

  /**
   * API to get carbon emission factor sources
   */
  private getCarbonEmissionFactorSources = async (emissionFactorId: string, type: number) => {
    try {
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.get<GetCarbonInventoryEmissionFactorSourcesDto>(
        `/carbon/emissionfactors/${emissionFactorId}/emissionsources?type=${type}`,
        options
      );
      if (result && result.data?.items?.length > 0) {
        // const sources = result.data.emissionFactorSources.filter((x) => x.unit);
        // sources.sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
        return result.data.items;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return [];
      }
    }
  };

  /**
   * API to get carbon inventories
   */
  private getCarbonInventories = async (includeState?: boolean) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      //Check if there are any previous pending requests
      if (cancelTokenGetCarbonInventories && typeof cancelTokenGetCarbonInventories != typeof undefined) {
        cancelTokenGetCarbonInventories.cancel('cancelled');
      }
      cancelTokenGetCarbonInventories = axios.CancelToken.source();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenGetCarbonInventories.token,
      };

      if (includeState) {
        this.setState({ loadingCarbonInventories: true });
      }

      const result = await api.get<GetCarbonInventoriesDto>(`/carbon/${accountId}`, options);
      if (result?.data?.inventories) {
        const inventories = result.data.inventories.map((x) => {
          if (x.assessmentId) {
            return { ...x, inventoryId: x.assessmentId };
          } else {
            return x;
          }
        });

        if (includeState) {
          this.setState({ carbonInventories: inventories, loadingCarbonInventories: false });
        }
        return inventories;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      if (includeState) {
        this.setState({ carbonInventories: [], loadingCarbonInventories: false });
      }
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return [];
      }
    }
  };

  /**
   * API to get carbon baseline
   */
  private getCarbonBaseline = async (id?: string) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const url = id
        ? `/carbon/inventories/baseline/${id}?accountId=${accountId}`
        : `/carbon/inventories/baseline?accountId=${accountId}`;
      const result = await api.get<CarbonBaselineDto>(url, options);
      if (result?.data?.targetYear) {
        return result?.data;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return null;
      }
    }
  };

  /**
   * API to get set carbon inventory
   */
  private setCarbonBaseline = async (data: CarbonBaselineDto) => {
    try {
      const token = localStorage.getItem('token') as string;
      //Check if there are any previous pending requests
      if (cancelTokenCreateCarbonInventory && typeof cancelTokenCreateCarbonInventory != typeof undefined) {
        cancelTokenCreateCarbonInventory.cancel('cancelled');
      }
      cancelTokenCreateCarbonInventory = axios.CancelToken.source();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenCreateCarbonInventory.token,
      };
      const result = await api.post<CreateCarbonInventoryResponseDto>(
        `/carbon/inventories/baseline`,
        {
          ...data,
          accountId: this.state.selectedAccount?.id,
        },
        options
      );

      if (!result?.data?.errorMessage) {
        return { result: true, message: null };
      } else {
        return { result: false, message: result?.data?.errorMessage };
      }
    } catch (ex) {
      toast.error(`There was a problem creating this baseline. Please try again or contact support.`);
      console.log(ex);
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return null;
      }
    }
  };

  /**
   * API to get create carbon inventory
   */
  private createCarbonInventory = async (data: CarbonInventoryDto) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      //Check if there are any previous pending requests
      if (cancelTokenCreateCarbonInventory && typeof cancelTokenCreateCarbonInventory != typeof undefined) {
        cancelTokenCreateCarbonInventory.cancel('cancelled');
      }
      cancelTokenCreateCarbonInventory = axios.CancelToken.source();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenCreateCarbonInventory.token,
        validateStatus: (status) => status === 400 || status === 200,
      };
      const result = await api.post<CreateCarbonInventoryResponseDto>(
        `/carbon/${accountId}`,
        {
          ...data,
          accountId: this.state.selectedAccount?.id,
        },
        options
      );

      if (!result?.data?.errorMessage) {
        toast.success(`Great! This inventory was created.`);
        sessionStorage.setItem('inventoryId', result.data.inventoryId);
        return { inventoryId: result.data.inventoryId, message: null };
      } else {
        return { inventoryId: null, message: result?.data?.errorMessage };
      }
    } catch (ex) {
      console.log(ex);
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return null;
      }
    }
  };

  /**
   * API to get update carbon inventory
   */
  private updateCarbonInventory = async (data: UpdateCarbonInventoryDto) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      //Check if there are any previous pending requests
      if (cancelTokenCreateCarbonInventory && typeof cancelTokenCreateCarbonInventory != typeof undefined) {
        cancelTokenCreateCarbonInventory.cancel('cancelled');
      }
      cancelTokenCreateCarbonInventory = axios.CancelToken.source();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenCreateCarbonInventory.token,
        validateStatus: (status) => status === 400 || status === 200 || status === 203,
      };
      const result = await api.post<CreateCarbonInventoryResponseDto>(
        `/carbon/${accountId}/${data.assessmentId}`,
        {
          ...data,
        },
        options
      );

      if (!result?.data?.errorMessage) {
        toast.success(`Great! This inventory was updated.`);
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.log(ex);
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return null;
      }
    }
  };

  /**
   * API to get publish carbon inventory
   */
  private publishCarbonInventory = async (inventoryId: string, publish: boolean) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;

      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.put<any>(
        `/carbon/${accountId}/${inventoryId}/publication?publish=${publish ? 'true' : 'false'}`,
        null,
        options
      );

      if (!result?.data?.errorMessage) {
        toast.success(`Great! This inventory was ${publish ? 'published' : 'unpublished'}.`);
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      toast.error(`This inventory failed to publish/unpublish. Please reload the page and try again.`);
      return null;
    }
  };

  /**
   * API to get get carbon inventory by type
   */
  public getCarbonInventoryByType = async (inventoryId: string, type: number) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.get<GetCarbonInventoryByTypeDto>(`/carbon/${accountId}/${inventoryId}/${type}`, options);
      if (result.data?.lines?.length >= 0) {
        const clone = [].concat(result.data?.lines);
        clone.sort((a, b) => (a.period > b.period ? 1 : a.period < b.period ? -1 : 0));
        return clone;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return [];
      }
    }
  };

  /**
   * API to get upsert carbon inventory by type
   */
  public upsertCarbonInventoryByType = async (
    inventoryId: string,
    type: number,
    data: CreateCarbonInventoryLineDto
  ) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      if (!data.dataId) {
        delete data.dataId;
      }
      if (!data.document?.documentId) {
        delete data.document;
      }
      const result = await api.put<any>(`/carbon/${accountId}/${inventoryId}/${type}`, data, options);
      if (!result.data?.errorMessage) {
        return result.data.dataId;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      return null;
    }
  };

  /**
   * API to get upsert carbon inventory by type
   */
  public deleteCarbonInventoryByType = async (inventoryId: string, dataId: string, type: number) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.delete<any>(`/carbon/${accountId}/${inventoryId}/${type}?dataId=${dataId}`, options);
      if (result && [200, 203].indexOf(result.status) > -1) {
        toast.success(`Great! This entry was deleted.`);
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      }
      toast.error(`Sorry, there was a problem deleting this entry. Please reload the page and try again`);
      return false;
    }
  };

  /**
   * Search airport
   */
  public searchAirport = async (data: string) => {
    try {
      const token = localStorage.getItem('token') as string;
      // if (cancelTokenAirportSearch && typeof cancelTokenAirportSearch != typeof undefined) {
      // cancelTokenAirportSearch.cancel('cancelled');
      // }
      // cancelTokenAirportSearch = axios.CancelToken.source();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        // cancelToken: cancelTokenAirportSearch.token,
      };
      const result = await api.get<any>(`/carbon/airports?search=${data}`, options);
      if (result && result.data?.airports?.length > 0) {
        return result.data.airports.map((x) => ({
          ...x,
          label: `${x.label} ${x.code ? `(${x.code})` : ''}`,
          value: x.code.replace(/ /g, ''),
        }));
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      }
      return false;
    }
  };

  /**
   * Get Carbon Summary
   */
  public getCarbonSummary = async (assessmentId?: string) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      if (cancelTokenGetCarbonSummary && typeof cancelTokenGetCarbonSummary != typeof undefined) {
        cancelTokenGetCarbonSummary.cancel('cancelled');
      }
      cancelTokenGetCarbonSummary = axios.CancelToken.source();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenGetCarbonSummary.token,
      };
      const result = await api.post<CarbonSummaryDto>(`/carbon/report/summary`, { accountId, assessmentId }, options);
      if (result && result.data?.result === 1) {
        return result.data;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      }
      return null;
    }
  };

  /**
   * Get Carbon Summary
   */
  public getCarbonSummaryRoadmap = async (from: string, to: string) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.post<CarbonSummaryRoadmapDto>(
        `/carbon/report/summary`,
        { accountId, from, to },
        options
      );
      if (result && result.data?.inventories.length > 0) {
        return result.data?.inventories;
      } else {
        throw Error();
      }
    } catch (ex) {
      if (ex.message === 'cancelled') {
        // do nothing
      }
      return [];
    }
  };

  /**
   * Get Carbon Report Lite
   */
  public getCarbonReport = async (inventoryId?: string) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.get<any>(`/carbon/${accountId}/reports/inventory/${inventoryId}`, options);
      if (result?.data?.url) {
        return result.data.url;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      if (ex.message === 'cancelled') {
        // do nothing
      }
      return null;
    }
  };

  /**
   * API to check whether to show automation bill authority flag
   */
  public checkAutomationBillAuthority = async () => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.get<any>(`/bills/${accountId}/authority/automation`, options);
      if (result?.data?.billDocumentMissingAutomation?.length > 0) {
        return result.data?.billDocumentMissingAutomation;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      if (ex.message === 'cancelled') {
        // do nothing
      }
      return [];
    }
  };

  /**
   * Create Carbon Authority API
   */
  public createCarbonAuthority = async (data: AuthorityLetterDto) => {
    try {
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const updatedData = { ...data };
      delete updatedData.authorityId;
      const result = await api.post<any>(`/authority/${data.authorityId}/letter`, updatedData, options);
      if (!result.data?.errorMessage) {
        return result.data;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      return null;
    }
  };

  /**
   * API to get carbon subscriptions
   */
  public getCarbonSubscription = async () => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.get<CarbonSubscriptionDto>(`/subscription/${accountId}`, options);
      if (result?.data) {
        return result.data;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      if (ex.message === 'cancelled') {
        // do nothing
      }
      return null;
    }
  };

  /**
   * API to get carbon subscription transactions
   */
  public getCarbonSubscriptionTransactions = async () => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.get<SubscriptionTransactionsDto>(`/subscription/${accountId}/transactions`, options);
      if (result?.data?.transactions) {
        return result.data?.transactions;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      if (ex.message === 'cancelled') {
        // do nothing
      }
      return [];
    }
  };

  /**
   * Change Card API
   */
  public changeCard = async (subscriptionId: string) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.post<any>(`/subscription/${accountId}/change-card`, { subscriptionId }, options);
      if (!result.data?.errorMessage) {
        return result.data?.clientSecret;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      return null;
    }
  };

  /**
   * Download subscription invoice
   */
  public downloadSubscriptionInvoice = async (invoiceId: string) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.get<any>(`/subscription/${accountId}/invoices?invoiceId=${invoiceId}`, options);
      if (!result.data?.errorMessage) {
        return result.data.url;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      return null;
    }
  };

  /**
   * Method to confirm change card after made
   */
  public confirmChangeCard = async (subscriptionId: string, identifier: string) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.put<any>(
        `/subscription/${accountId}/change-card?subscriptionId=${subscriptionId}&identifier=${identifier}`,
        null,
        options
      );
      if (!result.data?.errorMessage) {
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      return false;
    }
  };

  /** Method to try and make another payment */
  public makePayment = async (id: string) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        validateStatus: (status) => status === 400 || status === 200 || status === 204,
      };
      const result = await api.put<any>(`/subscription/${accountId}/transactions/${id}`, null, options);
      if (!result.data?.errorMessage) {
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      return false;
    }
  };

  /** Method to cancel subscription */
  public cancelSubscription = async (subscriptionId: string) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        validateStatus: (status) => status === 400 || status === 200 || status === 204,
      };
      const result = await api.put<any>(`/subscription/${accountId}/cancel`, { subscriptionId }, options);
      if (!result?.data?.errorMessage) {
        toast.success('Your subscription has been cancelled.');
        return true;
      } else {
        toast.error(
          'There was a problem cancelling your subscription, please reload the page and try again or contact support.'
        );
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      return false;
    }
  };

  /** Method to un subscription */
  public uncancelSubscription = async (subscriptionId: string) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
        validateStatus: (status) => status === 400 || status === 200 || status === 204,
      };
      const result = await api.put<any>(`/subscription/${accountId}/uncancel`, { subscriptionId }, options);
      if (!result?.data?.errorMessage) {
        toast.success('Great! Your subscription has been uncancelled.');
        return true;
      } else {
        toast.error(
          'There was a problem uncancelling your subscription, please reload the page and try again or contact support.'
        );
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      return false;
    }
  };

  /**
   * API to get view dashboards
   */
  private getDashboards = async () => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.get<GetDashboardsDto>(`/admin/dashboard/${accountId}`, options);
      if (result?.data?.dashboardViews) {
        return result.data.dashboardViews;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      if (ex.message === 'cancelled') {
        // do nothing
      } else {
        return [];
      }
    }
  };

  /**
   * API to get view dashboard by id
   */
  private getDashboard = async (dashboardId: string) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      //Check if there are any previous pending requests
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.get<DashboardDetailDto>(`/admin/dashboard/${accountId}/${dashboardId}`, options);
      if (!result?.data?.errorMessage) {
        return result.data;
      } else {
        toast.error(result.data.errorMessage);
        throw Error();
      }
    } catch (ex) {
      return null;
    }
  };

  /**
   * Upsert Dashboard API
   */
  public upsertDashboard = async (dashboardId: string, data: DashboardDetailDto) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.post<any>(`/admin/dashboard/${accountId}/${dashboardId}`, data, options);
      if (!result.data?.errorMessage) {
        return result.data;
      } else {
        throw Error();
      }
    } catch (ex) {
      toast.success(`Dashboard update failed. Please try again or contact support.`);
      console.log(ex);
      return null;
    }
  };

  /**
   * Upsert Dashboard API
   */
  public upsertCustomPage = async (dashboardId: string, imageType: number, customPage: number, data: any) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        'Content-Type': 'multipart/form-data',
        headers: { Authorization: `Bearer ${token}` },
      };
      let url = `/admin/dashboard/${accountId}/${dashboardId}/custompage?imagetype=${imageType}`;
      if (customPage) {
        url += `&custompage=${customPage}`;
      }
      const result = await api.post<any>(url, data, options);
      if (!result.data?.errorMessage) {
        return result.data;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      return null;
    }
  };

  /**
   * Delete Dashboard custom page API
   */
  public deleteCustomPage = async (dashboardId: string, customPage: string, imageType?: number) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        'Content-Type': 'multipart/form-data',
        headers: { Authorization: `Bearer ${token}` },
      };
      let url = `/admin/dashboard/${accountId}/${dashboardId}/custompage?`;
      if (customPage) {
        url += `custompage=${customPage}`;
      }
      if (imageType !== null) {
        url += `${customPage ? '&' : ''}imagetype=${imageType}`;
      }
      const result = await api.delete<any>(url, options);

      if (result && [200, 204].indexOf(result.status) > -1) {
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      return false;
    }
  };

  /**
   * Add Dashboard API
   */
  public addDashboard = async (props: DashboardCreateDto) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.put<any>(`/admin/dashboard/${accountId}`, props, options);
      if (result && [200, 204].indexOf(result.status) > -1) {
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      return false;
    }
  };

  /**
   * Delete Dashboard API
   */
  public deleteDashboard = async (dashboardId: string) => {
    try {
      const accountId = this.state.selectedAccount?.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let url = `/admin/dashboard/${accountId}/${dashboardId}`;
      const result = await api.delete<any>(url, options);
      if (result && [200, 204].indexOf(result.status) > -1) {
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      return false;
    }
  };

  /**
   * Get Preferences API
   */
  public getPreferences = async () => {
    try {
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.get<any>(`/profile/preferences`, options);
      if (result && [200, 204].indexOf(result.status) > -1) {
        return result.data.reports;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      return [];
    }
  };

  /**
   * setPreferences API
   */
  public setPreferences = async (reports: any) => {
    try {
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let url = `/profile/preferences`;
      const result = await api.put<any>(url, { reports: reports }, options);
      if (result && [200, 204].indexOf(result.status) > -1) {
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      return false;
    }
  };

  /**
   * Get account admin API
   */
  public getAccountAdmin = async () => {
    try {
      const accountId = this.state.selectedAccount.id;
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.get<AccountAdminDto>(`/admin/account/${accountId}`, options);
      if (result && [200, 204].indexOf(result.status) > -1) {
        return result.data;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      return null;
    }
  };

  /**
   * save account admin API
   */
  public saveAccountAdmin = async (data: SaveAccountAdminDto) => {
    try {
      const token = localStorage.getItem('token') as string;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const accountId = this.state.selectedAccount.id;
      let url = `/admin/account/${accountId}`;

      const result = await api.post<any>(url, data, options);
      if (result && [200, 204].indexOf(result.status) > -1) {
        const tmpSelectedAccount = { ...this.state.selectedAccount, label: data.organisationName };
        sessionStorage.setItem('selectedAccount', JSON.stringify(tmpSelectedAccount));
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      console.log(ex);
      return false;
    }
  };

  /**
   * Method to request analytics data for a single load
   * @param requestParams
   * @returns
   */
  private requestAnalyticsSingle = async (requestParams: AnalyticsRequestSingleDto) => {
    const token = localStorage.getItem('token');
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const { data } = await api.post<AnalyticsDto>('analytics/usage', requestParams, options);
      const updatedData = toCamel(data) as AnalyticsDto;
      if (updatedData.dimensions?.length > 0) {
        return updatedData.dimensions[0].utilities[0].consumption;
      } else {
        return [] as AnalyticsConsumptionData[];
      }
    } catch (ex) {
      console.log(ex);
      return [];
    }
  };

  /**
   * Method to manager right drawer
   */
  private manageRightDrawer = (open: boolean, type: string, id?: string, extraParams?: any) => {
    if (!open) {
      this.setState({ rightDrawer: { ...this.state.rightDrawer, open: false } });
      setTimeout(() => {
        this.setState({ rightDrawer: { open: false, type: null, id: null } });
      }, 250);
    } else {
      this.setState({ rightDrawer: { open, type, id, extraParams } });
    }
  };

  /**
   * Get the real dates we use based on the difference between two dates
   */
  private getRealDatesFromBrush = (start: Date, end: Date) => {
    const { selectedAreaType } = this.state;
    const isLoad = selectedAreaType === 'load';
    const diffDays = moment(end).diff(moment(start), 'days');
    let momentStart = moment(start).startOf('day');
    let momentEnd = moment(end).endOf('day');
    if (isLoad && diffDays < 6) {
      momentStart = momentStart.subtract(1, 'day');
      momentEnd = momentEnd.add(1, 'day');
    } else if (isLoad && diffDays <= 61) {
      momentStart = momentStart.subtract(1, 'day');
      momentEnd = momentEnd.add(1, 'day');
    } else if (diffDays <= 180) {
      momentStart = momentStart.subtract(1, 'week');
      momentEnd = momentEnd.add(1, 'week');
    } else if (diffDays < 335) {
      momentStart = momentStart.subtract(1, 'month');
      momentEnd = momentEnd.add(1, 'month');
    } else {
      momentStart = momentStart.subtract(1, 'month');
      momentEnd = momentEnd.add(1, 'month');
    }
    return {
      start: momentStart.format('YYYY-MM-DDTHH:mm:ss'),
      end: momentEnd.format('YYYY-MM-DDTHH:mm:ss'),
    };
  };

  /**
   * Get the actual interval used between two dates
   */
  private getIntervalFromDates = (start: Date, end: Date, isAmpsOrVolts?: boolean) => {
    const { selectedAreaType, chartUtilityFilter } = this.state;
    const isLoad = selectedAreaType === 'load';
    let interval = intervalObj.Days;
    const diffDays = moment(end).diff(moment(start), 'days');

    if (isLoad && diffDays < 6) {
      interval = intervalObj.Minutes;
    } else if (isLoad && diffDays <= 61) {
      interval = intervalObj.Hours;
    } else if (diffDays <= 180) {
      interval = intervalObj.Days;
    } else if (diffDays < 335) {
      interval = intervalObj.Weeks;
    } else {
      interval = intervalObj.Months;
    }
    const selectedAreas = this.getSelectedAreas();
    // override for current
    if (chartUtilityFilter === 'current' || chartUtilityFilter === 'volts') {
      interval = intervalObj.Minutes;
    } else {
      // check if only has current or volts
      if (selectedAreas.length === 1) {
        const subareas = selectedAreas.flatMap((x) => x.subareas);
        const hasVoltAmps = subareas.find((x) => voltAmpTypes.indexOf(x.unit) > -1);
        const hasKwH = subareas.find((x) => x.unit === 'kWh');
        if (!hasKwH && hasVoltAmps) {
          interval = intervalObj.Minutes;
        }
      } else if (this.state.chartView === 'Append Charts') {
        selectedAreas.forEach((area) => {
          const hasVoltAmps = area.subareas.find((x) => voltAmpTypes.indexOf(x.unit) > -1);
          if (area.subareas.length === 3 && hasVoltAmps) {
            interval = intervalObj.Minutes;
            return;
          }
        });
      }
    }
    const newinterval = getHighestIntervalFromLoads(selectedAreas);
    if (isLoad && newinterval < interval) {
      console.log('here', newinterval, selectedAreas);
      interval = newinterval;
    }
    return { interval: interval, intervalStr: intervalObjRev[interval] };
  };

  /**
   * Updates Brush in session storage
   */
  private refreshChartBrushMinAndMaxDate = () => {
    const { selectedAreaType } = this.state;
    let newPeriod = selectedAreaType === 'load' ? 'Last 7 Days' : 'Last 12 Months';
    if (selectedAreaType === 'load') {
      const interval = getLowestIntervalFromLoads(this.getSelectedAreas());
      if (interval < 4) {
        newPeriod = 'Last 12 Months';
      }
    }
    const range = dateRanges.find((x) => x.label === newPeriod);
    let chartMinDate = range.dates.startDate;
    let chartMaxDate = range.dates.endDate;
    if (getParameterByName('chartMinDate')) {
      chartMinDate = new Date(Number(getParameterByName('chartMinDate')));
      chartMaxDate = new Date(Number(getParameterByName('chartMaxDate')));
      const startStr = moment(chartMinDate).format('DD/MM/YYYY');
      const endStr = moment(chartMaxDate).format('DD/MM/YYYY');
      newPeriod = `Viewing: ${startStr} - ${endStr}`;
    }
    sessionStorage.setItem('chartMinDate', chartMinDate.getTime().toString());
    sessionStorage.setItem('chartMaxDate', chartMaxDate.getTime().toString());
    sessionStorage.setItem('chartPeriod', newPeriod);
    if (newPeriod !== this.state.chartPeriod) {
      this.setState({ chartPeriod: newPeriod });
    }
  };

  /**
   * Select area by id
   * Multiple optional
   * From popstate prevents pushing to history
   * Next tab optional to move sidebar to next tab
   * @param id
   * @param multiple
   * @param fromPopState
   * @param parentId
   */
  private selectAreaById = async (id?: string, multiple?: boolean, fromPopState?: boolean, parentId?: string) => {
    if (sessionStorage.getItem('reloadAccountMetadata')) {
      sessionStorage.removeItem('reloadAccountMetadata');
      setTimeout(() => {
        window.location.reload();
      }, 0);
      return;
    }
    const selectedAreas = this.getSelectedAreas() || [];
    // if (multiple) {
    //   const ids = selectedAreas.map((x) => x.id);
    //   const parentIds = selectedAreas.map((x) => x.parentIds[x.parentIds.length - 1]);
    //   ids.push(id);
    //   parentIds.push(parentId);
    //   this.recursiveSelectMultipleAreas(ids, parentIds, this.state.areas);
    // }
    const children = selectedAreas.flatMap((x) => x.subareas);
    const exists = children.find((x) => x.id === id);
    let area = (!id ? this.state.areas[0] : this.getAreaById(id, this.state.areas, parentId)) as AreaDto;
    if (!area) {
      return;
    }
    if (!parentId && exists) {
      area = exists;
    }

    if (this.isViewingOperations() && area.subareas.length > 0 && area.subareas[0].type === 'load') {
      const allNoMainIncomers = area.subareas.every((x) => !x.mainIncomer);
      if (allNoMainIncomers) {
        toast.info(
          `Sorry, this group has no main incomers selected, therefore the operational report has been disabled.`
        );
        return;
      }
    }

    this.autoSelectRelevantAreas(area);
    if (this.isViewingOperations() && multiple) {
      this.selectArea(area, false, fromPopState);
    } else {
      this.selectArea(area, multiple, fromPopState);
    }
  };

  /**
   * solar - force select the main incomer
   * production - force select all main incomers
   */
  private autoSelectRelevantAreas = async (area: AreaDto) => {
    if (area.type === 'load') {
      const parentId = area.parentIds[area.parentIds.length - 1];
      const grandParentId = area.parentIds[area.parentIds.length - 2];
      const parent = this.getAreaById(parentId, this.state.areas, grandParentId);
      if (parent) {
        if (area.name.toLowerCase().indexOf('solar') > -1) {
          const mainIncElec = parent.subareas.find((x) => x.mainIncomer && x.utility === 'electricity');
          if (mainIncElec && mainIncElec.id !== area.id) {
            setTimeout(() => {
              this.selectArea(mainIncElec, true, false);
            }, 0);
          }
        } else if (area.utility === 'production') {
          const mainIncomers = parent.subareas.filter((x) => x.mainIncomer);
          mainIncomers.forEach((x, i) => {
            setTimeout(() => {
              if (!x.selected) {
                this.selectArea(x, true, false);
              }
            }, 50 * i);
          });
        }
      }
    }
  };

  /**
   * Checks maximum amount of selections
   * Selects appropriate area based on params
   * @param area
   * @param multiple
   * @param fromPopState
   */
  private selectArea = async (area: AreaDto, multiple?: boolean, fromPopState?: boolean) => {
    let { areas, selectedAreaType } = this.state;

    const selectedAreas = this.getSelectedAreas();
    const isMeter = selectedAreaType === 'load';
    const areaIsMeter = area?.type === 'load';
    let maxSelections = 5;
    // refresh
    const changeToFromLoad = areaIsMeter !== isMeter || (getParameterByName('startDate') ? true : false);

    if (isMeter && areaIsMeter) {
      selectedAreaType = area.type;
      maxSelections = 10;
    }

    if (multiple) {
      if (area && !area.selected && selectedAreaType === area.type && selectedAreas.length === maxSelections) {
        toast.info(`Sorry, you can only have up to ${maxSelections} areas selected`);
        return;
      }
      if (!area || selectedAreaType !== area.type) {
        areas.forEach(this.unselectAreas);
      }
    } else {
      areas.forEach(this.unselectAreas);
    }
    if (area) {
      area.recent = true;
      area.selected = !area.selected;
      this.addRecentAreaIdToLocalStorage(area.id);
    } else {
      areas.forEach(this.unselectAreas);
    }

    const { groups } = this.getAreasByType();
    const types = [];
    if (groups.length > 0) {
      types.push('group');
    }
    types.push('load');

    let anAreaHasOnlyVoltsOrAmps = false;
    selectedAreas.forEach((area) => {
      const hasVoltAmps = area.subareas.find((x) => voltAmpTypes.indexOf(x.unit) > -1);
      if (area.subareas.length === 3 && hasVoltAmps) {
        anAreaHasOnlyVoltsOrAmps = true;
        return;
      }
    });

    // set loading true
    this.setState({
      selectedAreaType: area.type,
      loadingChartData: true,
      loadingTableData: true,
      loadingActivities: true,
      loadingOperations: true,
      loadingOperationsWorstPf: true,
      chartView: anAreaHasOnlyVoltsOrAmps
        ? 'Append Charts'
        : changeToFromLoad
        ? 'Overlay Charts'
        : this.state.chartView,
    });

    const updatedSelectedAreas = this.getSelectedAreas();
    const newUrl = updatedSelectedAreas.map((x) => x.id).join('|');

    if (fromPopState) {
      // do nothing
    } else {
      const selectedAreaParentIds = updatedSelectedAreas.map((x) => {
        return x.parentIds[x.parentIds.length - 1];
      });

      if (newUrl && area.type !== 'dashboard') {
        let url = `/app/detail?area=${newUrl}`;
        if (this.isViewingOperations() && area.type !== 'load') {
          url = `/app/operations/detail?area=${newUrl}`;
        } else if (this.isViewingActivities()) {
          url = `/app/activities/detail?area=${newUrl}`;
        }
        url += `&parent=${selectedAreaParentIds.join('|')}`;
        this.props.history.push(url);
      } else if (this.isViewingOperations()) {
        this.props.history.push(`/app/operations`);
      } else if (this.isViewingActivities()) {
        this.props.history.push(`/app/activities`);
      } else {
        this.props.history.push(`/app/dashboard`);
      }
    }

    setTimeout(async () => {
      this.clearAreasData();
      if (changeToFromLoad || updatedSelectedAreas.find((x) => x.utility === 'production')) {
        this.refreshChartBrushMinAndMaxDate();
      }
      sessionStorage.setItem('selectedAreaType', area.type);
      if (this.isViewingOperations() && area.type !== 'load') {
        await this.loadAllOperationDashboardData();
      } else if (this.isViewingActivities()) {
        // do nothing, handled internally
        this.setState({ loadingActivities: false });
      } else {
        await this.loadChartAndTableDataOntoAreas(changeToFromLoad);
      }
    }, 0);
  };

  /**
   * Converts an area and it's dimensions into utility dto for chart and table user
   * @param area
   * @param dimensionUtilities
   */
  private convertAreaAndDimesionUtilityToUtilities = (area: AreaDto, dimensionUtilities: DimensionsUtilityDto[]) => {
    const elecUnits = ['kWh', 'kVAh', 'kVa'];

    const gasUnits = ['Gas kWh', 'GasNorm m3', 'GasRaw m3'];

    const waterUnits = ['Water m3'];
    const otherElecUnits = [
      'L1A',
      'L1A Max',
      'L2A',
      'L2A Max',
      'L3A',
      'L3A Max',
      'L1V',
      'L2V',
      'L3V',
      'VoltsA',
      'VoltsB',
      'VoltsC',
      'Power Factor',
      'PUE',
    ];
    // sort elec, gas, water
    const unitIdx = [].concat(elecUnits, gasUnits, waterUnits, otherElecUnits);
    dimensionUtilities.sort((a, b) => {
      return unitIdx.indexOf(a.unit) > unitIdx.indexOf(b.unit) ? 1 : -1;
    });

    let firstDataIdx = null as number;
    dimensionUtilities.forEach((util) => {
      let newIdx = util.consumption.findIndex((cons) => cons.usage !== null && cons.usage !== undefined);
      if (newIdx > -1) {
        if (firstDataIdx === null || newIdx < firstDataIdx) {
          firstDataIdx = newIdx;
        }
      }
    });
    if (!firstDataIdx) {
      firstDataIdx = 0;
    }

    const utilities = dimensionUtilities
      .filter((x) => {
        if (x.type === utilityObjRev.gas) {
          return gasUnits.indexOf(x.unit) > -1;
        } else if (x.type === utilityObjRev.electricity) {
          return elecUnits.indexOf(x.unit) > -1 || otherElecUnits.indexOf(x.unit) > -1;
        } else if (x.type === utilityObjRev.water) {
          return waterUnits.indexOf(x.unit) > -1;
        } else {
          return true;
        }
      })
      .map((util) => {
        let type = 'electricity';
        let unit = util.unit || 'unknown';
        if (unit.toLowerCase().indexOf('gas') > -1) {
          type = 'gas';
        } else if (unit.toLowerCase().indexOf('water') > -1) {
          type = 'water';
        } else {
          type = utilityObj[util.type];
        }
        const output = { id: '', name: unit, type: type, label: unit, data: [] } as UtilityDto;
        if (area.utility === 'electricity') {
          if (unit.indexOf('L1A') > -1 || unit.indexOf('L2A') > -1 || unit.indexOf('L3A') > -1) {
            output.name = `phase${unit.replace('L', '').replace('A', '')}`;
            output.label = 'amps';
          } else if (unit.indexOf('L1V') > -1 || unit.indexOf('L2V') > -1 || unit.indexOf('L3V') > -1) {
            output.name = `phase${util.unit.replace('L', '').replace('V', '')}`;
            output.label = 'volts';
          } else if (unit.indexOf('VoltsA') > -1 || unit.indexOf('VoltsB') > -1 || unit.indexOf('VoltsC') > -1) {
            output.name = `phase${unit.replace('Volts', '').replace('A', '1').replace('B', '2').replace('C', '3')}`;
            output.label = 'volts';
          } else {
            output.name = `${area.utility} ${unit.replace('Power Factor', 'pf')}`;
            output.label = output.name;
          }
        } else if (area.utility === 'gas') {
          output.name = `${unit.replace('GasRaw', 'Gas Raw').replace('GasNorm', 'Gas Norm')}`;
          output.label = output.name;
        } else if (area.utility === 'water') {
          if (util.type === 10) {
            output.name = 'Waste water m3';
            output.label = 'Waste water m3';
          }
        }
        output.name = output.name.replace('Reduction Target', '');
        output.label = output.label.replace('Reduction Target', '');

        output.data = util.consumption.slice(firstDataIdx, util.consumption.length).map((cons) => {
          return {
            x: moment.utc(cons.event).valueOf(),
            y: cons.usage !== null && cons.usage !== undefined ? Number(cons.usage.toFixed(2)) : null,
          };
        });
        output.label = output.label.replace('raw', '');
        output.name = output.name.replace('raw', '');
        return output;
      });
    return utilities; //.filter((x) => x.data.length > 0);
  };

  /**
   * Convert nodes (api) to areas (ui), include parent names for breadcrumbs
   * @param nodes
   * @param parentNames
   */
  private convertNodesToAreas = (
    nodes: NodeDto[] | LoadDto[] | ChannelDto[],
    parentNames: string[],
    parentIds: string[],
    recentAreaIds: string[]
  ) => {
    const accountId = getParameterByName('accountId') || '';
    const selectedAreaParam = getParameterByName('area') || '';
    const selectedParentParentParam = getParameterByName('parent') || '';
    let areas = nodes.map((node) => {
      const newNames = node.label === 'Dashboard' ? parentNames : parentNames.concat(node.label);
      const newIds = node.label === 'Dashboard' ? parentIds : parentIds.concat(node.id);
      let children = [];
      if (node.loads?.length > 0) {
        children = node.loads || [];
      } else if (node.channels?.length > 0) {
        children = node.channels || [];
      } else {
        children = node.nodes || [];
      }

      children = children.filter(
        (x) => x.label?.toLowerCase().indexOf('saving') === -1 && x.label?.toLowerCase().indexOf('variable') === -1
      );

      let selected = false;
      if (selectedAreaParam) {
        const selectedAreaIds = selectedAreaParam.split('|');
        const selectedAreaParentIds = selectedParentParentParam.split('|');
        const selectedIdx = selectedAreaIds.indexOf(node.id);
        if (selectedIdx > -1) {
          const parentId = selectedAreaParentIds[selectedIdx];
          if (parentIds.indexOf(parentId) > -1 || accountId) {
            selected = true;
          }
        }
      }

      const area = {
        id: node.id,
        name: node.label,
        type: typeObj[node?.type],
        city: node?.city || '',
        mainIncomer: node?.mainIncomer ? true : false,
        unit: node?.unit,
        utility: node.utility ? utilityObj[node.utility] : utilityObj[0],
        favourite: false,
        recent: recentAreaIds.indexOf(node.id) > -1,
        selected: selected,
        parentNames: parentNames,
        parentIds: parentIds,
        subareas: children.length > 0 ? this.convertNodesToAreas(children, newNames, newIds, recentAreaIds) : [],
        utilities: [],
        brushUtilities: [],
        tableUtilities: [],
        index: node.index,
        interval: node.interval,
        nature: node.nature,
      } as AreaDto;
      area.subareas.sort((a, b) => (a.index > b.index ? 1 : a.index < b.index ? -1 : 0));
      return area;
    }) as AreaDto[];
    areas = areas.filter(
      (x) =>
        x.name?.toLowerCase().indexOf('saving') === -1 &&
        x.name?.toLowerCase().indexOf('variable') === -1 &&
        x.interval !== 'LooseFrequency'
    );

    return areas.sort((a, b) => (a.index > b.index ? 1 : a.index < b.index ? -1 : 0));
  };

  /** Set Chart Period */
  private setChartPeriod = (period: string) => {
    if (period !== this.state.chartPeriod) {
      sessionStorage.setItem('chartPeriod', period);
      this.setState({ chartPeriod: period });
    }
  };

  /** Set Chart View (overlay, append) */
  private setChartView = (chartView: string) => {
    if (chartView !== this.state.chartView) {
      sessionStorage.setItem('chartView', chartView);
      this.setState({ chartView: chartView });
    }
  };

  /**
   * Toggles compare mode in the UI
   * @param enabled
   */
  private setComparingModeEnabled = (enabled: boolean) => {
    if (enabled !== this.state.comparingModeEnabled) {
      this.setState({ comparingModeEnabled: enabled });
    }
  };

  /**
   * Window back forward buttons
   */
  private handlePopState = () => {
    clearTimeout(this.popStateTimeout);
    this.popStateTimeout = setTimeout(() => {
      const areaId = getParameterByName('area');
      const parentId = getParameterByName('parent');
      if (areaId) {
        const multipleIds = areaId ? areaId.split('|') : [];
        const multipleParentIds = parentId ? parentId.split('|') : [];
        if (multipleIds.length > 1) {
          const firstId = multipleIds.splice(0, 1)[0];
          const firstParentId = multipleParentIds.splice(0, 1)[0];
          this.recursiveSelectMultipleAreas(multipleIds, multipleParentIds, this.state.areas);
          setTimeout(() => {
            this.selectAreaById(firstId, true, true, firstParentId);
          }, 0);
        } else {
          this.selectAreaById(areaId, false, true, parentId);
        }
      }
    }, 250);
  };

  /**
   * Utility method to select based on window query string
   * @param ids
   * @param parentIds
   * @param areas
   */
  private recursiveSelectMultipleAreas = (ids: string[], parentIds: string[], areas: AreaDto[]) => {
    const innerFn = (areas) => {
      areas.forEach((x) => {
        x.selected = false;
        const idxId = ids.indexOf(x.id);
        if (idxId > -1) {
          const parentIdToMatch = parentIds[idxId];
          if (parentIdToMatch) {
            if (x.parentIds.indexOf(parentIdToMatch) > -1) {
              x.selected = true;
            }
          }
        }
        innerFn(x.subareas);
      });
    };
    innerFn(areas);
  };

  /**
   * Update utility filter (radio for amps/current/water/gas)
   * @param name
   */
  private updateChartUtilityFilter = async (name: string) => {
    sessionStorage.setItem('chartUtilityFilter', name);
    const wasCurrentOrVolt = this.state.chartUtilityFilter === 'current' || this.state.chartUtilityFilter === 'volts';
    const isCurrentOrVolt = name === 'current' || name === 'volts';
    if (wasCurrentOrVolt || isCurrentOrVolt) {
      this.setState({ chartUtilityFilter: name, loadingChartData: true });
      setTimeout(async () => {
        await this.loadChartAndTableDataOntoAreas(false, true);
      }, 0);
    } else {
      this.setState({ chartUtilityFilter: name });
    }
  };

  /**
   * Unselect all areas
   */
  private unselectAreas = () => {
    const unselect = (area: AreaDto) => {
      area.selected = false;
      area.subareas.forEach(unselect);
    };
    this.state.areas.forEach(unselect);
    setTimeout(() => {
      this.clearAreasData();
    }, 0);
  };

  /**
   * Get area by id. Recursive through all areas.
   * @param id
   * @param areas
   * @param parentId
   */
  private getAreaById = (id: string, areas: AreaDto[], parentId?: string) => {
    if (areas.length === 0) {
      return null;
    }
    const foundArea = areas.find((x) => {
      if (x.id === id) {
        if (parentId) {
          return x.parentIds[x.parentIds.length - 1] === parentId;
        } else {
          return true;
        }
      }
      return false;
    });
    if (foundArea) {
      return foundArea;
    } else {
      return this.getAreaById(
        id,
        areas.flatMap((x) => x.subareas),
        parentId
      );
    }
  };

  /**
   * Ensures we don't hog memory
   */
  private clearAreasData = () => {
    const updateAreasData = (areas: AreaDto[]) => {
      areas.forEach((x, i) => {
        x.utilities = [];
        x.tableUtilities = [];
        x.brushUtilities = [];
      });
      const subareas = areas.flatMap((x) => x.subareas);
      if (subareas.length > 0) {
        updateAreasData(areas.flatMap((x) => x.subareas));
      }
      return areas;
    };
    const areas = updateAreasData(this.state.areas);
    return areas;
  };

  /**
   * Method to determine default chart filter
   */
  private getDefaultChartFilter = () => {
    const selectedAreas = this.getSelectedAreas();
    const subareas = selectedAreas.flatMap((x) => x.subareas);
    const hasEnergy = subareas.filter((x) => voltAmpTypes.indexOf(x.unit) === -1).length > 0;
    const hasCurrent = subareas.filter((x) => ampTypes.indexOf(x.unit) > -1).length > 0;
    const hasVolts = subareas.filter((x) => voltTypes.indexOf(x.unit) === -1).length === 0;
    const hasProduction = selectedAreas.filter((x) => x.utility === 'production').length > 0;
    let newFilter = this.state.chartUtilityFilter;
    if (selectedAreas[0]?.type === 'load') {
      const availableTypes = [];
      if (hasEnergy && selectedAreas.length === 1) {
        availableTypes.push('energy');
      }
      if (hasCurrent && selectedAreas.length === 1) {
        availableTypes.push('current');
      }
      if (hasVolts && selectedAreas.length === 1) {
        availableTypes.push('volts');
      }
      if (hasProduction) {
        availableTypes.push('EUI');
        availableTypes.push('RAW');
      }
      if (availableTypes.length > 0) {
        newFilter = availableTypes[0];
      } else {
        newFilter = 'all';
      }
    } else {
      newFilter = 'all';
    }
    return newFilter;
  };

  /**
   * Method to format selected areas chart data into format chart library is expecting
   */
  private getFormattedChartData = (processBrushData?: boolean) => {
    const { selectedAreaType, areas } = this.state;
    const isMeter = selectedAreaType === 'load';
    let selectedAreas = this.getSelectedAreas();
    if (!selectedAreas || !areas || areas.length === 0) {
      return [];
    }

    if (selectedAreas.length === 0 && areas.length > 0) {
      selectedAreas = [areas[0]];
    }

    let chartData = selectedAreas.flatMap((area) => {
      let tmpUtilities = [].concat(processBrushData ? area.brushUtilities || [] : area.utilities || []);
      if (isMeter && selectedAreas.length === 1) {
        const kwh = tmpUtilities.find((x) => x.name.toLowerCase() === 'electricity kwh');
        const kvah = tmpUtilities.find((x) => x.name.toLowerCase() === 'electricity kvah');
        const hasPf = tmpUtilities.find((x) => x.name.toLowerCase().indexOf('pf') > -1);
        if (kwh && kvah && !hasPf && area.mainIncomer) {
          tmpUtilities.push({
            id: area.id,
            label: 'electricity pf',
            name: 'electricity pf',
            type: 'electricity',
            data: kwh.data.map((item, idx) => {
              let num = null;
              const match = kvah.data.find((record) => record.x === item.x);
              if (item && item.y && match && match.y) {
                num = item?.y / match?.y;
              }
              if (isNaN(num) || !isFinite(num) || num === null) {
                num = null;
              } else {
                num = parseFloat(num.toFixed(2));
              }
              return { x: item.x, y: num };
            }),
          });
        }
      }

      const newUtils = tmpUtilities.map((util) => {
        let type = 'spline';
        let name =
          selectedAreas.length === 1
            ? `${util.name.replace('electricity', 'Electricity')}`
            : `${area.name} ${util.name.replace(area.name, ' ').replace('electricity', 'Electricity')} `;
        return {
          areaId: area.id,
          areaName: area.name,
          type: type,
          utilityType: util.type,
          utilityLabel: util.label,
          name: name.toLowerCase() === 'kwh' ? util.type + ' kWh' : name,
          data: util.data.sort((a, b) => (a.x > b.x ? 1 : -1)),
          mainIncomer: area.mainIncomer,
        };
      });
      return newUtils;
    }) as ChartDto[];

    const hasElec = chartData.find((x) => x.utilityType.toLowerCase() === 'electricity');
    const hasWater = chartData.find((x) => x.utilityType.toLowerCase() === 'water');
    const hasGas = chartData.find((x) => x.utilityType.toLowerCase() === 'gas');
    if (hasGas && (hasElec || hasWater)) {
      chartData = chartData.filter((x) => x.utilityLabel !== 'Gas Raw m3');
    }
    chartData.sort((a, b) => {
      return a.utilityType < b.utilityType ? -1 : a.utilityType > b.utilityType ? 1 : 0;
    });
    return chartData;
  };

  /**
   * Favourite area
   * @param area
   */
  private favouriteArea = (area: AreaDto) => {
    area.favourite = !area.favourite;

    setTimeout(() => {
      this.clearAreasData();
    }, 0);
  };

  /**
   * Recursive search and returns all selected areas
   */
  private getSelectedAreas = () => {
    const selectedAreas = [] as AreaDto[];
    const filterSearch = (areas: AreaDto[]) => {
      areas?.forEach((x) => {
        if (x.selected && !selectedAreas.find((y) => y.id === x.id)) {
          selectedAreas.push(x);
        }
      });
      const subareas = areas?.flatMap((x) => x.subareas) || [];
      if (subareas.length > 0) {
        filterSearch(subareas);
      }
    };

    filterSearch(this.state.areas || []);
    if (selectedAreas.length === 0) {
      return this.state.areas && this.state.areas.length > 0 ? [this.state.areas[0]] : [];
    } else {
      return selectedAreas || [];
    }
  };

  /**
   * Returns a break down of areas by type
   */
  private getAreasByType = (propAreas?: AreaDto[]) => {
    const areas = propAreas || this.state.areas || [];
    const account = areas[0];
    const groups = [] as AreaDto[];
    const loads = [] as AreaDto[];
    const innerFunc = (theAreas: AreaDto[]) => {
      theAreas.forEach((x) => {
        if (x.type === 'group') {
          groups.push(x);
        } else if (x.type === 'load') {
          loads.push(x);
        }
      });
      const subareas = theAreas.flatMap((x) => x.subareas);
      if (subareas.length > 0) {
        innerFunc(theAreas.flatMap((x) => x.subareas));
      }
    };
    if (account && account.subareas && account.subareas.length > 0) {
      innerFunc(account.subareas);
    }
    return {
      account,
      groups,
      loads,
    };
  };

  /**
   * Notify logrocket
   */
  private notifyLogRocket = (user: UserDto, account: AccountDto) => {
    // log rocket session
    if (window.location.hostname === 'hub.esphq.com') {
      LogRocket.identify(user.profile.id, {
        name: user.profile.particulars.displayName,
        email: user.profile.particulars.emailAddress,
        // Add your own custom user variables here, ie:
        espUser: user.profile.espUser,
        account: account.label,
      });
    }
  };

  private notifyHotjar = (user: UserDto) => {
    // @ts-ignore
    if (window.location.hostname === 'hub.esphq.com' && window.hj) {
      // @ts-ignore
      window.hj('identify', user.profile.id);
    }
  };

  /**
   * Add recent area to local storage
   */
  private addRecentAreaIdToLocalStorage = (areaId: string) => {
    const recents = JSON.parse(localStorage.getItem('recentSearches')) || [];
    if (recents.indexOf(areaId) === -1) {
      recents.push(areaId);
    }
    localStorage.setItem('recentSearches', JSON.stringify(recents));
  };

  /**
   * Get recent search area ids
   */
  private getRecentAreaIds = () => {
    const recents = JSON.parse(localStorage.getItem('recentSearches')) || ([] as string[]);
    return recents;
  };

  /**
   * Utility method to determine if viewing operations
   */
  private isViewingCarbon = () => {
    if (window.location.href.indexOf('/app/carbon') > -1) {
      return true;
    }
    return false;
  };

  /**
   * Utility method to determine if viewing operations
   */
  private isViewingOperations = () => {
    if (window.location.href.indexOf('/operations') > -1) {
      return true;
    }
    return false;
  };

  /**
   * Utility method to determine if viewing activities
   */
  private isViewingActivities = () => {
    if (window.location.href.indexOf('/activities') > -1) {
      return true;
    }
    return false;
  };

  /**
   * Utility method to determine if viewing Account Settings
   */
  private isViewingAccountSettings = () => {
    if (window.location.href.indexOf('/account-settings') > -1) {
      return true;
    }
    return false;
  };

  /**
   * Utility function to get a static list of units and utilies based on selected areas loads and channels
   */
  private getUtilitiesAndUnitsFromSelectedAreas = (
    areas: AreaDto[],
    selectedAreaType: number,
    comparing: boolean,
    utililitiesUnits: UtilityUnit[]
  ) => {
    const firstArea = areas[0];
    const children = areas.flatMap((x) => x.subareas);
    if (firstArea.subareas.length > 0 && (firstArea?.type === 'dashboard' || firstArea?.type === 'group')) {
      return this.getUtilitiesAndUnitsFromSelectedAreas(children, selectedAreaType, comparing, utililitiesUnits);
    } else {
      areas.forEach((load) => {
        load.subareas.forEach((channel) => {
          if (selectedAreaType === typeObjRev.load) {
            utililitiesUnits.push({
              utility: utilityObjRev[load.utility],
              unit: channel.unit,
              id: channel.id,
              nature: channel.nature,
            });
          } else {
            const match = utililitiesUnits.find(
              (x) => x.utility === utilityObjRev[load.utility] && x.unit === channel.unit
            );
            if (!match) {
              utililitiesUnits.push({
                utility: utilityObjRev[load.utility],
                unit: channel.unit,
                nature: channel.nature,
              });
            }
          }
        });
      });

      if (selectedAreaType !== typeObjRev.load) {
        utililitiesUnits = utililitiesUnits.filter((x) => {
          if (x.utility === utilityObjRev.electricity) {
            return x.unit === 'kWh';
          } else if (x.utility === utilityObjRev.water) {
            return x.unit === 'Water m3';
          } else if (x.utility === utilityObjRev.gas) {
            return x.unit === 'Gas kWh';
          } else {
            return false;
          }
        });
      }

      return utililitiesUnits.filter((x) => x.unit !== 'Power Factor');
    }
  };
}
// @ts-ignore
export default withRouter(AppProvider);
