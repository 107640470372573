import React from 'react';
import { SimpleMenuSurface } from '@rmwc/menu';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';
// import { TabBar, Tab } from '@rmwc/tabs';
import { FixedSizeList } from 'react-window';

import { ReactComponent as GroupIcon } from 'assets/group.svg';
import { ReactComponent as MeterIcon } from 'assets/meter.svg';
import { ReactComponent as ElectricityIcon } from 'assets/electricity.svg';
import { ReactComponent as GasIcon } from 'assets/gas.svg';
import { ReactComponent as WaterIcon } from 'assets/water.svg';
import { ReactComponent as HeatIcon } from 'assets/heat.svg';
import { ReactComponent as ProductionIcon } from 'assets/production.svg';
import { ReactComponent as SolarIcon } from 'assets/solar.svg';
import { ReactComponent as SteamIcon } from 'assets/steam.svg';
import { ReactComponent as EnviromentalIcon } from 'assets/enviromental.svg';

import ReactTags from 'react-tag-autocomplete';

import { AreaDto, handleSearch, SearchResult, useAppState } from 'utilities';
import styles from './Search.module.scss';

export interface SearchComponentProps {
  areas: AreaDto[];
  selectedAreaType: string;
  setSelectedAreaType: (areaType: string) => void;
}

export const SearchComponent = () => {
  const viewingOperations = window.location.href.indexOf('/operations') > -1;
  const viewingActivities = window.location.href.indexOf('/activities') > -1;

  const {
    areas,
    selectedAreaType,
    comparingModeEnabled,
    selectAreaById,
    // favouriteArea,
    getSelectedAreas,
    // getAreasByType,
    setComparingModeEnabled,
  } = useAppState();
  const [mobileSearchOpen, setMobileSearchOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [searchMenuOpen, setSearchMenuOpen] = React.useState(false);
  // const [searchTab, setSearchTab] = React.useState(0);
  const [filteredResults, setFilteredResults] = React.useState([] as SearchResult[]);
  // const [, updateState] = React.useState();
  const reactTags = React.useRef(null);
  const selectedAreas = getSelectedAreas().filter((x) => x.type !== 'dashboard');

  const searchWidth = window.innerWidth < 841 ? window.innerWidth - 20 : window.innerWidth < 1279 ? 570 : 442;
  const searchHeight = window.innerWidth < 841 ? window.innerHeight - 160 : 640;
  // @ts-ignore
  // const forceUpdate = React.useCallback(() => updateState({}), []);

  const handleWindowClick = (e: Event) => {
    try {
      const eleA = document.getElementById('searchContainer') as HTMLElement;
      const eleB = document.getElementById('comparingModeLink') as HTMLElement;
      const target = e?.target as Node;
      // @ts-ignore
      if (target && target.className === 'react-tags__search-input') {
        return;
      }
      if (eleA) {
        if (!eleA.contains(target)) {
          if (!eleB || (eleB && !eleB.contains(target))) {
            setSearchMenuOpen(false);
            setComparingModeEnabled(false);
          }
        }
      }
    } catch (e) {}
  };

  React.useEffect(() => {
    let results = handleSearch(search, areas?.flatMap((x) => x.subareas) || [], [], '', null);
    if (comparingModeEnabled) {
      results = results.filter((x) => x.area.type === selectedAreaType);
    }
    if (viewingOperations || viewingActivities) {
      results = results.filter((x) => x.area.type !== 'load');
    }
    setFilteredResults(results);
    // eslint-disable-next-line
  }, [search, areas, comparingModeEnabled]);

  React.useEffect(() => {
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  });

  const handleSelectArea = (area: AreaDto, multiple: boolean) => {
    if (viewingOperations && area.type === 'load') {
      return false;
    }
    if (!multiple && !comparingModeEnabled) {
      document.getElementById('logo').focus();
      setSearchMenuOpen(false);
    }
    setTimeout(() => {
      selectAreaById(area?.id, multiple || comparingModeEnabled, false, area.parentIds[area.parentIds.length - 1]);
    }, 0);
  };

  // const handleFavouriteArea = (area: AreaDto, index: number) => {
  //   if (viewingOperations && area.type === 'load') {
  //     return false;
  //   }
  //   favouriteArea(area);
  //   forceUpdate();
  // };

  const renderSearchRow = ({ index, style }) => (
    <div style={style}>
      <SearchItem
        onClick={(multiple) => {
          handleSelectArea(filteredResults[index].area, multiple);
        }}
        area={filteredResults[index].area}
        text={filteredResults[index].jsx}
        multipleAreas={selectedAreas.length > 1}
      />
    </div>
  );

  return (
    <div
      id="searchContainer"
      className={`${styles.searchContainer}  ${selectedAreas.length > 0 ? 'active' : null} ${
        mobileSearchOpen ? styles.mobileSearchOpen : null
      } ${viewingOperations ? styles.viewingOperations : null}`}>
      <a
        href="#"
        className={styles.mobileSearchButton}
        onClick={(e) => {
          e.preventDefault();
          setMobileSearchOpen(!mobileSearchOpen);
        }}>
        <Icon icon={'search'} className={styles.mobileSearchIcon} />
      </a>

      <Icon icon={'search'} className={styles.searchIcon} />
      <ReactTags
        ref={reactTags}
        tags={selectedAreas || []}
        suggestions={[]}
        placeholderText="Search..."
        autoResize={false}
        onInput={(query) => {
          setSearch(query);
        }}
        onDelete={(index) => {
          if (selectedAreas.length === 1) {
            handleSelectArea(null, false);
          } else {
            handleSelectArea(selectedAreas[index], true);
          }
        }}
        onAddition={(item) => {
          // do nothing
        }}
        onFocus={() => {
          setSearchMenuOpen(true);
        }}
      />
      <SimpleMenuSurface
        rootProps={{ className: styles.searchWrapper }}
        anchorCorner="bottomLeft"
        handle={
          <a id="searchHiddenMenuLink" href="#" onClick={(e) => e.preventDefault()}>
            <span style={{ display: 'none' }}>&nbsp;</span>
          </a>
        }
        open={searchMenuOpen}>
        {/* <TabBar
          activeTabIndex={searchTab}
          onActivate={(evt) => setSearchTab(evt.detail.index)}
          className={`${styles.navigationTabs} alternate`}>
          <Tab className={styles.tab}>All</Tab>
          <Tab className={styles.tab}>Recent Searches</Tab>
        </TabBar> */}

        <button
          id="closeSearchLink"
          className={styles.closeMenuIcon}
          onClick={() => {
            document.getElementById('logo').focus();
            setSearchMenuOpen(false);
            setSearch('');
            // @ts-ignore
            reactTags.current.clearInput();
          }}>
          <Icon icon="close" />
        </button>
        {/* {!comparingModeEnabled && search.length === 0 ? (
          <NavigationBlocks
            searchTab={searchTab}
            areas={areas}
            selectedAreas={selectedAreas}
            handleSelectArea={handleSelectArea}
            handleFavouriteArea={handleFavouriteArea}
            getAreasByType={getAreasByType}
          />
        ) : ( */}
        <div className={styles.searchResult}>
          {filteredResults.length === 0 && <Typography use="body1">No results found...</Typography>}
          {!search && filteredResults.length > 0 ? (
            <Typography use="body1">{filteredResults.length} results available...</Typography>
          ) : (
            <Typography use="body1">{filteredResults.length} results found...</Typography>
          )}

          <FixedSizeList height={searchHeight} width={searchWidth} itemSize={40} itemCount={filteredResults.length}>
            {renderSearchRow}
          </FixedSizeList>
        </div>
        {/* )} */}
      </SimpleMenuSurface>
    </div>
  );
};

export const NavigationBlocks = ({
  searchTab,
  areas,
  handleSelectArea,
  handleFavouriteArea,
  selectedAreas,
  getAreasByType,
}) => {
  const { groups, loads } = getAreasByType();

  const viewingOperations = window.location.href.indexOf('/operations') > -1;
  const viewingActivities = window.location.href.indexOf('/activities') > -1;

  return (
    <>
      <div
        className={`${styles.navigationBlocks} ${
          viewingOperations || viewingActivities ? styles.viewingOperations : ''
        }`}>
        {groups.length > 0 && (
          <NavigationBlock
            header={'Groups'}
            activeTab={searchTab}
            areas={groups}
            onClick={handleSelectArea}
            onFavourite={handleFavouriteArea}
            CustomIcon={GroupIcon}
            selectedAreas={selectedAreas}
          />
        )}
        {groups.length === 0 && loads.length > 0 && (
          <NavigationBlock
            header={'Loads'}
            activeTab={searchTab}
            areas={loads}
            onClick={handleSelectArea}
            onFavourite={handleFavouriteArea}
            CustomIcon={MeterIcon}
            selectedAreas={selectedAreas}
          />
        )}
      </div>
    </>
  );
};

export const NavigationBlock = ({ header, areas, activeTab, CustomIcon, onClick, onFavourite, selectedAreas }) => {
  // const filteredAreas = areas.filter((x) => (activeTab === 1 ? x.favourite : activeTab === 2 ? x.recent : true));
  const filteredAreas = areas.filter((x) => (activeTab === 1 ? x.recent : true));
  if (filteredAreas.length === 0) {
    return null;
  }
  return (
    <div className={styles.areas}>
      <div className={styles.header}>
        <CustomIcon /> <Typography use="body1">{header}</Typography>
      </div>
      {filteredAreas.map((x, i) => (
        <div
          key={`${x.name}${i}`}
          className={`${styles.area} ${x.selected ? styles.active : ''} ${x.favourite ? styles.favourited : ''}`}>
          <a
            id={`selectAreaLink-${x.name}-${i}`}
            href="#"
            title={x.name}
            onClick={(e) => {
              e.preventDefault();
              onClick(x, false);
            }}>
            <Typography use="body2">{x.name}</Typography>
          </a>
          <button
            className={`${styles.selectButton}  ${x.selected ? styles.active : null}`}
            onClick={() => {
              onClick(x, true);
            }}>
            <Icon icon={x.selected ? (selectedAreas.length === 1 ? 'arrow_forward' : 'done_all') : 'add'} />
          </button>

          {/* <button
            className={`${styles.favouriteButton}  ${x.favourite ? styles.active : null}`}
            onClick={() => {
              onFavourite(x, i);
            }}>
            <Icon icon={x.favourite ? 'favorite' : 'favorite_border'} className={`${styles.favourite}`} />
          </button> */}
        </div>
      ))}
    </div>
  );
};

interface SearchItemProps {
  text: any;
  area: AreaDto;
  onClick: (multiple: boolean) => void;
  multipleAreas: boolean;
}

export const SearchItem = ({ text, area, onClick, multipleAreas }: SearchItemProps) => {
  let CustomIcon = null as any;
  if (area.type === 'group' || area.type === 'dashboard') {
    CustomIcon = GroupIcon;
  } else if (area.utility === 'electricity') {
    if (area.name.toLowerCase().indexOf('solar') > -1) {
      CustomIcon = SolarIcon;
    } else {
      CustomIcon = ElectricityIcon;
    }
  } else if (area.utility === 'gas') {
    CustomIcon = GasIcon;
  } else if (area.utility === 'water') {
    CustomIcon = WaterIcon;
  } else if (area.utility === 'steam') {
    CustomIcon = SteamIcon;
  } else if (area.utility === 'production') {
    CustomIcon = ProductionIcon;
  } else if (area.utility === 'heat') {
    CustomIcon = HeatIcon;
  } else if (area.utility === 'enviromental' || area.utility === 'weather') {
    CustomIcon = EnviromentalIcon;
  } else {
    CustomIcon = MeterIcon;
  }

  const icon = area.selected ? (multipleAreas ? 'done_all' : 'arrow_forward') : 'keyboard_arrow_right';

  return (
    <a
      href="#"
      className={`${styles.searchItem} ${area.selected ? styles.active : null}`}
      onClick={(e) => {
        e.preventDefault();
        onClick(e.ctrlKey ? true : false);
      }}>
      <CustomIcon />
      <Typography use="body2">{text}</Typography>
      <Icon className={styles.searchExtraIcon} icon={icon} />
    </a>
  );
};
