import React from 'react';
import moment from 'moment';

import { Controller, useForm } from 'react-hook-form';
import { Calendar } from 'react-date-range';
import { MenuItem, SimpleMenu } from '@rmwc/menu';
import { Icon } from '@rmwc/icon';
import { CircularProgress } from '@rmwc/circular-progress';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import { Button } from '@rmwc/button';
import { LoaderComponent } from 'components';
import {
  numberWithCommas,
  Highcharts,
  HighchartsReact,
  useAppState,
  getInitials,
  UserDto,
  Notification,
  NotificationsDetailsInterface,
  NotificationUsage,
  NotificationEvent,
  utilityObjRev,
  NotificationLog,
  intervalObj,
} from 'utilities';

import { ReactComponent as ElectricityIcon } from 'assets/electricity.svg';
import { ReactComponent as GasIcon } from 'assets/gas.svg';
import { ReactComponent as WaterIcon } from 'assets/water.svg';

import DrawerContentHeading from './DrawerContentHeading';
import styles from './ActivityDrawer.module.scss';

// let internalChart = null;

interface ActivityDrawerProps {
  activityId: string;
  manageRightDrawer: (open: boolean, type: string, id?: string) => void;
  updateNotification: (notificationGroupId: string, data: Notification) => Promise<boolean>;
}

const statusOptions = ['Pending', 'Disqualified', 'Qualified', 'Ignored', 'Investigating', 'WIP', 'Resolved'];
const rootCauses = [
  '',
  'Unknown',
  'Equipment operating out of hours',
  'Equipment Upgrade',
  'Change in pattern',
  'External Variable (I.e. weather, flood, locusts)',
];

const colors = [
  '#3C98AB',
  '#50A2B3',
  '#63ADBC',
  '#77B7C4',
  '#8AC1CD',
  '#8AC1CD',
  '#B1D6DD',
  '#C5E0E6',
  '#D8EAEE',
  '#ECF5F7',
];

export const getAssignees = (activityDetails?: NotificationsDetailsInterface, user?: UserDto) => {
  const output = [];
  const notification = activityDetails?.notificationDetails?.notification
    ? activityDetails?.notificationDetails?.notification
    : null;
  const contacts = activityDetails?.notificationDetails?.contacts || [];

  if (user && notification?.assignee === user.profile.id) {
    output.push({ label: user.profile.particulars.displayName, value: user.profile.id });
  }

  contacts?.forEach((x) => {
    const exists = output.find((y) => y.value === x.contactId);
    if (!exists) {
      output.push({ label: x.displayName, value: x.contactId });
    }
  });
  return output;
};

export const ActivityDrawer = ({ activityId, manageRightDrawer, updateNotification }: ActivityDrawerProps) => {
  const { loadActivityDetails, requestAnalyticsSingle, loadingActivityDetails, activityDetails, user } = useAppState();

  const [chartOptions, setChartOptions] = React.useState(null as any);
  const [formVisible, setFormVisible] = React.useState('none');
  const [submitting, setSubmitting] = React.useState(false);
  const [loadingChartData, setLoadingChartData] = React.useState(false);
  const [chartData, setChartData] = React.useState([]);
  const [logs, setLogs] = React.useState([] as NotificationLog[]);

  const notification = activityDetails?.notificationDetails?.notification
    ? activityDetails?.notificationDetails?.notification
    : null;

  const hasNotificationData = notification?.notificationId || false;

  const [assignees, setAssignees] = React.useState([] as any[]);

  const loadChartOptions = async (_notification: Notification, _events: NotificationEvent[]) => {
    if (!loadingChartData) setLoadingChartData(true);
    let elecCount = 0;
    let gasCount = 0;
    let waterCount = 0;
    let tempSeries = [] as any[];
    let latestDate = '';

    const start = moment(notification?.from, 'YYYY-MM-DD').subtract(1, 'year').format('YYYY-MM-DDT00:00:00');
    const end = moment(notification?.to, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DDT23:59:59');

    const result = await requestAnalyticsSingle({
      identifiers: [notification?.channelId],
      unit: notification?.channelUnitOfMeasure,
      utility: notification?.loadUtilityType,
      start: start,
      end: end,
      type: 4,
      interval: intervalObj.Hours,
      listNatureType: [1],
      includeBands: true,
    });

    if (result?.length > 0) {
      const isElec = _notification?.loadUtilityType === utilityObjRev.electricity;
      const isWater = _notification?.loadUtilityType === utilityObjRev.gas;
      const isGas = _notification?.loadUtilityType === utilityObjRev.water;
      let name = _notification?.loadLabel;
      let color = '';
      if (isElec) {
        color = colors[elecCount];
      } else if (isGas) {
        color = colors[gasCount];
      } else if (isWater) {
        color = colors[waterCount];
      }

      const seriesEvent = {
        name: name,
        label: name,
        type: 'area',
        utilityType: isElec ? 'electricity' : isGas ? 'gas' : 'water',
        color: color,
        stacking: isWater ? null : 'normal',
        showInLegend: true,
        showInNavigator: true,
        data: result.map((x) => {
          if (!latestDate || moment(x.event).isAfter(latestDate)) {
            latestDate = x.event;
          }
          return {
            x: moment.utc(x.event).valueOf(),
            y: Number(x.usage?.toFixed(2)),
          };
        }),
      };
      tempSeries.push(seriesEvent);

      tempSeries.push({
        type: 'bb',
        linkedTo: 'previous',
        color: `rgba(60, 152, 171, 1)`,
        fillColor: `rgba(60, 152, 171, 0.1)`,
        data: result.map((x) => {
          return [moment(x.event).valueOf(), x.upper, x.average, x.lower];
        }),
      });

      const eventSeries = {
        name: 'Notification Events',
        type: 'line',
        color: 'transparent',
        showInLegend: false,
        data: _events.map((x) => {
          return {
            x: moment.utc(x.event).valueOf(),
            y: Number(x.consumption?.toFixed(2)),
            EventId: x.eventId,
            marker: {
              enabled: true,
              fillColor: x.status === 0 ? 'red' : 'transparent',
              radius: 8,
              symbol: 'circle',
            },
          };
        }),
      };
      tempSeries.push(eventSeries);
    }

    const options = {
      title: {
        text: '',
        margin: 0,
      },
      chart: {
        height: '250px',
        type: 'area',
        alignTicks: false,
        showAxis: true,
        animation: false,
        zoomType: 'x',
        marginBottom: 10,
        style: {
          fontFamily: '"Roboto", Helvetica, sans-serif',
          color: '#616161',
        },
      },
      plotOptions: {
        spline: {
          lineWidth: 1.5,
          marker: {
            enabled: false,
            radius: 3,
          },
          zoneAxis: 'x',
        },
        area: {
          lineWidth: 1.5,
          fillColor: `rgba(60, 152, 171, 0.1)`,
          negativeColor: `rgba(60, 152, 171, 0.1)`,
          marker: {
            enabled: false,
            radius: 3,
          },
          zoneAxis: 'x',
        },
        series: {
          animation: false,
          turboThreshold: 0,
          zoneAxis: 'x',
          dataGrouping: {
            enabled: false,
            approximation: 'sum',
            units: [
              ['minutes', [15]],
              ['hour', [1]],
            ],
          },
        },
      },
      tooltip: {
        shared: true,
        split: false,
        // shared: true,
      },
      rangeSelector: {
        enabled: false,
      },
      scrollbar: {
        buttonBackgroundColor: '#fff',
        buttonBorderColor: '#fff',
        buttonArrowColor: '#fff',
        minWidth: 0,
        height: 0,
        liveRedraw: true,
      },
      exporting: {
        enabled: false,
      },
      navigator: {
        enabled: false,
      },
      xAxis: {
        ordinal: false,
        type: 'datetime',
        lineColor: '#C4C4C4',
        labels: {
          staggerLines: 1,
          style: { fontSize: 12 },
        },
        min: Number(moment(_notification?.from).valueOf()),
        max: Number(moment(_notification?.to).valueOf()),
      },
      yAxis: [
        {
          title: {
            text: '',
            style: { fontSize: 12 },
          },
          // min: 0,
          max: null,
          opposite: _notification?.loadUtilityType !== utilityObjRev.water ? false : true,
          showEmpty: true,
          alignTicks: false,
        },
      ],
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
        align: 'center',
        verticalAlign: 'bottom',
        style: {
          itemStyle: {
            fontWeight: 500,
            color: '#616161',
          },
        },
      },
      series: tempSeries,
    };
    setChartOptions(options);
    setTimeout(() => {
      setLoadingChartData(false);
    }, 500);
  };

  React.useEffect(() => {
    loadActivityDetails(activityId);
    // eslint-disable-next-line
  }, [activityId]);

  React.useEffect(() => {
    if (!loadingChartData && hasNotificationData) {
      const { notification, events } = activityDetails?.notificationDetails;
      const theLogs = notification.logs.filter((log) => log.type !== 0);
      theLogs.sort((a, b) => {
        return a.event > b.event ? -1 : a.event < b.event ? 1 : 0;
      });
      setLogs(theLogs);
      setAssignees(getAssignees(activityDetails, user));
      loadChartOptions(notification, events);
    }
    // eslint-disable-next-line
  }, [notification?.notificationId]);
  return (
    <div id="activityDrawer" className={styles.activityDrawer} key={activityId}>
      <DrawerContentHeading
        title=""
        titleIcon={
          <a
            href="#"
            className={styles.rahulLink}
            onClick={(e) => {
              e.preventDefault();
              manageRightDrawer(true, 'activities', null);
              setTimeout(() => {
                setFormVisible('none');
              }, 0);
            }}>
            <Icon icon="keyboard_backspace" />
            Activities
          </a>
        }
        titleSmall={true}
        manageRightDrawer={manageRightDrawer}
      />

      <div className={`${styles.activityDetailsWrapper} activityDetailsWrapper`}>
        <>
          {loadingActivityDetails && <LoaderComponent absolute />}
          {!loadingActivityDetails && hasNotificationData && (
            <div className={styles.activityDetailsContent}>
              <Typography use="headline5" className={styles.activityDetailsHeader}>
                {notification?.buildingLabel} {notification?.loadLabel} has identified {notification?.eventCount} events
                between {moment(`${notification?.from}Z`).format('DD MMM YYYY')}
                {'-'}
                {moment(`${notification?.to}Z`).format('DD MMM YYYY')}
              </Typography>
              <div className={styles.activityChartWrapper}>
                <div
                  className={styles.activityChartContainer}
                  onClick={() => {
                    // const loadId = getAreas
                    // window.location.href = '/app/detail?areaName=' + activityDetails.load;
                  }}>
                  {loadingChartData ? (
                    <LoaderComponent absolute />
                  ) : (
                    <HighchartsReact
                      constructorType={'stockChart'}
                      highcharts={Highcharts}
                      options={chartOptions}
                      immutable={false}
                      allowChartUpdate
                      containerProps={{ className: 'activityChartContainer' }}
                      callback={(chart) => {}}
                    />
                  )}
                </div>
              </div>
              <div className={`${styles.activityContentWrapper} ${styles.nowrap}`}>
                <div className={styles.activityContentItem}>
                  <div className={styles.activityContentHeading}>
                    <Typography use="body2">Building</Typography>
                  </div>
                  <p>
                    <Typography use="body2">{notification?.buildingLabel}</Typography>
                  </p>
                </div>
                <div className={styles.activityContentItem}>
                  <div className={styles.activityContentHeading}>
                    <Typography use="body2">Load</Typography>
                  </div>
                  <p>
                    {notification?.loadUtilityType === utilityObjRev.electricity && <ElectricityIcon />}
                    {notification?.loadUtilityType === utilityObjRev.water && <WaterIcon />}
                    {notification?.loadUtilityType === utilityObjRev.gas && <GasIcon />}
                    <Typography use="body2">{notification?.loadLabel}</Typography>
                  </p>
                </div>
              </div>

              <div className={styles.activityContentWrapper}>
                <div className={styles.activityContentHeading}>
                  <Typography use="body2">Assessment</Typography>
                </div>
                <p>
                  <Typography use="body2">{notification?.assessment || '-'}</Typography>
                </p>
              </div>

              {notification?.impacts && (
                <div className={styles.activityContentWrapper}>
                  <div className={styles.activityContentHeading}>
                    <Typography use="body2">Impact</Typography>
                  </div>
                  <div className={styles.impacts}>
                    {notification?.impacts?.map((x: any, i) => {
                      return (
                        <div className={styles.impact} key={i}>
                          <label>
                            {x.type === 1 && <Icon icon="monitoring" />}
                            {x.type === 2 && <Icon icon="forest" />}
                            {x.type === 3 && <Icon icon="attach_money" />}
                          </label>
                          <span>
                            {x.type === 3
                              ? `${x.symbol}${numberWithCommas(x.value.toFixed(2))}`
                              : `${numberWithCommas(x.value.toFixed(2))}${x.symbol}`}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <div className={`${styles.activityContentWrapper} ${styles.nowrap}`}>
                <div className={styles.activityContentItem}>
                  <div className={styles.activityContentHeading}>
                    <Typography use="body2">Created</Typography>
                  </div>
                  <p>
                    <Typography use="body2">
                      {moment(`${notification?.createdOn}Z`).format('HH:mm DD MMM YYYY')}
                    </Typography>
                  </p>
                </div>
                <div className={styles.activityContentItem}>
                  <div className={styles.activityContentHeading}>
                    <Typography use="body2">Analysis Window</Typography>
                  </div>
                  <p>
                    <Typography use="body2">
                      {moment(`${notification?.from}Z`).format('HH:mm DD MMM YYYY')}
                      <br />
                      {moment(`${notification?.to}Z`).format('HH:mm DD MMM YYYY')}
                    </Typography>
                  </p>
                </div>
                <div className={styles.activityContentItem}>
                  <div className={styles.activityContentHeading}>
                    <Typography use="body2">Due Date</Typography>
                  </div>
                  <p>
                    <Typography use="body2">
                      {moment(notification?.dueDate).isValid()
                        ? moment(`${notification?.dueDate}Z`).format('HH:mm DD MMM YYYY')
                        : null}
                    </Typography>
                  </p>
                </div>
              </div>

              <div className={`${styles.activityContentWrapper} ${styles.nowrap}`}>
                <div className={styles.activityContentItem}>
                  <div className={styles.activityContentHeading}>
                    <Typography use="body2">Qualification Status</Typography>
                  </div>
                  <p>
                    <Typography use="body2">{statusOptions[notification?.status]}</Typography>
                  </p>
                </div>
                <div className={styles.activityContentItem}>
                  <div className={styles.activityContentHeading}>
                    <Typography use="body2">Assignee</Typography>
                  </div>
                  <p>
                    <Typography use="body2">{notification?.assigneeLabel}</Typography>
                  </p>
                </div>
                <div className={styles.activityContentItem}>
                  <div className={styles.activityContentHeading}>
                    <Typography use="body2">Root Causes</Typography>
                  </div>
                  <p>
                    <Typography use="body2">{rootCauses[notification?.rootCause]}</Typography>
                  </p>
                </div>
              </div>

              <div className={`${styles.activityContentWrapper}`}>
                <div className={styles.activityContentItem}>
                  <div className={styles.activityContentHeading}>
                    <Typography use="body2">Comments</Typography>
                  </div>
                  {logs && (
                    <div>
                      <CommentsBlock logs={logs} userId={user.profile.id} />
                    </div>
                  )}
                </div>
              </div>

              <div className={`${styles.activityContentWrapper} form-wrapper`}>
                {formVisible === 'update' && (
                  <UpdateForm
                    assignees={assignees}
                    activityDetails={activityDetails?.notificationDetails?.notification}
                    updateNotification={updateNotification}
                    setSubmitting={setSubmitting}
                  />
                )}
              </div>
            </div>
          )}
        </>
      </div>

      {activityDetails?.notificationDetails && hasNotificationData && (
        <div className={`${styles.drawerFooter} ${styles.large}`}>
          <Button
            label={'CANCEL'}
            outlined
            type="button"
            icon={submitting ? <CircularProgress theme="secondary" /> : null}
            disabled={submitting}
            onClick={() => {
              if (formVisible === 'update') {
                setFormVisible('none');
              } else {
                manageRightDrawer(true, 'activities', null);
                setTimeout(() => {
                  setFormVisible('none');
                }, 0);
              }
            }}
          />

          <Button
            label={formVisible === 'update' ? 'SAVE' : 'UPDATE'}
            raised
            type="button"
            icon={submitting ? <CircularProgress theme="secondary" /> : null}
            disabled={submitting}
            onClick={() => {
              if (formVisible !== 'update') {
                setFormVisible('update');
                setTimeout(() => {
                  document.querySelector('.activityDetailsWrapper').scrollTo(0, 2000);
                }, 0);
              } else {
                document.getElementById('hiddenBtn').click();
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export const CommentsBlock = ({ logs, userId }) => {
  return (
    <div className={styles.commentsWrapper}>
      {logs.map((x, i) => {
        const isNotCurrentUser = x.contactId !== userId;
        const now = moment();
        const dt = moment(x.event);
        let dtTxt = dt.fromNow();
        if (dt.isAfter(now)) {
          dtTxt = 'just now';
        }
        return (
          <div className={styles.comment} key={`${i}-comment`}>
            <p className={`${styles.userWrapper} ${isNotCurrentUser ? styles.alternate : ''}`}>
              <label className={styles.avatar}>{getInitials(x.contactLabel, null, null)}</label>
              <Typography use="body2">{x.contactLabel}</Typography>
              <Typography use="body2" className={styles.commentDate}>
                {dtTxt}
              </Typography>
            </p>
            <p>
              <Typography use="body2">{x.message}</Typography>
            </p>
          </div>
        );
      })}
    </div>
  );
};

export const UpdateForm = ({ activityDetails, assignees, updateNotification, setSubmitting }) => {
  const [calendarOpen, setCalendarOpen] = React.useState(false);
  const { errors, control, register, handleSubmit, setValue } = useForm({
    mode: 'all',
  });

  let defaultAssignee = assignees.find((x) => x.value === activityDetails.assignee);
  if (!defaultAssignee) {
    defaultAssignee = assignees[0];
  }

  const onSubmit = async (data) => {
    setSubmitting(true);

    let theDate = null as string | null;
    if (data.dueDate !== undefined) {
      if (moment(data.dueDate, 'DD-MM-YYYY').isValid()) {
        theDate = moment(data.dueDate, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00');
      }
    } else {
      theDate = activityDetails?.dueDate;
    }

    const payload = {
      notificationId: activityDetails?.notificationId,
      status: data.status !== undefined ? Number(data.status) : activityDetails?.status,
      assignee: data.assignee !== undefined ? data.assignee : activityDetails?.assignee,
      rootCause: data.rootCause !== undefined ? Number(data.rootCause) : activityDetails?.rootCause,
      dueDate: theDate,
      newComment: data.comment ? { message: data.comment } : null,
    };
    await updateNotification(activityDetails.notificationId, payload);
    setSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
      <br />
      <div>
        <Typography use="headline6">Update Alert</Typography>
      </div>
      <br />
      <div className={styles.assignForm}>
        <div className={styles.row}>
          <div className={styles.colFullWidth}>
            <Controller
              name="status"
              control={control}
              defaultValue={activityDetails?.status?.toString()}
              rules={{
                required: true,
              }}
              as={
                <Select
                  enhanced
                  className={styles.customSelect}
                  name="status"
                  options={[
                    {
                      label: 'Qualified',
                      value: '2',
                    },
                    {
                      label: 'Ignored',
                      value: '3',
                    },
                    {
                      label: 'Investigating',
                      value: '4',
                    },
                    {
                      label: 'WIP',
                      value: '5',
                    },
                    {
                      label: 'Resolved',
                      value: '6',
                    },
                  ]}
                  label="Status"
                  required
                  outlined
                  defaultValue={activityDetails?.status?.toString()}
                />
              }
            />
            {errors.status && (
              <div className={styles.error}>
                <Typography use="caption">Please select a qualification status</Typography>
              </div>
            )}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.colFullWidth}>
            <Controller
              name="rootCause"
              control={control}
              defaultValue={activityDetails?.rootCause?.toString() || ''}
              as={
                <Select
                  enhanced
                  className={styles.customSelect}
                  name="action"
                  options={rootCauses.map((x, i) => {
                    return { value: i.toString(), label: x };
                  })}
                  label="Root Cause"
                  outlined
                  defaultValue={activityDetails?.rootCause?.toString() || ''}
                />
              }
            />
            {errors.rootCause && (
              <div className={styles.error}>
                <Typography use="caption">Please select a root cause</Typography>
              </div>
            )}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <Controller
              name="assignee"
              control={control}
              defaultValue={defaultAssignee?.value}
              as={
                <Select
                  enhanced
                  className={styles.customSelect}
                  name="assignee"
                  options={assignees}
                  label="Assignee"
                  outlined
                  defaultValue={defaultAssignee?.value}
                />
              }
            />
            {errors.assignee && (
              <div className={styles.error}>
                <Typography use="caption">Please select an assignee</Typography>
              </div>
            )}
          </div>
          <div className={`${styles.col} ${styles.datePickerWrapper} `}>
            <TextField
              outlined
              label="Target due date"
              className={styles.dueDate}
              type="text"
              name="dueDate"
              inputRef={register()}
              defaultValue={activityDetails?.dueDate ? moment(activityDetails?.dueDate).format('DD-MM-YYYY') : ''}
              onClick={(e) => {
                e.preventDefault();
                setCalendarOpen(!calendarOpen);
                return false;
              }}
            />

            <Calendar
              className={`${styles.calendar} ${calendarOpen ? styles.calendarOpen : ''} `}
              minDate={new Date()}
              onChange={(item) => {
                setValue('dueDate', moment(item).format('DD-MM-YYYY'));
                setCalendarOpen(false);
              }}
              date={activityDetails?.dueDate ? moment(activityDetails?.dueDate).toDate() : new Date()}
            />

            {errors.dueDate && (
              <div className={styles.error}>
                <Typography use="caption">Please select a valid date</Typography>
              </div>
            )}
          </div>
        </div>

        <TextField
          textarea
          outlined
          fullwidth
          rows={3}
          characterCount
          maxLength={500}
          label="Add a comment"
          className={styles.comments}
          name="comment"
          inputRef={register()}
        />
      </div>
      <button id="hiddenBtn" type="submit" style={{ display: 'none' }} />
    </form>
  );
};

export default ActivityDrawer;
