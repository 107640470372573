import { intervalFrequency, intervalFrequencyToType } from 'utilities';
import { AccountDto, AreaDto } from './interfaces';

export const numberWithCommas = (x: any) => {
  var parts = x?.toString().split('.');
  if (parts?.length > 0) {
    // eslint-disable-next-line
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  } else {
    return '';
  }
};

export const toTitleCase = (str: string) => {
  if (!str) {
    return '';
  }
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

export const groupByArray = (xs: any[], key: any) => {
  return xs.reduce(function (rv, x) {
    let v = key instanceof Function ? key(x) : x[key];
    let el = rv.find((r) => r && r.key === v);
    if (el) {
      el.values.push(x);
    } else {
      rv.push({ key: v, values: [x] });
    }
    return rv;
  }, []) as any[];
};

export const getParameterByName = (name: string) => {
  const theUrl = window.location.href;
  // eslint-disable-next-line
  const theName = name.replace(/[\[\]]/g, '\\$&');
  // eslint-disable-next-line
  const regex = new RegExp('[?&]' + theName + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(theUrl);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getHeading = (selectedAreas: AreaDto[]) => {
  let heading = '';
  if (selectedAreas.length > 1) {
    heading = `Comparing ${selectedAreas?.map((x) => x.name).join(', ')}`;
  } else if (selectedAreas.length > 0) {
    heading = selectedAreas[0].name;
  }
  return heading;
};

export const getPageTitle = (selectedAreas: AreaDto[], selectedAccount: AccountDto, extraText: string) => {
  let pageTitle = 'ESP Hub';
  if (selectedAreas.length > 1) {
    pageTitle += ` | ${selectedAccount.label} | Comparing ${selectedAreas.flatMap((x) => x.name).join(', ')}`;
  } else if (selectedAreas.length === 1) {
    const firstArea = selectedAreas[0];
    if (firstArea.type === 'dashboard') {
      pageTitle += ` | ${selectedAccount.label} | Dashboard`;
    } else if (firstArea.parentNames.length > 0) {
      pageTitle += ` | ${firstArea.parentNames.join(', ')} | ${firstArea.name}`;
    } else {
      pageTitle += ` | ${firstArea.name}`;
    }
  }
  if (extraText) {
    pageTitle += ' | ' + extraText;
  }
  return pageTitle;
};

export const getDatesFromAreas = (selectedAreas: AreaDto[]) => {
  // flat list of dates
  let dates = [];
  if (selectedAreas.length > 0) {
    selectedAreas.forEach((area) => {
      area.utilities.forEach((util) => {
        if (util.data.length > dates.length) {
          dates = util.data.flatMap((data) => data.x);
        }
      });
    });
  }
  return dates;
};

export const abbreviateNumber = function (num: any, fixed: number, large?: boolean) {
  if (num === null) {
    return null;
  } // terminate early
  if (num === 0) {
    return { numText: '0', num: 0, text: '' };
  } // terminate early
  const keys = large ? ['', 'thousand', 'million', 'billion', 'trillion'] : ['', 'K', 'M', 'B', 'T'];
  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  var b = num.toPrecision(2).split('e'), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
    c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(fixed), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e = d + keys[k]; // append power
  return { numText: e, num: d, text: keys[k] };
};

export const getInitials = (displayName: string, firstName?: string, lastName?: string) => {
  if (firstName && lastName) {
    return firstName.charAt(0) + lastName.charAt(0);
  }
  const initials = displayName
    ? displayName
        .split(' ')
        .map((n) => n[0])
        .join('')
    : '';
  return initials.charAt(0) + initials.charAt(initials.length - 1);
};

export const toCamel = (o: any) => {
  var newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === 'object') {
        value = toCamel(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
        value = o[origKey];
        if (value instanceof Array || (value !== null && value.constructor === Object)) {
          value = toCamel(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
};

export const getHighestIntervalFromLoads = (loads: AreaDto[]) => {
  const intervals = loads.flatMap((x) => x.subareas.flatMap((y) => y.interval));
  let highestIntervalIdx = intervalFrequency.length - 1;
  intervals.forEach((x) => {
    const idx = intervalFrequency.indexOf(x);
    if (idx < highestIntervalIdx) {
      highestIntervalIdx = idx;
    }
  });
  const frequency = intervalFrequency[highestIntervalIdx];
  const result = intervalFrequencyToType[frequency];
  return result;
};

export const getLowestIntervalFromLoads = (loads: AreaDto[]) => {
  const intervals = loads.flatMap((x) => x.subareas.flatMap((y) => y.interval));
  let lowestIntervalIdx = 0;
  intervals.forEach((x) => {
    const idx = intervalFrequency.indexOf(x);
    if (idx > lowestIntervalIdx) {
      lowestIntervalIdx = idx;
    }
  });
  const frequency = intervalFrequency[lowestIntervalIdx];
  const result = intervalFrequencyToType[frequency];
  return result;
};

export const setCookie = (cname: string, cvalue: string) => {
  const d = new Date();
  d.setTime(d.getTime() + 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const deleteCookie = (cname: string) => {
  document.cookie = cname + '=;Thu, 01 Jan 1970 00:00:00 UTC;path=/';
};

export const getCookie = (cname: string) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const processEmissionFactorSources = (emissionFactorSources) => {
  const getFlatEmissionSources = (emissionSources: any) => {
    const flattenedSources = [];
    const innerFn = (sources, parentStr) => {
      sources.forEach((x) => {
        if (!x.items) {
          const label = `${parentStr} > ${x.label} - ${x.unit}`;
          const labelNoUnit = `${parentStr} > ${x.label}`;
          flattenedSources.push({ ...x, label: label, labelNoUnit: labelNoUnit, value: x.sourceId });
          // flattenedSources.push({ ...x, label: `${x.label} - ${x.unit}`, value: x.sourceId });
        } else {
          const newLabel = `${parentStr ? `${parentStr} > ${x.label}` : x.label}`;
          // const newLabel = ''; // `${parentStr ? `${parentStr} > ${x.label}` : x.label}`;
          innerFn(x.items, newLabel);
        }
      });
    };
    innerFn(emissionSources, null);
    flattenedSources.sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
    return flattenedSources;
  };

  const results = [];
  emissionFactorSources.forEach((x) => {
    const items = getFlatEmissionSources(x.items);
    const uniqueParentLabels = [];
    items.forEach((x) => {
      const parentLabel = x.label.split('>')[0];
      if (uniqueParentLabels.indexOf(parentLabel) === -1) {
        uniqueParentLabels.push(parentLabel);
      }
    });
    if (uniqueParentLabels.length === 1) {
      items.forEach((x) => {
        x.label = x.label.replace(uniqueParentLabels[0] + '>', '');
      });
    }
    results.push({ label: x.label, value: '', options: items });
  });
  return results;
};
