import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { useAppState } from 'utilities';
import LoaderComponent from 'components/Loader/Loader';

interface AuthGuardProps {
  children?: ReactNode;
}

export const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { authenticating, authenticated } = useAppState();

  if (authenticating) {
    return <LoaderComponent />;
  } else if (!authenticated) {
    return <Redirect to="/login" />;
  } else {
    return <>{children}</>;
  }
};

export default AuthGuard;
