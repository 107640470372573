import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';

let timeout = null;
export const GoogleAnalyticsComponent: React.FC = () => {
  const history = useHistory();
  history.listen((location: any) => {
    if (history.action === 'PUSH' && location) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        const tag = {
          page_title: document.title,
          page_location: window.location.href,
          page_path: location.pathname,
        };
        // @ts-ignore
        window.gtag('config', process.env.REACT_APP_GTAG, tag);
      }, 1000);
    }
  });
  return null;
};

export default withRouter(GoogleAnalyticsComponent);
