// @ts-nocheck
import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@rmwc/dialog';
import { Icon } from '@rmwc/icon';

import { CircularProgress } from '@rmwc/circular-progress';
import { Button } from '@rmwc/button';
import { Typography } from '@rmwc/typography';
import { AccountDto, contactStatusReverse, useAppState } from 'utilities';

import styles from './WelcomeDialog.module.scss';

export const WelcomeDialog = () => {
  const [open, setOpen] = React.useState(false);
  const [updatingAccountIds, setUpdatingAccountIds] = React.useState([] as string[]);
  const [accountIdsChanged, setAccountIdsChanged] = React.useState([] as any[]);

  const { user, loadingAccountMetadata, acceptWelcomeInvite } = useAppState();

  const pendingAccounts = user?.profile?.accounts?.filter((x) => x.roleStatus === contactStatusReverse.Invited) || [];

  const handleAcceptWelcomeInvite = async (contactId: string, account: AccountDto, accept: boolean) => {
    const newIds = [].concat(updatingAccountIds);
    newIds.push(account.id);
    setUpdatingAccountIds(newIds);
    const result = await acceptWelcomeInvite(contactId, account.id, accept);
    if (result) {
      const updatedAccountIds = [].concat(accountIdsChanged);
      updatedAccountIds.push({ accountId: account.id, accept });
      setAccountIdsChanged(updatedAccountIds);
    }
    setUpdatingAccountIds(updatingAccountIds);
  };

  React.useEffect(() => {
    if (!loadingAccountMetadata) {
      if (pendingAccounts.length > 0) {
        setOpen(true);
      }
    }
  }, [loadingAccountMetadata]);
  return (
    <div className={styles.welcomeDialog}>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          window.location.reload();
        }}
        onClosed={() => {}}>
        <DialogTitle className={styles.dialogTitle}>Welcome to ESP Hub</DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <div className={styles.dialogText}>
            <Typography use="body1">
              You have {pendingAccounts.length} account{pendingAccounts.length > 1 ? 's' : ''} awaiting your action!
            </Typography>
          </div>
          {pendingAccounts.map((account) => {
            const updatingAccount = updatingAccountIds.indexOf(account.id) > -1;
            const statusChanged = accountIdsChanged.find((x) => x.accountId === account.id);
            return (
              <div className={styles.pendingAccount} key={account.label}>
                <div className={styles.avatar}>
                  <Icon icon="account_balance" />
                </div>
                <div className={styles.pendingAccountInfo}>{account.label}</div>
                <div className={styles.contactOptions}>
                  {statusChanged ? (
                    <div>{statusChanged.accept ? 'Accepted' : 'Declined'}</div>
                  ) : (
                    <>
                      <Button
                        raised
                        disabled={updatingAccount}
                        icon={updatingAccount ? <CircularProgress theme="secondary" /> : null}
                        onClick={() => {
                          handleAcceptWelcomeInvite(user.profile.id, account, false);
                        }}
                        type="button">
                        Decline
                      </Button>
                      <Button
                        outlined
                        type="button"
                        disabled={updatingAccount}
                        icon={updatingAccount ? <CircularProgress theme="secondary" /> : null}
                        onClick={() => {
                          handleAcceptWelcomeInvite(user.profile.id, account, true);
                        }}>
                        Accept
                      </Button>
                    </>
                  )}
                </div>
              </div>
            );
          })}
          <a
            style={{ position: 'absolute', top: '8px', right: '8px' }}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
            }}>
            <Icon icon="close" />
          </a>
        </DialogContent>
      </Dialog>
    </div>
  );
};
