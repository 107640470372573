import moment from 'moment';

export const dateRanges = [
  {
    label: 'Yesterday',
    dates: {
      startDate: moment().startOf('day').subtract(11, 'hours').toDate(),
      endDate: moment().endOf('day').subtract(11, 'hours').toDate(),
    },
  },
  {
    label: 'Last 7 Days',
    dates: {
      startDate: moment().startOf('day').subtract(6, 'days').subtract(11, 'hours').toDate(),
      endDate: moment().endOf('day').subtract(11, 'hours').toDate(),
    },
  },
  {
    label: 'Last 14 Days',
    dates: {
      startDate: moment().startOf('day').subtract(13, 'days').subtract(11, 'hours').toDate(),
      endDate: moment().endOf('day').subtract(11, 'hours').toDate(),
    },
  },
  {
    label: 'Last Month',
    dates: {
      startDate: moment().subtract(1, 'month').startOf('month').toDate(),
      endDate: moment().subtract(1, 'month').endOf('month').toDate(),
    },
  },
  {
    label: 'Last 3 Months',
    dates: {
      startDate: moment().subtract(3, 'month').startOf('month').toDate(),
      endDate: moment().subtract(1, 'month').endOf('month').toDate(),
    },
  },
  {
    label: 'Last 6 Months',
    dates: {
      startDate: moment().subtract(6, 'month').startOf('month').toDate(),
      endDate: moment().subtract(1, 'month').endOf('month').toDate(),
    },
  },
  {
    label: 'Last 12 Months',
    dates: {
      startDate: moment().subtract(12, 'month').startOf('month').toDate(),
      endDate: moment().subtract(1, 'month').startOf('month').add(1, 'day').toDate(),
    },
  },
];

export const staticRanges = dateRanges.map((x) => {
  return {
    label: x.label,
    range: () => ({
      startDate: x.dates.startDate,
      endDate: x.dates.endDate,
    }),
    isSelected: (date) => {
      const tmpDate = { startDate: date.startDate, endDate: date.endDate };
      return JSON.stringify(tmpDate) === JSON.stringify(x.dates);
    },
  };
});

export const operationRanges = [
  {
    label: 'Last Month',
    dates: {
      startDate: moment().subtract(1, 'month').startOf('month').toDate(),
      endDate: moment().subtract(1, 'month').endOf('month').toDate(),
    },
  },
  {
    label: 'Last 3 Months',
    dates: {
      startDate: moment().subtract(3, 'month').startOf('month').toDate(),
      endDate: moment().subtract(1, 'month').endOf('month').toDate(),
    },
  },
  {
    label: 'Last 6 Months',
    dates: {
      startDate: moment().subtract(6, 'month').startOf('month').toDate(),
      endDate: moment().subtract(1, 'month').endOf('month').toDate(),
    },
  },
  {
    label: 'Last 12 Months',
    dates: {
      startDate: moment().subtract(12, 'month').startOf('month').toDate(),
      endDate: moment().subtract(1, 'month').endOf('month').toDate(),
    },
  },
  {
    label: 'Previous Year',
    dates: {
      startDate: moment().subtract(24, 'month').startOf('month').toDate(),
      endDate: moment().subtract(13, 'month').endOf('month').toDate(),
    },
  },
];

export const operationStaticRanges = operationRanges.map((x) => {
  return {
    label: x.label,
    range: () => ({
      startDate: x.dates.startDate,
      endDate: x.dates.endDate,
    }),
    isSelected: (date) => {
      const tmpDate = { startDate: date.startDate, endDate: date.endDate };
      return JSON.stringify(tmpDate) === JSON.stringify(x.dates);
    },
  };
});
