import React from 'react';
import { useAppState, CreateContactDto, contactRoleType, contactRoleTypeRev, ContactDto, getInitials } from 'utilities';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import { Icon } from '@rmwc/icon';
import { default as RSelect } from 'react-select';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
// @ts-ignore
import { LoaderComponent } from 'components';
import { isEmail, VirtualGroupDto } from 'utilities';
import { ReactComponent as YayImage } from 'assets/yay.svg';
import DrawerContentHeading from './DrawerContentHeading';
import LoadPicker from './LoadPicker';
import styles from './VirtualGroupDrawer.module.scss';

interface VirtualGroupDrawerProps {
  groupId: string;
  extraParams?: any;
  manageRightDrawer: (open: boolean, type: string, id?: string) => void;
  updateVirtualGroup: (group: VirtualGroupDto) => Promise<boolean>;
  createVirtualGroup: (group: VirtualGroupDto) => Promise<VirtualGroupDto>;
  createContact: (contact: CreateContactDto) => Promise<boolean>;
}

const getSelectedLoads = (mappedData: any[]) => {
  const loadIds = [];
  const innerFn = (data: any[]) => {
    data.forEach((x) => {
      if (x.type === 'load') {
        if (x.checked) {
          loadIds.push(x.value);
        }
      } else {
        innerFn(x.children);
      }
    });
  };
  innerFn(mappedData);
  return loadIds;
};

export const VirtualGroupDrawer = ({
  groupId,
  extraParams,
  manageRightDrawer,
  updateVirtualGroup,
  createVirtualGroup,
  createContact,
}: VirtualGroupDrawerProps) => {
  const { getProfileContacts } = useAppState();

  const isUserFlow = window.location.href.indexOf('/users') > -1;
  const isCreate = !groupId;
  const steps = ['createForm', 'groups', 'invite', 'complete'];

  let defaultStep = steps[0];
  if (extraParams?.selectedOption >= 0) {
    defaultStep = steps[extraParams?.selectedOption];
  }

  const storedVirtualGroups = sessionStorage.getItem('virtualGroups')
    ? JSON.parse(sessionStorage.getItem('virtualGroups'))
    : [];

  const storedVirtualGroupsLoads = sessionStorage.getItem('virtualGroupsLoads')
    ? JSON.parse(sessionStorage.getItem('virtualGroupsLoads'))
    : [];

  const group = groupId ? extraParams.group : null;

  const [submitting, setSubmitting] = React.useState(false);
  const [actualGroup, setActualGroup] = React.useState(group);
  const [createFormData, setCreateFormData] = React.useState(
    actualGroup ? { id: actualGroup.id, label: actualGroup.label, parentGroup: actualGroup.parentId } : null
  );
  const [step, setStep] = React.useState(defaultStep);
  const [selectedNodes, setSelectedNodes] = React.useState([]);

  const getBackLabel = () => {
    if (isCreate) {
      return steps.indexOf(step) === 0 ? 'CANCEL' : 'BACK';
    } else {
      return 'CANCEL';
    }
  };

  const getNextLabel = () => {
    if (isUserFlow) {
      return 'INVITE';
    } else if (step === 'invite') {
      return 'INVITE';
    } else if (!isCreate) {
      return 'SAVE';
    } else {
      switch (step) {
        case 'createForm':
          return 'NEXT';
        case 'groups':
          return 'ADD LOADS';
        case 'invite':
          return 'INVITE';
        default:
          return 'NEXT';
      }
    }
  };

  const submitCreate = async (values) => {
    setSubmitting(true);
    if (actualGroup?.id) {
      let data = { ...actualGroup, label: values.label, parentId: values.parentGroup };
      const result = await updateVirtualGroup(data);
      if (result) {
        sessionStorage.setItem('clearAccountData', 'yes');
        setCreateFormData({ ...values });
        setActualGroup({ ...actualGroup, label: values.label });
        setSubmitting(false);
        if (isCreate) {
          setStep('groups');
        } else {
          manageRightDrawer(false, 'groups', null);
        }
      }
    } else {
      const result = await createVirtualGroup({
        parentId: values.parentGroup,
        label: values.label,
        restricted: false,
        permissions: [],
      });
      if (result) {
        sessionStorage.setItem('clearAccountData', 'yes');
        setCreateFormData({ ...values });
        setActualGroup(result);
        setSubmitting(false);
        setTimeout(() => {
          setStep('groups');
        }, 0);
      }
    }
  };

  const submitGroupsHierarchy = async () => {
    const loadIds = getSelectedLoads(selectedNodes);
    const groupData = { ...actualGroup, loads: loadIds.map((x) => ({ loadId: x })) };
    setSubmitting(true);
    const result = await updateVirtualGroup(groupData);
    if (result) {
      sessionStorage.setItem('clearAccountData', 'yes');
      setSubmitting(false);
      if (isCreate) {
        setStep(steps[steps.indexOf(step) + 1]);
      } else {
        sessionStorage.removeItem('virtualGroups');
        manageRightDrawer(false, 'groups', null);
      }
    }
  };

  const submitInvite = async (values) => {
    setSubmitting(true);
    if (values.length > 0) {
      const promises = values.map((x) => {
        return createContact({
          emailAddress: x.email,
          role: contactRoleTypeRev[x.role],
          // status: contactStatusReverse.Invited,
        });
      });

      const results = await Promise.all(promises);
      const success = results.filter((x) => x === true).length;

      toast.success(`${success}/${results.length} Users successfully created.`);
    }
    sessionStorage.setItem('clearAccountData', 'yes');
    sessionStorage.removeItem('virtualGroups');
    setSubmitting(false);
    setStep('complete');
  };

  let title = isCreate
    ? `Create a new group${actualGroup?.label ? `: ${actualGroup.label}` : ''}`
    : `Update group${actualGroup?.label ? `: ${actualGroup.label}` : ''}`;

  if (isUserFlow) {
    title = 'Add/Invite Users';
  }
  return (
    <div className={`${styles.virtualGroupDrawer} ${step === 'groups' ? styles.capWidth : ''}`}>
      <DrawerContentHeading
        title={title}
        titleSmall={true}
        titleIcon={
          <Icon icon={{ icon: isUserFlow ? 'supervisor_account' : 'language', basename: 'material-icons-outlined' }} />
        }
        manageRightDrawer={manageRightDrawer}
      />

      {isUserFlow && step !== 'complete' ? (
        <div className={styles.userFlowContainer}>
          <VirtualGroupInviteForm
            submitInvite={submitInvite}
            getProfileContacts={getProfileContacts}
            group={actualGroup}
            isUserFlow={true}
            updateVirtualGroup={updateVirtualGroup}
          />
        </div>
      ) : (
        <>
          {step === 'createForm' && (
            <VirtualGroupCreateForm
              isEdit={isCreate ? false : true}
              createFormData={createFormData}
              submitCreate={submitCreate}
              virtualGroups={storedVirtualGroups}
            />
          )}
          {step === 'groups' && (
            <LoadPicker
              isUserFlow={isUserFlow}
              id={actualGroup?.id}
              label={'Assign loads to group'}
              selectedNodes={selectedNodes}
              setSelectedNodes={setSelectedNodes}
              loads={storedVirtualGroupsLoads}
              description={
                isCreate
                  ? "Great! We've created your group. Now it's time to assign loads to this group. This is purely optional if you'd like to create \"groups of groups\". You can always come back and edit this later."
                  : "Here you can pick which loads this group should or shouldn't have access to. Simply check or uncheck the checkboxes and click save, then we'll assign these loads to the group."
              }
              disabled={false}
              showUtilityFilter={false}
            />
          )}

          {step === 'invite' && (
            <VirtualGroupInviteForm
              submitInvite={submitInvite}
              getProfileContacts={getProfileContacts}
              group={actualGroup}
              isUserFlow={false}
              updateVirtualGroup={updateVirtualGroup}
            />
          )}

          {step === 'complete' && (
            <div className={styles.complete}>
              <div className={styles.noDataWrapper}>
                <YayImage />
                <div className={styles.noDataHeading}>
                  <Typography use="body1">Thank you!</Typography>
                </div>
                <div className={styles.noDataContent}>
                  <Typography use="body1">
                    {isUserFlow
                      ? 'The invitation has been sent'
                      : isCreate
                      ? `The group "${actualGroup.label}" is all setup!`
                      : `The group "${actualGroup.label}" was updated`}
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <div className={`${styles.drawerFooter}`}>
        {step === 'complete' && (
          <Link
            to="/app/account-settings/groups"
            onClick={(e) => {
              e.preventDefault();
              sessionStorage.removeItem('virtualGroups');
              manageRightDrawer(false, 'groups', null);
            }}>
            Close
          </Link>
        )}

        {step !== 'complete' && (
          <Button
            label={getBackLabel()}
            outlined
            type="button"
            disabled={submitting}
            onClick={() => {
              sessionStorage.removeItem('contacts');
              if (step === 'createForm') {
                sessionStorage.removeItem('virtualGroups');
                manageRightDrawer(false, 'virtualGroup', null);
              } else {
                setStep(steps[steps.indexOf(step) - 1]);
              }
            }}
          />
        )}

        {step !== 'complete' && (
          <Button
            label={getNextLabel()}
            raised
            type="button"
            icon={submitting ? <CircularProgress theme="secondary" /> : null}
            disabled={submitting}
            onClick={() => {
              const hiddenBtn = document.getElementById('hiddenBtn') as HTMLElement;
              if (hiddenBtn) {
                hiddenBtn.click();
              } else if (step === 'groups') {
                submitGroupsHierarchy();
              } else {
                setSubmitting(true);
                setTimeout(() => {
                  if (isCreate) {
                    setStep(steps[steps.indexOf(step) + 1]);
                  } else {
                    toast.success(`Users successfully invited`);
                    sessionStorage.removeItem('virtualGroups');
                    manageRightDrawer(false, 'groups', null);
                  }
                  setSubmitting(false);
                }, 1000);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

const VirtualGroupCreateForm = ({ createFormData, submitCreate, isEdit, virtualGroups }) => {
  const flattenNodes = (tempInnerAreas: VirtualGroupDto[], nodes?: VirtualGroupDto[], parentNames?: string[]) => {
    nodes.forEach((x) => {
      tempInnerAreas.push({ ...x, parentNames: parentNames });
      const newParentNames = [].concat(parentNames);
      newParentNames.unshift(x.label);
      flattenNodes(tempInnerAreas, x.nodes, newParentNames);
    });
    return tempInnerAreas;
  };

  const flattenedGroups = flattenNodes([], virtualGroups, []);
  const { errors, register, handleSubmit } = useForm({
    mode: 'onChange',
  });

  const [parentGroup, setParentGroup] = React.useState(createFormData?.parentGroup);

  // const groupsNoLoads = flattenedGroups.filter((x) => x.loads.length === 0);

  const parentGroups = [
    {
      label: 'Primary',
      options: flattenedGroups
        .filter((x) => x.tier === 1 && x.loads.length === 0)
        .map((x) => {
          return { label: x.label, value: x.id };
        }),
    },
    {
      label: 'Secondary',
      options: flattenedGroups
        .filter((x) => x.tier > 1 && x.loads.length === 0)
        .map((x) => {
          return { label: x.parentNames.join(' > ') + ' > ' + x.label, value: x.id };
        }),
    },
  ];

  const onSubmit = (values) => {
    const newData = {
      ...createFormData,
      parentGroup: parentGroup,
    };
    if (values.label) {
      newData.label = values.label;
    }
    submitCreate(newData);
  };

  return (
    <div className={styles.virtualGroupContent}>
      <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
        <div className={styles.createForm}>
          <div className={styles.row}>
            <div className={styles.col}>
              {createFormData?.id ? (
                <Typography use="body1">
                  Hi there. You may edit any part of the group, simply edit the text box you want and click save and
                  this will move you onto the next section. Edit any of the groups or loads previously selected by
                  clicking on the box to click or unclick a previous selection and click save.
                </Typography>
              ) : (
                <Typography use="body1">
                  This sidebar allows you to create a new group of loads, or groups of groups. Simply give your new
                  group a name, select the parent group and click next and we will walk you through the setup process.
                </Typography>
              )}
            </div>
          </div>
          <div className={styles.virtualGroupHeading}>
            <Typography use="headline6">{isEdit ? 'Edit Group' : 'Create Group'}</Typography>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <TextField
                outlined
                label="Group Name"
                name="label"
                required
                inputRef={register({ required: true })}
                autoFocus
                defaultValue={createFormData?.label}
                floatLabel
              />
              {errors?.label && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter in a group name</Typography>
                </div>
              )}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={`rSelectContainer ${createFormData?.id ? 'rSelect--is-disabled' : ''}`}>
                <RSelect
                  options={parentGroups}
                  placeholder={'Type or pick from the drop down'}
                  className={`rSelect--custom-control ${parentGroup ? `has-value` : ''} `}
                  classNamePrefix="rSelect"
                  isClearable={true}
                  defaultValue={
                    parentGroup
                      ? { label: flattenedGroups.find((x) => x.id === parentGroup)?.label, value: parentGroup }
                      : null
                  }
                  onInputChange={(inputValue, { action }) => {
                    if (action === 'input-change') {
                      setParentGroup(null);
                    }
                  }}
                  onChange={(data, { action }) => {
                    if (action === 'clear') {
                      setParentGroup(null);
                    }
                    if (data) {
                      setParentGroup(data.value);
                    }
                  }}
                  isDisabled={createFormData?.id}
                />
                <span>Parent Group</span>
              </div>
            </div>
          </div>

          {/* <div className={styles.row}>
            <div className={styles.col}>
              <TextField
                textarea
                outlined
                rows={3}
                characterCount
                maxLength={500}
                label="Description"
                className={styles.description}
                name="description"
                inputRef={register()}
                autoFocus
                defaultValue={createFormData?.description}
              />
            </div>
          </div> */}
        </div>

        <button id="hiddenBtn" type="submit" style={{ display: 'none' }} />
      </form>
    </div>
  );
};

const VirtualGroupInviteForm = ({ submitInvite, getProfileContacts, group, isUserFlow, updateVirtualGroup }) => {
  const storedContacts = sessionStorage.getItem('contacts') ? JSON.parse(sessionStorage.getItem('contacts')) : [];
  const [loadedContacts, setLoadedContacts] = React.useState(storedContacts.length > 0);
  const [contacts, setContacts] = React.useState(storedContacts as ContactDto[]);
  const [updatingUser, setUpdatingUser] = React.useState(false);

  const [contactsListOpen, setContactsListOpen] = React.useState(false);
  const [usersListOpen, setUsersListOpen] = React.useState(isUserFlow);

  const localUsers = [];
  if (localUsers.length === 0) {
    localUsers.push({
      email: '',
      role: contactRoleType[contactRoleTypeRev.User],
      disabled: false,
      contactId: null,
      hasPermission: true,
    });
  }

  const { errors, control, handleSubmit, setError, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      users: localUsers,
    },
  });

  const watchUsers = watch('users');

  const { fields, append } = useFieldArray({
    control,
    name: 'users',
  });

  const addContactToGroup = async (contact: ContactDto) => {
    let isValid = true;
    if (group?.permissions.length === 0) {
      isValid = window.confirm(
        'This group is currenly unrestricted. Adding this user will enforce that only this user can see this group!'
      );
    }
    if (isValid) {
      setUpdatingUser(true);
      const newPermissions = [].concat(group.permissions);
      newPermissions.push({ contactId: contact.contactId });
      const result = await updateVirtualGroup({
        ...group,
        restricted: true,
        permissions: newPermissions,
      });
      if (result) {
        group.permissions = newPermissions;
        group.restricted = true;
        sessionStorage.setItem('clearAccountData', 'yes');
      }

      setUpdatingUser(false);
    }
  };

  const removeContactFromGroup = async (contact: ContactDto) => {
    let isValid = true;
    if (group?.permissions.length === 1) {
      isValid = window.confirm(
        'This group is currenly restricted. Removing this user will allow all users who have permission on the account to see this group!'
      );
    }
    if (isValid) {
      setUpdatingUser(true);
      const clonedPermissions = [].concat(group.permissions);
      const idx = clonedPermissions.findIndex((x) => x.contactId === contact.contactId);
      clonedPermissions.splice(idx, 1);
      const result = await updateVirtualGroup({
        ...group,
        restricted: clonedPermissions.length > 0 ? true : false,
        permissions: clonedPermissions,
      });
      if (result) {
        group.permissions = clonedPermissions;
        group.restricted = false;
        sessionStorage.setItem('clearAccountData', 'yes');
      }
      setUpdatingUser(false);
    }
  };

  const onSubmit = (values) => {
    let hasError = false;
    const users = values?.users || [];
    users.forEach((x, i) => {
      if (x.email && !isEmail(x.email)) {
        hasError = true;
        setError(`users[${i}].email`, { message: 'error' });
      }
    });
    if (!hasError) {
      submitInvite(users.filter((x) => !x.disabled && x.email && isEmail(x.email)));
    }
  };

  React.useEffect(() => {
    const loadContacts = async () => {
      const result = await getProfileContacts();
      sessionStorage.setItem('contacts', JSON.stringify(result));
      setLoadedContacts(true);
      setContacts(result);
    };
    if (!loadedContacts) {
      loadContacts();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.virtualGroupContent}>
      <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off" className={styles.customInviteForm}>
        <div className={styles.row}>
          <div className={styles.col}>
            {isUserFlow ? (
              <Typography use="body1">
                Please fill in the email address and role fields. Click “add more users” if you have more users to add
                otherwise click invite and we will send them a welcome email.
              </Typography>
            ) : (
              <Typography use="body1">
                Below is the default list of users that have access to this group. If you’d like to restrict access to
                one or more Users, simply click “add” next to the users name and this will add that user and restrict
                access to all others. You can also add a new user by filling in the e-mail address and selecting their
                role in the text boxes below. If you have multiple new users click on the “Add more users” link, if done
                click “Invite” and we will send them a welcome email.
              </Typography>
            )}
          </div>
        </div>

        <div className={styles.inviteForm}>
          {!loadedContacts && (
            <div className={styles.loadingContainer}>
              <LoaderComponent absolute />
            </div>
          )}

          {!isUserFlow && (
            <a
              href="#"
              className={styles.virtualGroupHeading}
              onClick={(e) => {
                e.preventDefault();
                setContactsListOpen(!contactsListOpen);
                // do stuff
              }}>
              <Typography use="headline6">
                Manage Users {group?.permissions.length === 0 ? '[Unrestricted]' : '[Restricted]'}
              </Typography>
              <Icon
                icon="chevron_right"
                className={`${styles.accordionIcon} ${contactsListOpen ? styles.rotate : null}`}
              />
            </a>
          )}

          {!isUserFlow && (
            <div className={`${styles.customAccordion} ${contactsListOpen ? styles.open : styles.closed}`}>
              {contacts.map((contact) => {
                const hasPermissions = group?.permissions.find((x) => x.contactId === contact.contactId) ? true : false;
                return (
                  <div className={styles.contact} key={contact.contactId}>
                    <div className={styles.avatar}>
                      {getInitials(contact.displayName, contact.first, contact.last) || ':)'}
                    </div>
                    <div className={styles.contactInfo}>
                      <div className={styles.contactName}>{contact.displayName}</div>
                      <div className={styles.contactName}>{contact.email}</div>
                    </div>
                    <div className={styles.contactOptions}>
                      {hasPermissions ? (
                        <Button
                          raised
                          disabled={updatingUser}
                          icon={updatingUser ? <CircularProgress theme="secondary" /> : null}
                          onClick={() => {
                            removeContactFromGroup(contact);
                          }}
                          type="button">
                          Remove
                        </Button>
                      ) : (
                        <Button
                          raised
                          type="button"
                          disabled={updatingUser}
                          icon={updatingUser ? <CircularProgress theme="secondary" /> : null}
                          onClick={() => {
                            addContactToGroup(contact);
                          }}>
                          Add
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <a
            href="#"
            className={styles.virtualGroupHeading}
            onClick={(e) => {
              e.preventDefault();
              setUsersListOpen(!usersListOpen);
            }}>
            <Typography use="headline6">Add/Invite users</Typography>
            <Icon icon="chevron_right" className={`${styles.accordionIcon} ${usersListOpen ? styles.rotate : null}`} />
          </a>

          <div className={`${styles.customAccordion} ${usersListOpen ? styles.open : styles.closed}`}>
            {fields.map((field, index) => {
              return (
                <div className={styles.row}>
                  <div className={styles.col}>
                    <Controller
                      key={`${field.id}.email`}
                      name={`users.${index}.email`}
                      control={control}
                      defaultValue={field.email}
                      as={
                        <TextField
                          name={`users.${index}.email`}
                          outlined
                          label="Email"
                          autoFocus
                          type="email"
                          defaultValue={field.email}
                          disabled={field.disabled}
                        />
                      }
                    />
                    {errors?.users && errors?.users[index] && errors?.users[index].email && (
                      <div className={styles.error}>
                        <Typography use="caption">Please enter in a valid email</Typography>
                      </div>
                    )}
                  </div>
                  <div className={styles.col}>
                    <Controller
                      key={`${field.id}.role`}
                      name={`users.${index}.role`}
                      control={control}
                      defaultValue={field.role}
                      as={
                        <Select
                          enhanced
                          className={styles.customSelect}
                          name={`users.${index}.role`}
                          options={['User', 'Admin']}
                          label="Role"
                          outlined
                          defaultValue={field.role}
                          disabled={field.disabled}
                        />
                      }
                    />
                    {errors?.users && errors?.users[index] && errors?.users[index].role && (
                      <div className={styles.error}>
                        <Typography use="caption">Please select a role</Typography>
                      </div>
                    )}
                  </div>
                  <Controller
                    key={`${field.id}.disabled`}
                    name={`users.${index}.disabled`}
                    control={control}
                    defaultValue={field.disabled}
                    as={<input type="hidden" value={field.disabled} />}
                  />
                </div>
              );
            })}

            <p style={{ paddingBottom: '32px' }}>
              {watchUsers.length <= 20 && (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    let hasError = false;
                    watchUsers.forEach((x, i) => {
                      if (x.email && !isEmail(x.email)) {
                        hasError = true;
                        setError(`users[${i}].email`, { message: 'error' });
                      }
                    });
                    if (!hasError) {
                      append({ email: '', role: 'User' });
                    }
                  }}>
                  Add more users
                </a>
              )}
            </p>
          </div>
        </div>
        <button id="hiddenBtn" type="submit" style={{ display: 'none' }} />
      </form>
    </div>
  );
};

export default VirtualGroupDrawer;
