import React from 'react';
import { DragPreviewImage, useDrag } from 'react-dnd';

import { TabBar, Tab } from '@rmwc/tabs';
import { Typography } from '@rmwc/typography';
import { Ripple } from '@rmwc/ripple';
import { Icon } from '@rmwc/icon';

import { ReactComponent as GroupIcon } from 'assets/group.svg';
import { ReactComponent as MeterIcon } from 'assets/meter.svg';
import { ReactComponent as GasIcon } from 'assets/gas.svg';
import { ReactComponent as ElectricityIcon } from 'assets/electricity.svg';
import { ReactComponent as WaterIcon } from 'assets/water.svg';
import { ReactComponent as HeatIcon } from 'assets/heat.svg';
import { ReactComponent as ProductionIcon } from 'assets/production.svg';
import { ReactComponent as SolarIcon } from 'assets/solar.svg';
import { ReactComponent as SteamIcon } from 'assets/steam.svg';
import { ReactComponent as EnviromentalIcon } from 'assets/enviromental.svg';

import { SkeletonDrawer } from 'components';
import { useAppState, AreaDto, toTitleCase } from 'utilities';

import styles from './Drawer.module.scss';

export interface DrawerComponentProps {
  open?: boolean;
}

export const DrawerComponent: React.FC<DrawerComponentProps> = ({ open }) => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const {
    areas,
    loadingAccountMetadata,
    selectAreaById,
    getSelectedAreas,
    getAreaById,
    getAreasByType,
  } = useAppState();

  const selectedAreas = getSelectedAreas();
  const { groups, loads } = getAreasByType();

  const drawerData = [] as AreaDto[][];
  const firstSelectedArea = selectedAreas?.length > 0 ? selectedAreas[0] : areas[0];
  if (!firstSelectedArea || firstSelectedArea.type === 'dashboard') {
    if (groups.length > 1) {
      drawerData.push(groups);
    } else if (loads.length > 0) {
      drawerData.push(loads);
    }
  } else {
    // add parent objects
    firstSelectedArea.parentIds.forEach((id, idx) => {
      const area = getAreaById(id, areas);
      if (area) {
        const firstInnerChild = area.subareas[0];
        if (area.type === 'dashboard') {
          if (groups.length > 1) {
            drawerData.push(groups);
          } else if (loads.length > 0) {
            drawerData.push(loads);
          }
        } else if (firstInnerChild.type === 'load') {
          if (idx === firstSelectedArea.parentIds.length - 1) {
            drawerData.push(area.subareas);
          }
        } else {
          drawerData.push([area]);
        }
      }
    });
    if (firstSelectedArea?.subareas?.length > 0) {
      const firstChild = firstSelectedArea.subareas[0];
      if (firstChild.type !== 'channel') {
        drawerData.push(firstSelectedArea.subareas);
      }
    }
  }

  // if (loads.
  const viewingOperations = window.location.href.indexOf('/operations') > -1;
  const viewingActivities = window.location.href.indexOf('/activities') > -1;

  let disableDrawer = false;
  if (viewingOperations || viewingActivities) {
    if (firstSelectedArea?.subareas?.length > 0 && selectedTab === drawerData.length - 1) {
      const firstChild = firstSelectedArea.subareas[0];
      if (firstChild.type === 'load') {
        disableDrawer = true;
      }
    }
  }

  const handleSelectArea = async (area: AreaDto, multiple?: boolean, goNextTab?: boolean) => {
    if (disableDrawer) {
      return;
    }

    if (firstSelectedArea?.id === area.id && goNextTab) {
      setSelectedTab(selectedTab + 1);
    } else if (goNextTab && area.type !== 'load') {
      sessionStorage.setItem('nextTab', 'yes');
    }
    selectAreaById(area.id, multiple, false, area.parentIds[area.parentIds.length - 1]);
  };

  const handleGoBack = () => {
    if (disableDrawer) {
      return;
    }
    if (selectedTab > 0) {
      setSelectedTab(selectedTab - 1);
    } else {
      selectAreaById(null, false, false);
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      const tabToSelect = firstSelectedArea?.parentIds?.length > 0 ? firstSelectedArea.parentIds.length - 1 : 0;
      if (sessionStorage.getItem('nextTab')) {
        sessionStorage.removeItem('nextTab');
        setSelectedTab(tabToSelect + 1);
      } else {
        setSelectedTab(tabToSelect);
      }
    }, 0);
  }, [firstSelectedArea]);

  if (loadingAccountMetadata) {
    return (
      <div className={`${styles.drawer} ${!open ? styles.closed : styles.open}`}>
        <div className={styles.drawerContent}>
          <SkeletonDrawer />
        </div>
      </div>
    );
  }

  const activeDrawerData = drawerData[selectedTab] || drawerData[0];
  const isLoadDrawer = activeDrawerData?.length > 0 && activeDrawerData[0].type === 'load';

  return (
    <div
      id="drawer"
      className={`${styles.drawer} ${!open ? styles.closed : styles.open} ${
        disableDrawer ? styles.disableDrawer : null
      }`}>
      <div className={styles.drawerContent}>
        {!loadingAccountMetadata && selectedAreas.length === 0 ? (
          <div />
        ) : selectedAreas.length === 0 ? (
          <SkeletonDrawer />
        ) : (
          <>
            <CustomTabs selectedTab={selectedTab} drawerData={drawerData} handleTabInteraction={setSelectedTab} />

            {isLoadDrawer ? (
              <LoadDrawerBlock
                header={
                  selectedTab === 0
                    ? null
                    : selectedAreas[0].parentNames.length > selectedTab
                    ? getAreaById(selectedAreas[0].parentIds[selectedTab], areas)?.name
                    : selectedAreas[0].name
                }
                selectAreaIds={selectedAreas.flatMap((x) => x.parentIds)}
                areas={activeDrawerData}
                onClick={handleSelectArea}
                onClickBack={handleGoBack}
                onlyLoads={groups.length === 1}
              />
            ) : (
              <DrawerBlock
                header={
                  selectedTab === 0
                    ? null
                    : selectedAreas[0].parentNames.length > selectedTab
                    ? getAreaById(selectedAreas[0].parentIds[selectedTab], areas)?.name
                    : selectedAreas[0].name
                }
                selectAreaIds={selectedAreas.flatMap((x) => x.parentIds)}
                areas={
                  selectedAreas[0].parentNames.length > selectedTab
                    ? getAreaById(selectedAreas[0].parentIds[selectedTab], areas)?.subareas
                    : selectedAreas[0].subareas
                }
                onClick={handleSelectArea}
                onClickBack={handleGoBack}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const CustomTabs = ({ selectedTab, drawerData, handleTabInteraction }) => {
  return (
    <TabBar activeTabIndex={selectedTab} className={`${styles.drawerTabs} alternate`}>
      {drawerData.map((areas, i) => {
        const firstArea = areas[0];
        let CustomIcon = GroupIcon;
        if (firstArea.type === 'group') {
          CustomIcon = GroupIcon;
        } else {
          CustomIcon = MeterIcon;
        }
        return (
          <Tab
            key={firstArea.type + '' + i}
            title={toTitleCase(firstArea.type)}
            className={`${styles.drawerTab}`}
            onInteraction={() => handleTabInteraction(i)}>
            <CustomIcon />
          </Tab>
        );
      })}
    </TabBar>
  );
};

export interface DrawerBlockProps {
  header: string;
  selectAreaIds: string[];
  areas: AreaDto[];
  onClick: (area: AreaDto, multiple?: boolean, changeTab?: boolean) => void;
  onClickBack: () => void;
}

export const DrawerBlock = ({ header, areas, selectAreaIds, onClick, onClickBack }: DrawerBlockProps) => {
  const selectedIds = areas.filter((x) => x.selected).map((x) => x.id);
  const viewingOperations = window.location.href.indexOf('/app/operations') > -1;
  return (
    <div id="drawerBlock" className={styles.drawerBlock}>
      {header ? (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onClickBack();
          }}
          className={styles.back}>
          <Icon icon="keyboard_backspace" />
          <Typography use="caption" className={styles.caption}>
            <span className={styles.ellipsis}>{header}</span>
          </Typography>
        </a>
      ) : (
        <div style={{ height: '8px' }} />
      )}
      <ul id="drawerList" className={styles.drawerList}>
        {areas?.map((x, i) => {
          const activated = selectedIds.indexOf(x.id) > -1 || selectAreaIds.indexOf(x.id) > -1;
          const icon = selectedIds.length > 1 && activated ? 'done_all' : 'keyboard_arrow_right';
          const hasLoads = x.subareas.length > 0 && x.subareas[0].type === 'load';
          let disabled = false;
          if (viewingOperations && hasLoads) {
            const allNoMainIncomers = x.subareas.every((x) => !x.mainIncomer);
            if (allNoMainIncomers) {
              disabled = true;
            }
          }
          return (
            <DraggableItem
              id={`${x.name}${i}`.toLowerCase().replace(/ /g, '').replace(/,/g, '').replace(/'/g, '')}
              key={`${x.name}${i}`}
              name={x.name}
              icon={icon}
              activated={activated}
              area={x}
              onClick={onClick}
              disabled={disabled}
            />
          );
        })}
      </ul>
    </div>
  );
};

export interface LoadDrawerBlockProps extends DrawerBlockProps {
  onlyLoads?: boolean;
}

export const LoadDrawerBlock = ({ header, areas, onlyLoads, onClick, onClickBack }: LoadDrawerBlockProps) => {
  const selectedIds = areas?.filter((x) => x.selected).map((x) => x.id);
  const [expanded, setExpanded] = React.useState('');
  const InnerRender = ({ areas, name }) => {
    let CustomIcon = MeterIcon;
    if (name === 'Electricity') {
      CustomIcon = ElectricityIcon;
    } else if (name === 'Gas') {
      CustomIcon = GasIcon;
    } else if (name === 'Water') {
      CustomIcon = WaterIcon;
    } else if (name === 'Steam') {
      CustomIcon = SteamIcon;
    } else if (name === 'Heat') {
      CustomIcon = HeatIcon;
    } else if (name === 'Production') {
      CustomIcon = ProductionIcon;
    } else if (name === 'Solar') {
      CustomIcon = SolarIcon;
    } else if (name === 'Enviromental') {
      CustomIcon = EnviromentalIcon;
    } else {
      CustomIcon = MeterIcon;
    }

    const clonedAreas = [].concat(areas);
    clonedAreas.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

    const sliced = onlyLoads
      ? clonedAreas
      : clonedAreas.slice(0, expanded.indexOf(name) === -1 ? 5 : clonedAreas.length);

    return (
      clonedAreas?.length > 0 && (
        <>
          <p className={styles.loadDrawerHeading}>
            <CustomIcon />
            <Typography use="body2">{name} Loads</Typography>
          </p>
          <ul className={`${styles.drawerList} ${styles.loadDrawerList}`}>
            {sliced.map((x, i) => {
              const activated = selectedIds.indexOf(x.id) > -1;
              const icon = selectedIds.length > 1 && activated ? 'done_all' : 'arrow_forward';

              return (
                <DraggableItem
                  id={`${x.name}${i}`}
                  key={`${x.name}${i}`}
                  area={x}
                  name={x.name}
                  activated={activated}
                  icon={icon}
                  onClick={onClick}
                  disabled={false}
                />
              );
            })}
            {!onlyLoads && areas.length > 5 && expanded.indexOf(name) === -1 && (
              <li className={styles.showMoreItem}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setExpanded(expanded + ' ' + name);
                  }}>
                  <Typography use="caption">Show more</Typography>
                </a>
              </li>
            )}
          </ul>
        </>
      )
    );
  };
  return (
    <div className={styles.drawerBlock}>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          onClickBack();
        }}
        className={styles.back}>
        {header && <Icon icon="keyboard_backspace" />}
        <Typography use="caption" className={styles.caption}>
          <span className={styles.ellipsis}>{header}</span>
        </Typography>
      </a>

      <InnerRender
        areas={areas?.filter((x) => x.utility === 'electricity' && x.name?.toLowerCase().indexOf('solar') === -1) || []}
        name="Electricity"
      />
      <InnerRender areas={areas?.filter((x) => x.utility === 'gas') || []} name="Gas" />
      <InnerRender areas={areas?.filter((x) => x.utility === 'water') || []} name="Water" />
      <InnerRender areas={areas?.filter((x) => x.utility === 'heat') || []} name="Heat" />
      <InnerRender areas={areas?.filter((x) => x.utility === 'steam') || []} name="Steam" />
      <InnerRender
        areas={areas?.filter((x) => x.utility === 'electricity' && x.name?.toLowerCase().indexOf('solar') > -1) || []}
        name="Solar"
      />
      <InnerRender areas={areas?.filter((x) => x.utility === 'production') || []} name="Production" />
      <InnerRender
        areas={areas?.filter((x) => x.utility === 'enviromental' || x.utility === 'weather') || []}
        name="Enviromental"
      />
      {/* <InnerRender areas={areas?.filter((x) => x.utility === 'ghgemissions') || []} name="GHG Emissions" /> */}
      {/* <InnerRender areas={areas?.filte  r((x) => x.utility === 'other') || []} name="Other" /> */}
    </div>
  );
};

interface DraggableItemProps {
  id: string;
  area: AreaDto;
  name: string;
  icon?: string;
  metaIcon?: string;
  activated: boolean;
  disabled: boolean;
  onClick: (area: AreaDto, multiple?: boolean, changeTab?: boolean) => void;
}

export const DraggableItem = ({ id, area, name, icon, metaIcon, activated, disabled, onClick }: DraggableItemProps) => {
  const iconFontSize = icon === 'done_all' ? '14px' : null;
  // @ts-ignore
  const [{ opacity }, dragRef, preview] = useDrag({
    type: 'item',
    // @ts-ignore
    item: { id: area.id, name, type: 'item', icon: icon, parentId: area.parentIds[area.parentIds.length - 1] },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });
  return (
    <li id={id} className={`${styles.draggableItemListItem}`} style={{ opacity }}>
      <DragPreviewImage
        connect={preview}
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs="
      />
      <Ripple>
        <div
          className={`${styles.draggableItemWrapper}  ${activated ? styles.active : null} ${
            disabled ? styles.disabled : null
          }`}
          ref={activated ? null : dragRef}>
          <a
            href="#"
            title={name}
            tabIndex={activated ? -1 : null}
            onClick={(e) => {
              e.preventDefault();
              onClick(area, e.ctrlKey ? true : false, false);
            }}
            className={`${styles.draggableItemContent} `}>
            <Typography use="body2">{name}</Typography>
          </a>
          {area.subareas && area.subareas.length > 0 && icon ? (
            <a
              href="#"
              title={name}
              tabIndex={activated ? -1 : null}
              onClick={(e) => {
                e.preventDefault();
                if (area.type !== 'load') {
                  onClick(area, false, true);
                }
              }}
              className={`${styles.draggableItemIcon}  ${activated ? styles.active : null}`}>
              <Icon icon={icon} style={{ fontSize: iconFontSize }} />
            </a>
          ) : null}

          <div className={`${styles.backgroundItem} ${activated ? styles.active : null}`} />
        </div>
      </Ripple>
    </li>
  );
};

export default DrawerComponent;
